import { useMemo } from 'react'
import { useUserRole } from 'lib'
import { FEATURES } from 'constants/features'
import { useFeatureFlags } from 'lib/useFeatureFlags'

export const useIsSidebarEnabledByFF = (): boolean => {
  const { isTaaS, isInvestor, isSSS, isBanker } = useUserRole()
  const { isFeatureFlagEnabled } = useFeatureFlags()
  const isFeatureEnabledForInvestors: boolean = isFeatureFlagEnabled(FEATURES.notificationsForInvestors)
  const isFeatureEnabledForBanker: boolean = isFeatureFlagEnabled(FEATURES.notificationsForBanker)
  const isFeatureEnabledForSSS: boolean = isFeatureFlagEnabled(FEATURES.notificationsForSSS)

  return useMemo(() => [
    isTaaS,
    isFeatureEnabledForInvestors && isInvestor,
    isFeatureEnabledForSSS && isSSS,
    isFeatureEnabledForBanker && isBanker
  ].includes(true), [
    isBanker,
    isFeatureEnabledForInvestors,
    isFeatureEnabledForSSS,
    isFeatureEnabledForBanker,
    isInvestor,
    isSSS,
    isTaaS
  ])
}
