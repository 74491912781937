import React from 'react'
import { ProfileProvider as AuthProvider } from 'contexts/user-auth-context/index'
import { ModalProvider } from 'contexts/modal-context'
import { ThemeProvider } from '@emotion/react'
import { lightThemeColors } from 'theme'
import { NavbarBackButtonProvider } from 'components/Buttons/NavbarBackButton/context'
import { TermsheetModalProvider } from 'contexts/modal-context/TermsheetModals/context'

interface AppProvidersProps {
  children: any
  isMfe?: boolean
}

export function AppProviders ({
  children,
  isMfe = false
}: AppProvidersProps): JSX.Element {
  return (
    <AuthProvider isMfe={isMfe}>
      <ThemeProvider theme={lightThemeColors}>
        <ModalProvider>
          <NavbarBackButtonProvider>
            <TermsheetModalProvider>{children}</TermsheetModalProvider>
          </NavbarBackButtonProvider>
        </ModalProvider>
      </ThemeProvider>
    </AuthProvider>
  )
}
