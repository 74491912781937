import { NOTIFICATIONS_QUERY } from 'components/Notifications/constants'
import { CACHED_QUERY_PROPS } from 'constants/queries'
import { useQuery, UseQueryResult } from 'react-query'
import { getNotifications, Notification } from 'services/notifications'

export function useGetNotifications (): UseQueryResult<Notification[]> {
  return useQuery<Notification[]>(NOTIFICATIONS_QUERY, getNotifications, {
    ...CACHED_QUERY_PROPS,
    staleTime: 5000,
    refetchInterval: 45000
  })
}
