import { useHistory } from 'react-router'
import { isNil } from 'ramda'
import { useQuery } from 'react-query'
import { CACHED_QUERY_PROPS } from 'constants/queries'
import { useUserPreferences } from 'lib'
import { DashboardRegion, DASHBOARD_TYPE } from 'constants/index'
import { useStores } from 'store/rootStore'
import { getCountriesWithStockExchanges } from 'services/countries'
import { useRegionList } from 'lib/regionHooks'

export function useDashboardPage (): {
  setDashboardRegionAndUpdateUrl: () => void
  title: string
  filteredDashboardRegions: DashboardRegion[]
} {
  const [
    dashboardRegion,
    setDashboardRegion,
    hasDashboardBeenFetched
  ] = useUserPreferences<DashboardRegion>(['dashboardRegion'])
  const { countriesStore, userStore } = useStores()
  useQuery('countriesWithStockExchanges', getCountriesWithStockExchanges, {
    ...CACHED_QUERY_PROPS,
    onSuccess: (countries) => {
      if (!isNil(countries) && countries?.length > 0 && countriesStore.isCountriesEmpty) {
        countriesStore.setCountriesWithCodesFromList(countries)
      }
    }
  })
  const history = useHistory()
  const filteredDashboardRegions: DashboardRegion[] = useRegionList()
  const pathname = history.location.pathname.substring(1)
  const getDashboardRegion = (region): string => DashboardRegion[region].toLocaleLowerCase()

  function setDashboardRegionAndUpdateUrl (): void {
    if (hasDashboardBeenFetched) {
      filteredDashboardRegions.forEach((region) => {
        if (pathname === getDashboardRegion(region) || pathname.slice(0, -1) === getDashboardRegion(region)) {
          if (dashboardRegion === region) {
            history.push('/dashboard')
          } else {
            void setDashboardRegion(region)
            history.push('/dashboard')
          }
        }
      })
    }
  }

  const title = userStore.user.config.dashboardType === DASHBOARD_TYPE.TERMSHEET
    ? 'My Deal Dashboard'
    : 'Deal Dashboard'

  return {
    setDashboardRegionAndUpdateUrl,
    title,
    filteredDashboardRegions
  }
}
