import {
  all,
  always,
  flatten,
  isEmpty,
  isNil,
  map,
  mergeDeepWith,
  pickAll,
  pipe,
  prop,
  propOr,
  reduce,
  sortBy,
  toLower,
  values,
  when,
  without
} from 'ramda'
import {
  CountriesType,
  CountryDTO,
  CountryItem,
  FilterDisplay,
  SubRegionSoringMappingType,
  SubRegionType
} from 'store/types'
import memoize from 'fast-memoize'
import { DashboardRegion } from 'constants/index'

export const getCountriesBySubRegionsAndRegion = memoize((
  subRegionList: string[],
  countriesByRegion: SubRegionType
): CountryItem[] => pipe(
  (items) => pickAll(subRegionList, items),
  values as () => SubRegionType[],
  flatten as (x: SubRegionType[]) => CountryItem[],
  without([undefined]) as unknown as (x: CountryItem[] | undefined[]) => CountryItem[],
  map(pickAll(['name', 'code', 'displayType'])),
  sortBy(pipe(prop('name') as () => string, toLower))
)(countriesByRegion) as CountryItem[])

const checkValidityOfCountryDTO = when<CountryDTO[], CountryDTO[]>(
  (item: CountryDTO[]) => !Array.isArray(item),
  always<CountryDTO[]>([] as CountryDTO[])
)

export const parseCountryDTO = memoize((data: CountryDTO[]): CountriesType => pipe(
  checkValidityOfCountryDTO,
  reduce<CountryDTO, CountriesType>((acc, item) => {
    const elem = {
      name: !isNil(item.shortName) && !isEmpty(item.shortName) ? item.shortName : item.name,
      code: item.alpha3ISOCode,
      displayType: item.filterDisplay ?? FilterDisplay.HIDDEN
    }
    return mergeDeepWith((left, right) =>
      (all(Array.isArray)([left, right]))
        ? [...left, ...right]
        : right,
    acc, {
      [DashboardRegion.ALL]: {
        [item.subRegion]: [elem]
      },
      [item.region]: {
        [item.subRegion]: [elem]
      }
    })
  }, {}) as unknown as () => CountriesType
)(data))

export const subRegionsSortingMapping = memoize((data: CountryDTO[]): SubRegionSoringMappingType => pipe(
  checkValidityOfCountryDTO,
  reduce<CountryDTO, SubRegionSoringMappingType>((acc, item) => ({
    ...acc,
    [item.subRegion]: propOr(0, 'subRegionSortingRank', item)
  }), {}) as unknown as () => SubRegionSoringMappingType
)(data))
