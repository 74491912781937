import React, { useState } from 'react'
import { TabContainerProps } from 'components/CountryFilter/types'
import { observer } from 'mobx-react-lite'
import { useStores } from 'store/rootStore'
import FilterColumn from 'components/CountryFilter/FilterColumn'
import { Link } from 'react-router-dom'
import { Checkbox } from '@ppui/ui-components'

const TabContainer: React.FC<TabContainerProps> = observer(({ value }) => {
  const { countriesStore, filterStore } = useStores()
  const subRegions = countriesStore.getSubRegionsForCurrentRegion()
  const [isOpenExpanded, setIsOpenExpanded] = useState<boolean>(false)
  return (
    <>
      <div className='grid grid-cols-7 gap-4'>
        {subRegions.map((subRegion, index) => {
          const id = `filter-column-${value}-${index}`
          return (
            <FilterColumn
              key={id}
              selectedRegion={value}
              parentId={id}
              subRegion={subRegion}
              isOpen={isOpenExpanded}
              setIsOpen={setIsOpenExpanded}
            />
          )
        })}
      </div>
      <div className='text-black mt-5'>
        <div className='flex gap-2 items-center'>
          <span>* Country selection is based on underlying stock exchange.</span>
          <Checkbox
            id='country-filter-no-stock-exchange'
            value={filterStore.includeWithNoStockExchanges}
            label='Include deals that have no stock exchange.'
            styles={{ margin: 'mr-2' }}
            onChangeHandler={() => {
              filterStore.includeWithNoStockExchanges = !filterStore.includeWithNoStockExchanges
            }}
          />
        </div>

        <br />
        * These filters will apply to your dashboard view only, email notifications will be unaffected.
        To change your email notification preferences, please click
        <Link
          to='/investor/preferences' className='text-indigo-600 hover:text-indigo-500' onClick={() => {
            filterStore.isFilterOpen = false
          }}
        > here
        </Link>.

      </div>
    </>

  )
})
export default TabContainer
