import { IApplicationFormMeta } from 'store/types'

export enum DistributionGroup {
  QIBS = 'QIBS',
  REG_S = 'REG_S'
}
export enum OfferingType {
  IPO = 'IPO',
  ABO = 'ABO',
  EQL = 'EQL',
  RI = 'RI',
  SPAC = 'SPAC',
  OTH = 'OTH'
}
export const FIRST_STEP_NAME = 'configurator'
export const SECOND_STEP_NAME = 'complete'

export const STEPS_ORDER = [FIRST_STEP_NAME, SECOND_STEP_NAME]
export const MODES = {
  edit: 'EDIT',
  create: 'CREATE',
  add: 'ADD'
}
export const defaultMetaValues: IApplicationFormMeta = {
  mode: null,
  offeringType: OfferingType.IPO,
  tsId: '',
  currentStep: FIRST_STEP_NAME,
  isDraft: false
}
