import useWebSocket from 'react-use-websocket'
import { useStores } from 'store/rootStore'
import { Options } from 'react-use-websocket/src/lib/types'
import { equals } from 'ramda'
import { Auth } from '@aws-amplify/auth'
import { useEffect, useState } from 'react'
import { IWebSocketHook } from 'lib/types'
import { getToken } from '@pp/utils'

export const useWs = (options?: Options): IWebSocketHook => {
  const { commonStore } = useStores()
  const [wsSettings, setWsSettings] = useState<{
    endpoint: string | null
    token: string
  }>({
    endpoint: null,
    token: ''
  })
  useEffect(() => {
    const prepareWsSettings = async (): Promise<void> => {
      const token = await getToken()
      const endpoint = commonStore.config?.wsEndpoint ?? ''
      const newSettings = {
        token,
        endpoint
      }

      if (endpoint && token && !equals(wsSettings, newSettings)) {
        setWsSettings(newSettings)
      }
    }
    void prepareWsSettings()
  }, [commonStore.bundleMode, commonStore.config?.wsEndpoint, wsSettings])

  return useWebSocket(wsSettings.endpoint, {
    share: true,
    shouldReconnect: () => true,
    reconnectAttempts: 10,
    reconnectInterval: 3000,
    onError: async (event) => {
      const token = (await Auth.currentSession()).getAccessToken().getJwtToken()
      setWsSettings({ ...wsSettings, token })
    },
    queryParams: {
      token: wsSettings.token
    },
    ...options ?? {}
  })
}
