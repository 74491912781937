import { useEffect } from 'react'
import { useStores } from 'store/rootStore'

export function useTitle (title: string): void {
  const { commonStore } = useStores()
  useEffect(() => {
    commonStore.setTitle(title)
    document.title = `Primary Portal - ${title}`

    return () => {
      commonStore.setTitle('')
      document.title = 'Primary Portal'
    }
  }, [commonStore, title])
}
