import clsx from 'clsx'
import React, { ReactNode } from 'react'

export interface ButtonProps {
  children: ReactNode
  className?: string
  disabled?: boolean
  handler?: (a: any) => any
  dataQa?: string
  removed?: boolean
}

export function Button ({ disabled, children, handler, className, dataQa }: ButtonProps): JSX.Element {
  const classnames = clsx(
    'w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 hover:bg-indigo-700',
    className
  )

  return (
    <button
      disabled={disabled}
      type={handler !== undefined ? 'button' : 'submit'}
      onClick={handler}
      className={classnames}
      data-qa={dataQa}
    >
      {children}
    </button>
  )
}
