import { createTheme, responsiveFontSizes } from '@material-ui/core'

// TODO: now color theme is defined in both here and tailwind config. Would be good to have only one place where theme is defined
export const lightThemeColors = {
  white: 'white',

  black: '#000',

  grey_100: '#F3F3F3',
  grey_130: '#f1f3f6',
  grey_150: '#F7F7F7',
  grey_200: '#F0F4F6',
  grey_250: '#dbe5ea',
  grey_300: '#e3e8ee',
  grey_350: '#c7cacc',
  grey_400: '#98B2BF',
  grey_500: '#93A4AC',
  grey_800: '#323a46',

  blue_100: '#585fff',
  blue_110: '#eeefff',
  blue_150: '#DEEAF6',
  blue_200: '#D3E2F3',
  blue_400: '#5c62ff',
  blue_500: '#3e6071',
  blue_700: '#134160',
  blue_900: '#092940',

  green_50: '#D0F3EB',
  green_100: '#4EC8AD',
  green_200: '#6CB7A6',
  green_300: '#43a976',

  orange: '#EE6A52',

  yellow: '#F5A623',
  yellow_100: '#f2e28c',

  pink: '#F8E4E1',

  sweetCorn: '#fae890',
  gray_bg: '#F4F4F4',
  border: '2px solid black',
  borderBottom: '2px solid #092940'
}

const themeConfig = createTheme({
  typography: {
    fontFamily: 'Public Sans, sans-serif',
    color: lightThemeColors.blue_900
  },
  palette: {
    primary: {
      main: lightThemeColors.blue_100
    },
    secondary: {
      main: lightThemeColors.sweetCorn
    }
  }
})

export const MUITheme = responsiveFontSizes(themeConfig)
