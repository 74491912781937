import { CACHED_QUERY_PROPS, USER_PREFERENCES_KEY } from 'constants/queries'
import { defaultUserPreferences } from 'constants/users'
import { any, Lens, lensPath, mergeDeepLeft } from 'ramda'
import { QueryClient } from 'react-query'
import { Bookrunner, Organization } from 'services/organizations'
import { getUserProfile, ProfileDTO, updateUserPreferences, UserPreferencesDTO } from 'services/users'
import { ISpecificPath } from 'lib/types'
import { ICompleteWithdrawDealForm } from 'routes/TermsheetV2ViewPage/components/WithdrawDeal/types'

export const getUserPreferenceView = <T>(data: Partial<ProfileDTO> | undefined, preferencePath: string[]): {
  userPreferences: UserPreferencesDTO
  preferenceLens: Lens<UserPreferencesDTO, T>
} => {
  const preferencesFromBackend = data?.user?.preferences ?? {}
  const userPreferences = mergeDeepLeft(preferencesFromBackend ?? {}, defaultUserPreferences)
  const preferenceLens = lensPath<UserPreferencesDTO, T>(preferencePath)
  return {
    userPreferences,
    preferenceLens
  }
}

export const dbUpdateUserPreferences = async (newUserPreferences: UserPreferencesDTO): Promise<void> => {
  await updateUserPreferences(newUserPreferences)
}
export const getUserData = async (queryClient: QueryClient): Promise<ProfileDTO> => {
  return await queryClient.fetchQuery<ProfileDTO>(
    USER_PREFERENCES_KEY,
    getUserProfile,
    { ...CACHED_QUERY_PROPS, retryDelay: 3000 }
  )
}

export const filterBookrunnerBySelectedBank = (selectedBank: Organization, bookrunner: Bookrunner): boolean =>
  (!!bookrunner?.parentId && [selectedBank?.parentId, selectedBank?.id].includes(bookrunner.parentId)) ||
  !!(bookrunner?.id && selectedBank?.canActAsAgentFor?.includes(bookrunner.id))

export const comparePaths = (pathname: string, paths: ISpecificPath[]): boolean =>
  any((item: ISpecificPath) =>
    item.compare === 'includes'
      ? pathname.includes(item.path)
      : pathname === item.path
  )(paths)

export const isEligibleForWithdraw = (obj: any): obj is ICompleteWithdrawDealForm => {
  return 'finalOfferingSizeLocalCcy' in obj
}
