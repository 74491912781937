import { QIBS, REG_S_ONLY } from './index'
// Hardcoded organizations that we use to create accounts. @todo move to the back-end API.

export const TEST_PROTO_ORGANIZATION_ID = '3da2385e-8584-42a4-a727-a5aab0787b66'

export const USER_ACCOUNT_ROLES = {
  DEFAULT_USER: 'DEFAULT_USER',
  TAAS: 'TAAS',
  ADMIN: 'ADMIN'
}

export const ORGANIZATION_TYPES = {
  INVESTOR: 'INVESTOR',
  BANKER: 'BANKER',
  PRIMARY_PORTAL_AGENT: 'PRIMARY_PORTAL_AGENT',
  SELL_SIDE_SALES: 'SELL_SIDE_SALES'
}

export enum ORGANIZATION_DASHBOARD_TYPE {
  TERMSHEET= 'TERMSHEET',
  DEAL= 'DEAL'
}

export const DISTRIBUTION_STATUSES = {
  QIB: QIBS,
  REG_S: REG_S_ONLY
}
