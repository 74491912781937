import styled from '@emotion/styled'

export const ConfirmModalStyled = styled.div`
  margin: 0 auto;
  text-align: center;
  .confirmation-modal-cancel {
    text-align: right;
    &:hover {
      cursor: pointer;
    }
    svg {
      color: ${({ theme }) => theme.blue_900};
    }
  }
  .confirmation-modal-bell {
    margin-bottom: 19px;
    svg {
      color: ${({ theme }) => theme.orange};
      font-size: 45px;
    }
  }
  h3 {
    margin-bottom: 20px;
  }
  .confirmation-modal-issuer-name {
    margin-bottom: 15px;
    font-weight: 500;
    color: #585fff;
  }
  .confirmation-modal-edit {
    & > button {
      margin-left: 0;
      padding: 3px 20px;
      font-weight: 400;
      font-size: 12px;
      min-width: auto;
    }
  }
  .confirmation-modal-text {
    margin-top: 42px;
    margin-bottom: 20px;
  }
  .confirmation-modal-confirm {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    width: 70%;
    & > div {
      width: 48%;
    }
    & > div:last-of-type {
      margin: 0;
    }
    .MuiOutlinedInput-input {
      padding: 10px 14px;
    }
    .MuiButton-text {
      padding: 9px 8px;
    }
  }
`
