import React, { createContext, ReactNode, useMemo, useState } from 'react'
import { ModalComponent } from '../components/ModalComponent'

export const ModalContext = createContext<any>(undefined)

export function ModalProvider ({ children }: { children: ReactNode }): JSX.Element {
  const [isModal, setIsModal] = useState(false)
  const [isWithoutContentWrapper, setIsWithoutContentWrapper] = useState(false)
  const [modalContent, setModalContent] = useState<ReactNode>()
  const [maxWidth, setMaxWidth] = useState('md')
  const setIsModalHandler = (value: boolean, isWithoutContentWrapper?: boolean): void => {
    setIsModal(value)
    setIsWithoutContentWrapper(isWithoutContentWrapper ?? false)
  }

  const getModalContent = (content: ReactNode): void => setModalContent(content)

  const modalContentExists = useMemo(() => modalContent !== undefined &&
  modalContent !== null && Object.keys(modalContent).length > 0, [modalContent])

  return (
    <ModalContext.Provider value={{ getModalContent, setIsModal: setIsModalHandler, setMaxWidth }}>
      {isWithoutContentWrapper && modalContentExists && modalContent}
      {!isWithoutContentWrapper && (
        <ModalComponent
          isOpen={isModal}
          handleModalClose={() => {
            setMaxWidth('md')
            setIsModalHandler(false)
          }}
          maxWidth={maxWidth as any}
        >
          {modalContentExists && modalContent}
        </ModalComponent>
      )}

      {children}
    </ModalContext.Provider>
  )
}
