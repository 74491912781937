import { useIsSidebarEnabledByFF } from 'components/Notifications/commonHooks'
import { useUserPreferences } from 'lib'
import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { getNotifications } from 'services/notifications'
import { NOTIFICATION_CENTER_OPEN, NOTIFICATIONS_QUERY } from './constants'

interface useNotificationIconReturn {
  enableDueToFeatureFlag: boolean
  showNewNotificationsIndicator: boolean
  onClick: () => void
}

export const useNotificationIcon = (): useNotificationIconReturn => {
  const [open, setOpen] = useUserPreferences<boolean>([NOTIFICATION_CENTER_OPEN])
  const enableDueToFeatureFlag = useIsSidebarEnabledByFF()
  const [notificationsHaveChanged, setNotificationsHaveChanged] = useState<boolean>(false)
  const [mostRecentNotificationId, setMostRecentNotificationId] = useState<string>()

  const { data, refetch } = useQuery(NOTIFICATIONS_QUERY, getNotifications, {
    staleTime: 5000,
    refetchInterval: 45000
  })

  useEffect(() => {
    if (open) void refetch()
  }, [open, refetch])

  useEffect(() => {
    if (data !== undefined && data.length > 0 && data[0].id !== mostRecentNotificationId) {
      setNotificationsHaveChanged(true)
      setMostRecentNotificationId(data[0].id)
    }
  }, [data, mostRecentNotificationId])

  return {
    enableDueToFeatureFlag,
    showNewNotificationsIndicator: notificationsHaveChanged && !open,
    onClick: () => {
      void setOpen(!open)
      setNotificationsHaveChanged(false)
    }
  }
}
