
import React, { useEffect, useState } from 'react'
import { Snackbar } from '@material-ui/core'
import { getAppEtag } from 'services/etag'

export const CacheBustNotificationSnackbar = (): JSX.Element => {
  const [fetchedEtag, setFetchedEtag] = useState<string | null>(null)
  const [localEtag, setLocalEtag] = useState<string | null>(null)
  const [isVisible, setIsVisible] = useState<boolean>(false)

  useEffect(() => {
    const int = setInterval((): void => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      (getAppEtag().then(val => {
        setFetchedEtag(val)
      }))
    }, 60000)

    return () => clearInterval(int)
  }, [])

  if (fetchedEtag !== null) {
    if (localEtag === null) {
      setLocalEtag(fetchedEtag)
    } else if (fetchedEtag !== localEtag) {
      setLocalEtag(fetchedEtag)
      setIsVisible(true)
    }
  }

  const reloadWebPage = (): void => {
    window.location.reload()
  }

  const pointer = { cursor: 'pointer' }

  return (
    <Snackbar
      open={isVisible}
      message='A new version is available. Click here to reload.'
      onClick={reloadWebPage}
      style={pointer}
    />
  )
}
