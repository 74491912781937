import clsx from 'clsx'
import React, { forwardRef } from 'react'
import { FormError } from './FormError'
import { FormLabel } from './FormLabel'
import { ReactHookFormProps } from './types'

interface Props extends ReactHookFormProps {
  label: string
  autoComplete?: string
  disabled?: boolean
  readOnly?: boolean
  required?: boolean
  placeholder?: string
  type?: 'text' | 'password' | 'email' | 'number'
  value?: string
  dataQa?: string
}

export const FormFieldText = forwardRef<HTMLInputElement, Props>((props: Props, ref): JSX.Element => {
  const valid = (props.errors ?? {})[props.name] == null

  const className = clsx(
    'appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm',
    {
      'border-red-500 focus:border-red-500': !valid,
      'text-gray-500 focus:ring-width-0 select-none': props.readOnly,
      'bg-gray-400': props.disabled
    }
  )

  const htmlFor = props.name

  return (
    <div>
      <FormLabel htmlFor={htmlFor} label={props.label} />
      <div className='mt-2'>
        <input
          ref={ref}
          id={htmlFor}
          type={props.type ?? 'text'}
          className={className}
          autoComplete={props.autoComplete}
          required={props.required}
          disabled={props.disabled}
          readOnly={props.readOnly}
          placeholder={props.placeholder}
          value={props.value}
          name={props.name}
          onChange={props.onChange}
          onBlur={props.onBlur}
          data-qa={props.dataQa}
        />
        <FormError errors={props.errors ?? {}} name={props.name} />
      </div>
    </div>
  )
})
