import React, { createContext, useContext, useState } from 'react'

export const NavbarBackButtonContext = createContext({})

function NavbarBackButtonProvider ({ children }: any): JSX.Element {
  const [handleClick, setHandleClick] = useState<(() => {})|null>(null)
  const [label, setLabel] = useState<string|null>(null)
  return <NavbarBackButtonContext.Provider value={{ setHandleClick, setLabel, handleClick, label }}>{children}</NavbarBackButtonContext.Provider>
}

const useNavbarBackButtonContext = (): {
  label: string|null
  setLabel: ((s: string|null) => void)
  handleClick: (() => {})|null
  setHandleClick: ((v: (() => {}) | null) => {})
} => useContext<any>(NavbarBackButtonContext)

export { NavbarBackButtonProvider, useNavbarBackButtonContext }
