import React, { useCallback, useState } from 'react'
import CancelIcon from '@material-ui/icons/Cancel'
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone'
import { Button, TextField } from '@material-ui/core'
import { useTermsheetModalContext } from 'contexts/modal-context/TermsheetModals/context'
import { ConfirmModalStyled } from 'contexts/modal-context/TermsheetModals/ConfirmModalStyled'
import { SubmitButtonAddNewDealStyled } from 'components/Buttons/AddNewDeal/PublishButtonAddNewDeal'

export function ConfirmDeleteFileModal (
  { submitHandler, setIsModalOpen, filename }:
  {submitHandler: () => {}, setIsModalOpen: (isModalOpen: boolean) => void, filename: string}): JSX.Element {
  const [confirmTextBoxState, setConfirmTextBoxState] = useState('')
  const { setSubmitHandler } = useTermsheetModalContext()
  const isDisabled = confirmTextBoxState !== 'OK'

  const onClose = useCallback(() => {
    setIsModalOpen(false)
    setSubmitHandler(null)
  }, [setIsModalOpen, setSubmitHandler])
  return (
    <ConfirmModalStyled>
      <div onClick={onClose} className='confirmation-modal-cancel'>
        <CancelIcon />
      </div>
      <div className='confirmation-modal-bell'>
        <NotificationsNoneIcon />
      </div>
      <h3>You are about to delete a file</h3>
      <p className='confirmation-modal-issuer-name'>{filename}</p>
      <p className='confirmation-modal-text'>
        To confirm, type ‘OK’ in the box below and hit delete
      </p>
      <div className='confirmation-modal-confirm'>
        <TextField
          variant='outlined'
          value={confirmTextBoxState}
          onChange={(e) => setConfirmTextBoxState(e.target.value)}
          inputProps={{ 'data-qa': 'ConfirmDeleteFileModal-TextInput' }}
        />
        <SubmitButtonAddNewDealStyled>
          <Button
            onClick={() => {
              setIsModalOpen(false)
              submitHandler()
              onClose()
            }}
            disabled={isDisabled}
            className='MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-fullWidth'
            fullWidth
            data-qa='ConfirmDeleteFileModal-delete-button'
          >
            DELETE
          </Button>
        </SubmitButtonAddNewDealStyled>
      </div>

    </ConfirmModalStyled>
  )
}
