import { TEST_PROTO_ORGANIZATION_ID, USER_ACCOUNT_ROLES, ORGANIZATION_TYPES } from '../constants/organizations'
import { useProfileHook } from '../contexts/user-auth-context'

export interface UserRoles {
  isTaaS: boolean
  isTestBankUser: boolean
  isBanker: boolean
  isInvestor: boolean
  isSSS: boolean
}
export const useUserRole = (): UserRoles => {
  const [profile] = useProfileHook()

  const isTaaS = USER_ACCOUNT_ROLES.TAAS === profile.accountRole
  const isTestBankUser = [TEST_PROTO_ORGANIZATION_ID].includes(profile.accountOrganizationId)
  const isBanker = ORGANIZATION_TYPES.BANKER === profile.accountType
  const isInvestor = ORGANIZATION_TYPES.INVESTOR === profile.accountType
  const isSSS = ORGANIZATION_TYPES.SELL_SIDE_SALES === profile.accountType
  return {
    isTaaS,
    isTestBankUser,
    isBanker,
    isInvestor,
    isSSS
  }
}
