import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

// Guide: https://docs.sentry.io/platforms/javascript/guides/react/
export function configureSentry (env?: string): void {
  Sentry.init({
    dsn: 'https://26314d1c69fa49ac9ce418d6a6643832@o414373.ingest.sentry.io/5303787',
    environment: env,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: env === 'local' ? 1 : 0.2
  })
}
