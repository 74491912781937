import { isDateInThePast, formatDateString, isYoungerThan } from 'utils/dateHelpers'
import { complement, either, equals, isEmpty, isNil, type } from 'ramda'

const isReallyEmpty = either(isNil, isEmpty)
const isUndefined = (value: any): boolean => type(value) === 'Undefined'
const isReallyNotEmpty = <T>(input?: T|null): input is T => complement(isReallyEmpty)(input)
const notEqual = complement(equals)

export {
  isReallyEmpty,
  isReallyNotEmpty,
  isDateInThePast,
  formatDateString,
  isUndefined,
  isYoungerThan,
  notEqual
}
