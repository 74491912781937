import React from 'react'
import { useIsSpecificPathWatch, useScreenMetadata } from 'lib/hooks'
import { SecondaryNavigationBar } from '@ppui/ui-components'
import { Links } from 'components/InternalNavbar/Links'
import { useFeatureFlags } from 'lib/useFeatureFlags'
import { FEATURES } from 'constants/features'
import { NotificationsIcon } from 'components/Notifications'

export const InternalNavbar: React.FC = () => {
  const { isMobile } = useScreenMetadata()
  const isBuySideSignUp = useIsSpecificPathWatch([{
    path: '/ecm/buy-side-sign-up',
    compare: 'includes'
  }])

  const { isFeatureFlagEnabled } = useFeatureFlags()
  const showInternalNavbar: boolean = isFeatureFlagEnabled(FEATURES.showInternalNavbar)
  if (!showInternalNavbar || isBuySideSignUp || isMobile) {
    return <></>
  }
  return (
    <SecondaryNavigationBar>
      <div className='flex w-full justify-center'>
        <div className='container flex'>
          <Links />
          <NotificationsIcon />
        </div>
      </div>
    </SecondaryNavigationBar>
  )
}
