import { RootStore } from 'store/rootStore'
import { makeAutoObservable } from 'mobx'
import { equals } from 'ramda'
import { ITermsheetV2Entity } from 'store/types'

export class RfnJourneyStore {
  private readonly rootStore: RootStore
  private _showRfnSignUpRequestForm: boolean = true
  private _termsheetListV2: ITermsheetV2Entity[] = []
  private _termsheetToShow: ITermsheetV2Entity = {} as unknown as ITermsheetV2Entity

  constructor (rootStore: RootStore) {
    this.rootStore = rootStore
    makeAutoObservable(this)
  }

  set showRfnSignUpRequestForm (value: boolean) {
    this._showRfnSignUpRequestForm = value
  }

  get showRfnSignUpRequestForm (): boolean {
    return this._showRfnSignUpRequestForm
  }

  set partnerTermsheets (data: ITermsheetV2Entity[] | undefined) {
    if (equals(data, this._termsheetListV2)) return
    this._termsheetListV2 = data === undefined ? [] : data
    this.termsheetToShow = this._termsheetListV2[0]
  }

  get partnerTermsheets (): ITermsheetV2Entity[] {
    return this._termsheetListV2
  }

  set termsheetToShow (data: ITermsheetV2Entity | undefined) {
    if (equals(data, this._termsheetToShow)) return
    this._termsheetToShow = data === undefined ? {} as unknown as ITermsheetV2Entity : data
  }

  get termsheetToShow (): ITermsheetV2Entity {
    return this._termsheetToShow
  }
}
