import { Auth } from '@aws-amplify/auth'
import React, { createContext, ReactNode, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { handleLogout } from 'services/auth'
import { setUserData } from 'config/configureGoogleAnalytics'
import { useGetUserProfile } from 'lib/queries/useGetUserProfile'
import { AccountType } from 'lib/types'
import { USER_ACCOUNT_ROLES } from 'constants/organizations'

export const ProfileStateContext = createContext<any>(undefined)
export const ProfileDispatchContext = createContext<any>(undefined)

export interface ProfileState {
  isAuthenticated: boolean
  isAuthenticationRequestDone: boolean
  accountOrganizationName: string
  accountOrganizationId: string
  accountLogo: string
  accountRole: string
  accountType: string
  email: string
  family_name: string
  given_name: string
  phone_number: string
  firm_name: string
  lei: string
  termsAndConditionsAcceptance: boolean
  sub: string
  isMfe: boolean
}

const DEFAULT_PROFILE_VALUES: ProfileState = {
  // Organization, authentication and authorization
  isAuthenticated: false,
  isAuthenticationRequestDone: false,
  accountOrganizationName: '', // The name of the organization to whom this account belongs to.
  accountOrganizationId: '', // The organizationId as it is on the back-end.
  accountLogo: '', // The company or client logo of the account
  accountRole: '', // The role the account plays in the organization, i.e: [Admin, Support, Trader, Compliance, Manager, TaaS]
  accountType: '', // The user type which could be [BANKER, INVESTOR, PRIMARY_PORTAL_AGENT]
  // User profile names and contact information.
  email: '',
  family_name: 'Primary Portal',
  given_name: 'Public',
  phone_number: '+316123456789',
  // Investors attributes.
  firm_name: '',
  lei: '',
  termsAndConditionsAcceptance: false,
  sub: '',
  isMfe: false
}

export const USER_REDUCER_TYPES = {
  LOGOUT: 'LOGOUT',
  LOGIN: 'LOGOUT',
  SET_ATTRIBUTES: 'SET_ATTRIBUTES',
  SET_HAS_AUTHENTICATED: 'SET_HAS_AUTHENTICATED',
  SET_USER_GROUP: 'SET_USER_GROUP'
}

function verifyUserOrganizationAttributes (userAttributes): any {
  if (userAttributes === undefined) {
    const message = '[user-auth-context/index] userAttributes is undefined. It seems the user doesn\'t have any userAttributes.'
    console.warn(message)
    return {}
  }
  // Parse organization attributes
  const { organizationId, organizationName, accountType, id, email } = userAttributes.user

  const accountOrganizationId: string | undefined = organizationId
  const accountOrganizationName: string | undefined = organizationName
  const accountRole: string | undefined = accountType === AccountType.PRIMARY_PORTAL_AGENT ? USER_ACCOUNT_ROLES.TAAS : USER_ACCOUNT_ROLES.DEFAULT_USER
  const accountLogo: string | undefined = userAttributes.organization.logo

  const organizationAttributes = {
    accountOrganizationId,
    accountOrganizationName,
    accountType,
    accountRole,
    accountLogo
  }

  setUserData({
    ...organizationAttributes,
    userId: id,
    userEmail: email
  })

  if (accountOrganizationId === undefined || accountOrganizationId.length < 3) {
    const message = '[user-auth-context/index] accountOrganizationId is undefined. It seems the user doesn\'t belong to any organization.'
    console.warn(message)
  } else {
    return organizationAttributes
  }
}

// We want to the reducer as a pure function outside of the Profile Provider.
function userReducer (state: ProfileState, action: { type: string, payload: Record<string, any> }): ProfileState {
  const { type, payload } = action
  switch (type) {
    case USER_REDUCER_TYPES.SET_ATTRIBUTES: {
      // Here we do need to handle errors if the user has faulty organization values.
      const userAttributes: Record<any, any> | undefined = payload.attributes
      if (userAttributes === undefined) {
        console.warn('[user-auth-context/index] userAttributes is undefined. It seems the user doesn\'t have any userAttributes.')
        return DEFAULT_PROFILE_VALUES
      } else {
        const organizationAttributes = verifyUserOrganizationAttributes(payload.attributes)
        const { accountOrganizationId, accountOrganizationName, accountType, accountRole, accountLogo } = organizationAttributes

        return {
          ...state,
          ...payload.attributes,
          // State the organization attributs explicitly for readability.
          accountOrganizationId,
          accountOrganizationName,
          accountType,
          accountRole,
          accountLogo
        }
      }
    }
    case USER_REDUCER_TYPES.SET_HAS_AUTHENTICATED: {
      return { ...state, isAuthenticated: payload.hasAuthenticated, isAuthenticationRequestDone: true }
    }
    case USER_REDUCER_TYPES.LOGOUT: {
      // TODO async side-effect should not be present in the reducer
      handleLogout() // eslint-disable-line
      return { ...state, isAuthenticated: false, isAuthenticationRequestDone: false }
    }
    case USER_REDUCER_TYPES.LOGIN: {
      return { ...state, isAuthenticated: true, isAuthenticationRequestDone: true }
    }
    default: {
      throw new Error(`Unhandled action type: ${type}`)
    }
  }
}

function ProfileProvider ({ children, isMfe = false }: { children: ReactNode, isMfe?: boolean }): JSX.Element {
  const history = useHistory()
  const [state, dispatch] = React.useReducer(userReducer, { ...DEFAULT_PROFILE_VALUES, isMfe })
  const { data } = useGetUserProfile()

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    (async function () {
      try {
        const { getSession, getUser } = isMfe
          ? await import('@pp/utils')
          : {
              getSession: async () => await Auth.currentSession(),
              getUser: async () => await Auth.currentAuthenticatedUser()
            }

        const session = await getSession()

        if (!session.isValid()) {
          throw new Error('session invalid')
        }
        if (data != null) {
          await getUser()
            .then(async (user) => {
              dispatch({
                type: USER_REDUCER_TYPES.SET_HAS_AUTHENTICATED,
                payload: {
                  hasAuthenticated: true,
                  isAuthenticationRequestDone: true
                }
              })
              dispatch({
                type: USER_REDUCER_TYPES.SET_ATTRIBUTES,
                payload: {
                  attributes: data
                }
              })
            })
        }
      } catch (e) {
        dispatch({
          type: USER_REDUCER_TYPES.SET_HAS_AUTHENTICATED,
          payload: {
            hasAuthenticated: false,
            isAuthenticationRequestDone: true
          }
        })
      }
    })()
  }, [state.isAuthenticated, history, isMfe, data])

  return (
    <ProfileStateContext.Provider value={state}>
      <ProfileDispatchContext.Provider value={dispatch}>{children}</ProfileDispatchContext.Provider>
    </ProfileStateContext.Provider>
  )
}

function useProfileDispatch (): any {
  const context = React.useContext<any>(ProfileDispatchContext)
  if (context === undefined) {
    throw new Error('useProfileDispatch must be used within a ProfileProvider')
  }
  return context
}

function useProfileState (): ProfileState {
  const context = React.useContext<any>(ProfileStateContext)
  if (context === undefined) {
    throw new Error('useProfileState must be used within a ProfileProvider')
  }
  return context
}

function useProfileHook (): [ProfileState, any] {
  return [useProfileState(), useProfileDispatch()]
}

export { ProfileProvider, useProfileHook, useProfileDispatch, useProfileState }
