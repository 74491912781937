export async function getAppEtag (): Promise<string | null> {
  return await fetch('/', { method: 'HEAD' })
    .then(async (response) => {
      return response.headers.get('etag')
    })
    .catch(() => {
      console.warn('Could not fetch latest etag for index.html')
      return null
    })
}
