interface FeatureFlagMap {
  [key: string]: boolean
}

export const getFeatureFlagHideECMRelatedFeature = (envName, wsEndpoint): FeatureFlagMap => {
  // client-config returns 'env1' for e2e tests so flag for env1 should be off to pass tests
  const isE2eEnv = wsEndpoint === undefined ? true : wsEndpoint.includes('e2e')
  const env1Flag = !isE2eEnv
  const FeatureFlagHideECMRelatedFeature = { env1: env1Flag, test: true, pse: true, live: true }
  if (envName && /\d{3}/.test(envName)) {
    return { env1: env1Flag, test: false, pse: true, live: true, [envName]: true }
  }
  return FeatureFlagHideECMRelatedFeature
}
