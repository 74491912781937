import React from 'react'
import { ErrorMessage } from '@hookform/error-message'
import { FieldErrors } from 'react-hook-form-v7'

interface Props {
  name: string
  errors: FieldErrors
}

export function FormError (props: Props): JSX.Element {
  return (
    <div className='mt-1 ml-2 text-xs text-red-700'>
      <ErrorMessage
        errors={props.errors} name={props.name}
        render={({ message, messages }) => {
          if (Object.keys(messages ?? []).length > 0) {
            return (
              <>
                {Object.entries(messages ?? []).map(([type, message]) => {
                  return <p key={type}>{message}</p>
                })}
              </>
            )
          }
          return <p>{message}</p>
        }}
      />
    </div>
  )
}
