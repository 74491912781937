import React, { useState } from 'react'
import { XIcon } from '@heroicons/react/solid'
declare let MR_ID: string
declare let PROJECT_URL: string
export const GitlabMRLink: React.FC<{envName: string}> = ({ envName }) => {
  const [isOpen, setIsOpen] = useState(true)
  const url = `${PROJECT_URL}/-/merge_requests/${MR_ID}`
  if (!MR_ID || envName === 'test' || !isOpen) return null
  return (
    <div className='fixed inset-0 flex px-4 py-6 pointer-events-none items-end'>
      <div className='w-full flex flex-col space-y-4 items-end'>
        <div className='bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden'>
          <div className='p-4'>
            <div className='flex items-center gap-2'>
              <a
                href={url}
                target='_blank'
                rel='noreferrer'
                className='block bg-white rounded-md text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
              >
                {url}
              </a>
              <XIcon className='w-5 h-5 cursor-pointer' onClick={() => setIsOpen(false)} />
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}
