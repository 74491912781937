import { RootStore } from 'store/rootStore'
import { makeAutoObservable } from 'mobx'
import { Attachment, IApplicationForm, IApplicationFormMeta, IStep, ITermsheetV2Entity, ONE_OF_STEPS } from 'store/types'
import {
  defaultMetaValues,
  FIRST_STEP_NAME,
  SECOND_STEP_NAME, STEPS_ORDER
} from 'store/applicationGeneratorStore/const'
import { Organization } from 'services/organizations'
import { assocPath, identity, mergeDeepLeft, pathOr, pickAll } from 'ramda'
import { mapFormFieldsToApiFields } from 'store/applicationGeneratorStore/utils'
import { CONSISTENT_WITH_API_FIELDS, FORM_TO_API_FIELDS_MAPPING } from 'store/applicationGeneratorStore/termsheetFieldsMapping'
import {
  apiToUIViewMapping,
  bottomApiToUIViewMapping, timetableApiToUIViewMapping
} from 'routes/TermsheetV2ViewPage/tsMappers'
import { getExistingApiFieldKeysToBeMapped, getTsPathBasedOnKeyAndType } from 'utils/tsHelpers'
import { IApiToUIViewMapping } from 'routes/TermsheetV2ViewPage/types'

export class ApplicationGeneratorStore {
  private readonly _rootStore: RootStore
  private _bankers: Organization[] = []
  private _bankersById: {[key: string]: Organization} = {}
  private _steps: IApplicationForm = {}
  private _attachments: Attachment[] = []
  private _dirtyAttachments: Attachment[] = []

  private _meta: IApplicationFormMeta = defaultMetaValues
  constructor (rootStore: RootStore) {
    this._rootStore = rootStore
    makeAutoObservable(this)
  }

  set bankers (value: Organization[]) {
    this._bankers = value
    this._bankersById = value.reduce((acc, item) => ({
      ...acc,
      [item.id]: item
    }), {})
  }

  get bankers (): Organization[] {
    return this._bankers
  }

  get bankersById (): {[key: string]: Organization} {
    return this._bankersById
  }

  get steps (): IApplicationForm {
    return this._steps
  }

  get attachments (): Attachment[] {
    return this._attachments
  }

  set attachments (value) {
    this._attachments = value
  }

  get dirtyAttachments (): Attachment[] {
    return this._dirtyAttachments
  }

  set dirtyAttachments (value) {
    this._dirtyAttachments = value
  }

  saveStep (stepName: ONE_OF_STEPS, data: IStep): void {
    this._steps = { ...this._steps, [stepName]: data }
  }

  saveAllStep (data: IApplicationForm): void {
    this._steps = { ...data }
  }

  getStep<T extends ONE_OF_STEPS,> (stepName: T): IStep | {} {
    return this._steps[stepName] ?? {}
  }

  private generateStepsFromApiFields (data: ITermsheetV2Entity, mapping: IApiToUIViewMapping, suffix?: string): IApplicationForm {
    const allowedKeysToBeMapped = getExistingApiFieldKeysToBeMapped(data, mapping, suffix)
    return allowedKeysToBeMapped.reduce((acc, key) => {
      const { uiFormField = '', formPageValueMapper = identity } = mapping[key]
      const [stepName, fieldName] = uiFormField.split('.')
      if (!fieldName || !STEPS_ORDER.includes(stepName)) {
        return acc
      }
      const tsPath = getTsPathBasedOnKeyAndType(key, data?.offeringType, suffix)
      return assocPath([stepName, fieldName], formPageValueMapper(pathOr('', tsPath, data), data), acc)
    }, {})
  }

  saveAllStepsFromApi (data: ITermsheetV2Entity): void {
    const stepsWithoutTimetable = this.generateStepsFromApiFields(data, { ...apiToUIViewMapping, ...bottomApiToUIViewMapping })
    const stepsWithTimetable = this.generateStepsFromApiFields(data, timetableApiToUIViewMapping, 'Timetable')
    this.attachments = data.attachments ?? []
    this.saveAllStep(mergeDeepLeft({ ...stepsWithoutTimetable, attachments: this.attachments }, stepsWithTimetable))
  }

  getAllStepsPreparedForApiByStepData (firstStep: IStep, secondStep: IStep): ITermsheetV2Entity {
    return {
      ...pickAll<IStep, Partial<ITermsheetV2Entity>>(CONSISTENT_WITH_API_FIELDS[FIRST_STEP_NAME], firstStep),
      ...pickAll<IStep, Partial<ITermsheetV2Entity>>(CONSISTENT_WITH_API_FIELDS[SECOND_STEP_NAME], secondStep),
      ...this.getMappedFieldsToApi(firstStep, secondStep),
      offeringType: this._meta.offeringType
    } as unknown as ITermsheetV2Entity
  }

  getAllStepsPreparedForApi (): ITermsheetV2Entity {
    return this.getAllStepsPreparedForApiByStepData(this.getStep(FIRST_STEP_NAME), this.getStep(SECOND_STEP_NAME))
  }

  getMappedFieldsToApi (firstStep: IStep, secondStep: IStep): Partial<IStep> {
    const firstStepFields: Record<string, any> = pickAll(
      Object.keys(FORM_TO_API_FIELDS_MAPPING[FIRST_STEP_NAME]),
      firstStep
    )
    const secondStepFIelds: Record<string, any> = pickAll(
      Object.keys(FORM_TO_API_FIELDS_MAPPING[SECOND_STEP_NAME]),
      secondStep
    )
    return {
      ...mapFormFieldsToApiFields(
        firstStepFields,
        FORM_TO_API_FIELDS_MAPPING[FIRST_STEP_NAME],
        this._meta.offeringType
      ),
      ...mapFormFieldsToApiFields(
        { ...firstStepFields, ...secondStepFIelds },
        FORM_TO_API_FIELDS_MAPPING[SECOND_STEP_NAME],
        this._meta.offeringType
      )
    }
  }

  clearData (): void {
    this._steps = {}
    this._meta = { ...defaultMetaValues }
  }

  set meta (value: IApplicationFormMeta) {
    this._meta = value
  }

  get meta (): IApplicationFormMeta {
    return this._meta
  }
}
