export const QUERY_DASHBOARD = 'dashboard'
export const QUERY_DASHBOARD_V2 = 'dashboardV2'
export const CACHED_QUERY_PROPS = {
  cacheTime: Infinity,
  refetchOnMount: false,
  refetchOnReconnect: false,
  refetchOnWindowFocus: false,
  staleTime: Infinity
}

export const USER_PREFERENCES_KEY = 'client-preferences'
