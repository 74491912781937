import { API } from 'services/aws-api'

export enum TelemetryStatus {
  BUTTON_CLICKED = 'BUTTON_CLICKED',
  LINK_CLICKED = 'LINK_CLICKED',
  PAGE_LOADED = 'PAGE_LOADED',
  DROPDOWN_OPENED = 'DROPDOWN_OPENED'
}

export interface TelemetryData {
  userId?: string
  timestamp: string
  addressIP?: string
  userAgent: string
  referrer?: string
  buttonName?: string
  linkURL?: string
  linkQueryString?: string
  pageName?: string
  deviceType?: 'desktop' | 'mobile'
  pageURL?: string
  dealId?: string
  termsheetId?: string
  status?: TelemetryStatus
}

export async function sendTelemetryData (data: TelemetryData): Promise<void> {
  await API<TelemetryData>().post({
    path: '/v2/telemetry',
    init: { body: { ...data } }
  })
}
