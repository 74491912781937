import { options } from 'linkifyjs'
export const linkifyDefaults = {
  ...options.defaults,
  target: '_blank',
  className: 'text-purple-800 font-bold',
  validate: {
    url: (value: string, type: string) => {
      switch (type) {
        case 'url':
          return /^https?:\/\/|www|ww1/i.test(value)
        default:
          return true
      }
    }
  }
}
