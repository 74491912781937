import { Auth } from '@aws-amplify/auth'
import { onError } from 'lib'
import { signOut as mfeSignOut, getUser as mfeGetUser } from '@pp/utils'

interface SentrifiedAuthReturn {
  signOut: () => Promise<any>
  currentAuthenticatedUser: () => Promise<any>
  updateUserAttributes: (currentUser: any, attribute: {}) => Promise<any>
}

export function SentrifiedAuth (isMfe: boolean): SentrifiedAuthReturn {
  const { awsSignOut, awsCurrentAuthenticatedUser } = !isMfe
    ? {
        awsSignOut: async () => await Auth.signOut(),
        awsCurrentAuthenticatedUser: async () => await Auth.currentAuthenticatedUser()
      }
    : {
        awsSignOut: async () => await mfeSignOut(),
        awsCurrentAuthenticatedUser: async () => await mfeGetUser()
      }

  const signOut = async (): Promise<any> => await awsSignOut()
    .then(() => {
      window.location.replace(window.location.origin)
    })
    .catch((error) => onError({ error, AWSAmplifyMethod: 'Auth.signOut()' }))

  const currentAuthenticatedUser = async (): Promise<any> => await awsCurrentAuthenticatedUser()
    .catch((error) => onError({ error, AWSAmplifyMethod: 'Auth.currentAuthenticatedUser()' }))

  const updateUserAttributes = async (...args: [any, {}]): Promise<unknown> => await Auth.updateUserAttributes(...args)
    .catch((error) => onError({ error, AWSAmplifyMethod: 'Auth.updateUserAttributes()' }))

  return {
    signOut,
    currentAuthenticatedUser,
    updateUserAttributes
  }
}
