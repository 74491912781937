import React from 'react'
export const DEFAULT_ERROR = <h2>This page isn't working right now.</h2>
export const ERRORS = {
  CONFIG_ERROR: DEFAULT_ERROR,
  WS_ERROR: (
    <section>
      <h2 className='mb-4'>It appears that you do not have websockets enabled. This is a requirement for Primary Portal to work. Please send this information to your IT team to enable websockets</h2>
      <h2>High level requirements</h2>
      <h2>Web</h2>
      <article>
        <p>HTTPS and Websocket traffic must be permitted to these domains and any subdomains under them:</p>
        <ul className='list-inside list-disc'>
          <li>primary-portal.com</li>
          <li>primaryportal.com</li>
        </ul>
        <p>All traffic is standard HTTPS or Websocket traffic over port 443.</p>
        <p>Alternatively, contact us at&nbsp;
          <a
            className='text-blue-600'
            href='mailto:experience@primaryportal.com'
          >
            experience@primaryportal.com
          </a> for further assistance
        </p>
      </article>
    </section>
  )
}
