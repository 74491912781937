import React from 'react'
import { ItemProps } from 'components/Tabs/types'
import classnames from 'classnames'

const Item = <T,>({ label, isActiveTab, onChange, index }: ItemProps<T>): JSX.Element => {
  return (
    <li className='mr-2'>
      <a
        href='#'
        data-qa={`Tab-Item-${label as unknown as string}`}
        className={classnames(
          'inline-block p-4 rounded-t-lg border-b-2',
          { 'text-blue-600 border-blue-600 active dark:text-blue-500 dark:border-blue-500': isActiveTab },
          { 'border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300': !isActiveTab }
        )}
        aria-current={isActiveTab ? 'page' : undefined}
        onClick={() => !isActiveTab && onChange(index)}
      >{label}
      </a>
    </li>
  )
}

export default React.memo(Item) as typeof Item
