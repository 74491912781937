import React from 'react'

import { withStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import InfoIcon from '@material-ui/icons/Info'

export function TooltipReusable ({ helperText, small = false, classNames }: {helperText: string | JSX.Element, small?: boolean, classNames?: string}): JSX.Element {
  return (
    <HtmlTooltip
      title={
        <>
          <Typography color='inherit'>{helperText}</Typography>
        </>
        }
    >
      {small
        ? <InfoIcon className={classNames} color='disabled' fontSize='small' />
        : <InfoIcon className={classNames} />}

    </HtmlTooltip>
  )
}

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9'
  }
}))(Tooltip)
