import { RootStore } from 'store/rootStore'
import { makeAutoObservable } from 'mobx'
import { AlertData } from 'ui/Alert/types'

export class AlertStore {
  private readonly rootStore: RootStore
  private _shouldBeClosed: boolean = true
  private _alertData: AlertData = {
    type: null
  }

  constructor (rootStore: RootStore) {
    this.rootStore = rootStore
    makeAutoObservable(this)
  }

  get shouldBeClosed (): boolean {
    return this._shouldBeClosed
  }

  get alertData (): AlertData {
    return this._alertData
  }

  showAlert (payload: AlertData): void {
    this._shouldBeClosed = false
    this._alertData = payload
  }

  closeAlert (): void {
    this._shouldBeClosed = true
  }

  closeAfter (ms: number): void {
    setTimeout(() => this.closeAlert(), ms)
  }

  showTemporaryAlert ({ alert, ms }: { alert: AlertData, ms?: number }): void {
    this.showAlert(alert)
    this.closeAfter(ms ?? 0)
  }
}
