import { useQuery } from 'react-query'
import { ORGANIZATION_TYPES } from 'constants/organizations'
import configureAssetLinks from '../../config/configureAssetLinks'
import { useMemo } from 'react'

export interface useTermsAndConditionsReturn {
  termsAndConditionsText: string | undefined
  isSuccess: boolean
}

const getTermsAndConditionsUrl = (accountType: string): string => {
  switch (accountType) {
    case ORGANIZATION_TYPES.BANKER:
    case ORGANIZATION_TYPES.SELL_SIDE_SALES:
      return configureAssetLinks.BANK_TERMS_AND_CONDITIONS_URL
    case ORGANIZATION_TYPES.INVESTOR:
    default:
      return configureAssetLinks.INVESTOR_TERMS_AND_CONDITIONS_URL
  }
}

export const useTermsAndConditions = (accountType: string | undefined): useTermsAndConditionsReturn => {
  const { data, isSuccess } = useQuery(['getTermsAndConditions', accountType], async () => {
    if (accountType === undefined || !Object.values(ORGANIZATION_TYPES).includes(accountType)) return undefined
    const url = getTermsAndConditionsUrl(accountType)
    const res = await fetch(url)
    return await res.text()
  }, { refetchOnWindowFocus: false })
  return useMemo(() => ({
    termsAndConditionsText: data,
    isSuccess
  }), [data, isSuccess])
}
