import { useMutation, UseMutationResult } from 'react-query'
import { useStores } from 'store/rootStore'
import { sendTelemetryData, TelemetryData } from 'services/telemetry'

export const useAnalyticTelemetry = (): UseMutationResult<void, unknown, Partial<TelemetryData>, void> => {
  const { userStore } = useStores()
  const userId = userStore.user?.profile?.user.id
  const timestamp = new Date().toUTCString()
  const userAgent = navigator.userAgent
  const referrer = encodeURIComponent(document.referrer)

  const constantData: TelemetryData = {
    timestamp: timestamp,
    userAgent: userAgent,
    referrer: referrer
  }
  if (userId) {
    constantData.userId = userId
  }
  return useMutation(async (additionalData: Partial<TelemetryData>): Promise<void> => {
    await sendTelemetryData({ ...constantData, ...additionalData })
  },
  {
    retry: 5
  })
}
