import { OFFERING_TYPE } from 'constants/index'
import { useNotificationSidebar } from 'components/Notifications/useNotificationsSidebar'
import { sendNotificationClickEvent } from 'config/configureGoogleAnalytics'
import React, { ReactNode } from 'react'
import { Link } from 'react-router-dom'
import { Notification } from 'services/notifications'
import { useStores } from 'store/rootStore'
import { observer } from 'mobx-react-lite'

function assertUnreachable (x: never): never {
  throw new Error('unknown notification type')
}

const Wrapper = ({ children }: { children: ReactNode }): JSX.Element => (
  <div
    data-qa='NotificationCenter'
    className='h-full overflow-y-auto flex-col shadow-md w-64 border-l-2 border-grey-600'
  >
    <div className='overflow-y-auto relative flex-1 text-gray-600'>
      {children}
    </div>
  </div>
)

const NoNotifications = (): JSX.Element => (
  <div className='mt-16 text-center text-lg'>No notifications</div>
)

const NotificationItemSkeleton = ({ i }: { i: number }): JSX.Element => {
  let width = 'w-48'
  if (i % 3 === 0) width = 'w-16'
  if (i % 3 === 0) width = 'w-32'
  return (
    <div key={i} className='p-3 border-b border-gray-300'>
      <div className='col-span-8'>
        <div className='h-3 bg-gray-200 rounded w-100' />
      </div>
      <div className='mt-1 col-span-4'>
        <div className={`h-3 bg-gray-200 rounded ${width}`} />
      </div>
    </div>
  )
}

const NotificationItem = observer(({
  id,
  link,
  type,
  publishedDate,
  publishingBankName,
  issuerName,
  message,
  offeringType,
  isCMAApprovalPostLaunch
}: Notification): JSX.Element => {
  const { userStore } = useStores()
  let typeMessage: string | undefined

  if (type === 'TERMSHEET_CLEARED_FOR_SAU') {
    if (userStore.user.userCountryCode !== 'SAU' ||
      (userStore.user.userCountryCode === 'SAU' && isCMAApprovalPostLaunch === false)) {
      return <div />
    }
  }

  switch (type) {
    case 'TERMSHEET_CLEARED_FOR_SAU':
      if (userStore.user.userCountryCode === 'SAU' && isCMAApprovalPostLaunch === true) {
        typeMessage = 'Cleared for Distribution into SAU'
      }
      break
    case 'TERMSHEET_UPDATED':
      typeMessage = 'Updated Term Sheet'
      break
    case 'TERMSHEET_CREATED':
      typeMessage = 'New Term Sheet added'
      break
    case 'BOOKBUILDING_MESSAGE_CREATED':
      typeMessage = 'Bookbuild message'
      break
    case 'BOOKBUILDING_MESSAGE_UPDATED':
      typeMessage = 'Bookbuild message updated'
      break
    default:
      assertUnreachable(type)
  }

  return (
    <Link
      key={id}
      to={link}
      onClick={() => sendNotificationClickEvent(`${publishingBankName}, ${issuerName}, ${type}`)}
      className='block p-3 border-b border-gray-300 hover:shadow-inner hover:text-gray-900'
      data-qa='NotificationCenter-Notification'
    >
      <div className='flex row justify-between'>
        <div className='font-bold mb-1 truncate'>{issuerName}</div>
        <div className='font-bold rounded-full border-current bg-current  p-0.5 px-2 text-center '>
          <span className='text-white '>{offeringType === OFFERING_TYPE.OTH ? 'Other' : offeringType}</span>
        </div>
      </div>
      <div className='truncate'>{typeMessage ?? ''}</div>
      <div className='truncate italic'>{message}</div>
      <div className='flex flex-wrap justify-between mt-1'>
        <div className='shrink-0'>{publishedDate}</div>
        <div className='grow truncate text-right'>{publishingBankName}</div>
      </div>
    </Link>
  )
})

export const NotificationsSidebar: React.FC = observer(() => {
  const {
    data,
    enableDueToFeatureFlag,
    showNotificationsSidebar,
    showSkeleton,
    showNoNotificationsMessage
  } = useNotificationSidebar()

  if (!enableDueToFeatureFlag) return null
  if (!showNotificationsSidebar) return null

  if (showSkeleton) {
    return (
      <Wrapper>
        {new Array(100).fill(true).map((x, i) => NotificationItemSkeleton({ i }))}
      </Wrapper>
    )
  }

  if (showNoNotificationsMessage) {
    return (
      <Wrapper>
        <NoNotifications />
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      {data?.map((item, index) =>
        <NotificationItem {...item} key={`notificationItem-${item.id}-${index}`} />
      )}
    </Wrapper>
  )
}
)
