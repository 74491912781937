import React, { ReactElement, useEffect, useState } from 'react'

export interface Props {
  children: ReactElement | boolean
  shouldBeClosed: boolean
  onClick?: () => void
}

export function CloseableToast (props: Props): JSX.Element|null {
  const { children, shouldBeClosed, onClick } = props
  const [isClosing, setIsClosing] = useState<boolean>(false)
  const [isClosed, setIsClosed] = useState<boolean>(false)

  useEffect(() => {
    if (!shouldBeClosed) {
      setIsClosing(false)
      setIsClosed(false)
      return
    }
    if (shouldBeClosed) {
      if (!isClosing) {
        setIsClosing(true)
      }
    }
  }, [shouldBeClosed, isClosing, isClosed, setIsClosed])

  const onTransitionEnd = (): void => {
    if (shouldBeClosed) {
      setIsClosed(true)
    }
  }
  const className = `${shouldBeClosed ? 'opacity-0 transition duration-1000' : 'opacity-100'}`
  if (isClosed) return null

  return (
    <div
      className={className}
      onTransitionEnd={onTransitionEnd}
      onClick={onClick}
    >{children}
    </div>
  )
}
