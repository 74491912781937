import { RootStore } from 'store/rootStore'
import { makeAutoObservable } from 'mobx'
import { RedirectionData, RedirectionPayload, RedirectionType } from 'store/types'

export class RedirectionStore {
  private readonly rootStore: RootStore
  private _redirectionData: RedirectionData = {
    type: null,
    payload: { bankId: '' }
  }

  constructor (rootStore: RootStore) {
    this.rootStore = rootStore
    makeAutoObservable(this)
  }

  get type (): RedirectionType {
    return this._redirectionData.type
  }

  set type (value: RedirectionType) {
    this._redirectionData.type = value
  }

  set payload (value: RedirectionPayload) {
    this._redirectionData.payload = value
  }

  get payload (): RedirectionPayload {
    return this._redirectionData.payload
  }
}
