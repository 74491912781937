class AssetLinks {
  LOCK_UP_EXPIRY_PDF_URL: string
  BANK_TERMS_AND_CONDITIONS_URL: string
  INVESTOR_TERMS_AND_CONDITIONS_URL: string

  constructor () {
    this.LOCK_UP_EXPIRY_PDF_URL = ''
    this.BANK_TERMS_AND_CONDITIONS_URL = ''
    this.INVESTOR_TERMS_AND_CONDITIONS_URL = ''
  }

  set (uri: string): void {
    this.LOCK_UP_EXPIRY_PDF_URL = `${uri}/files/lock-up-expiry.pdf`
    this.BANK_TERMS_AND_CONDITIONS_URL = `${uri}/termsandconditions/bank.md`
    this.INVESTOR_TERMS_AND_CONDITIONS_URL = `${uri}/termsandconditions/investor.md`
  }
}

export default new AssetLinks()
