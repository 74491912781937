import { equals } from 'ramda'
import { AccountType } from 'lib/types'
import React, { useCallback } from 'react'
import { ViewGridIcon, CogIcon, LinkIcon } from '@heroicons/react/outline'
import { UrlData } from 'components/InternalNavbar/types'
import configureAssetLinks from 'config/configureAssetLinks'
import { sendLockUpExpiryClickEvent } from 'config/configureGoogleAnalytics'

export const useGetLockUpHandler = (): () => void => {
  return useCallback(() => {
    window.open(configureAssetLinks.LOCK_UP_EXPIRY_PDF_URL, '_blank')
    return sendLockUpExpiryClickEvent()
  }, [])
}

export const useLinks = (isFeatureFlagEnabled, accountType): UrlData[] => {
  const lockUpClickHandler = useGetLockUpHandler()
  return [
    {
      icon: <ViewGridIcon className='mt-0.5 w-4 h-4' />,
      label: 'Dashboard',
      isAvailable: true,
      url: '/ecm/dashboard'
    },
    {
      icon: <CogIcon className='mt-0.5 w-4 h-4' />,
      label: 'ECM Admin',
      isAvailable: equals(accountType, AccountType.PRIMARY_PORTAL_AGENT),
      url: '/ecm/admin'
    },
    {
      icon: <LinkIcon className='mt-0.5 w-4 h-4' />,
      label: 'Lockups',
      isAvailable: equals(accountType, AccountType.INVESTOR),
      url: '/ecm/investor/lock-up-expiry',
      clickHandler: lockUpClickHandler
    },
    {
      icon: <CogIcon className='mt-0.5 w-4 h-4' />,
      label: 'Preferences',
      isAvailable: equals(accountType, AccountType.INVESTOR),
      url: '/ecm/investor/preferences'
    }
  ]
}
