import { handleLogout } from 'services/auth'
import { API as AmplifyAPI } from '@aws-amplify/api'
import { onError } from './../lib'

export const unauthenticatedApiName = 'app-unauthenticated'
export const authenticatedApiName = 'app-authenticated'

const getApiName = (authenticated: boolean): string => !authenticated ? unauthenticatedApiName : authenticatedApiName

export interface APIInput {
  authenticated?: boolean
  path: string
  init?: {}
}

interface TypedAPI<T> {
  get: (x: APIInput) => Promise<T>
  post: (x: APIInput) => Promise<T>
  put: (x: APIInput) => Promise<T>
  del: (x: APIInput) => Promise<T>
  patch: (x: APIInput) => Promise<T>
}

export function API<T> (): TypedAPI<T> {
  const get = async ({ authenticated = true, path, init = {} }): Promise<T> => {
    const apiName = getApiName(authenticated)
    return await AmplifyAPI
      .get(apiName, path, init)
      .catch(async (error) => {
        if (error?.response?.data?.error === 'Access Token has been revoked') {
          await handleLogout()
        }
        onError({ error: error, httpMethod: 'GET', apiName, path, init })
      })
  }

  const post = async ({ authenticated = true, path, init = {} }): Promise<T> => {
    const apiName = getApiName(authenticated)
    return await AmplifyAPI
      .post(apiName, path, init)
      .catch((error) => onError({ error: error, httpMethod: 'POST', apiName, path, init }))
  }

  const put = async ({ authenticated = true, path, init = {} }): Promise<T> => {
    const apiName = getApiName(authenticated)
    return await AmplifyAPI
      .put(apiName, path, init)
      .catch((error) => onError({ error: error, httpMethod: 'PUT', apiName, path, init }))
  }

  const patch = async ({ authenticated = true, path, init = {} }): Promise<T> => {
    const apiName = getApiName(authenticated)
    return await AmplifyAPI
      .patch(apiName, path, init)
      .catch((error) => onError({ error: error, httpMethod: 'PATCH', apiName, path, init }))
  }

  const del = async ({ authenticated = true, path, init = {} }): Promise<T> => {
    const apiName = getApiName(authenticated)
    return await AmplifyAPI
      .del(apiName, path, init)
      .catch((error) => onError({ error: error, httpMethod: 'DEL', apiName, path, init }))
  }

  return {
    get,
    post,
    put,
    del,
    patch
  }
}
