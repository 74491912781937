import { useQuery, UseQueryResult } from 'react-query'
import { getUserProfile, ProfileDTO } from 'services/users'
import { CACHED_QUERY_PROPS } from '../../constants/queries'

export function useGetUserProfile (): UseQueryResult<ProfileDTO> {
  return useQuery<ProfileDTO>('get-userprofile', getUserProfile, {
    ...CACHED_QUERY_PROPS,
    retryDelay: 5000
  })
}
