import ReactGA from 'react-ga4'

let TRACK_GA = false

export const configureGoogleAnalytics = (googleAnalyticsTrackingId: string): void => {
  if (googleAnalyticsTrackingId != null) {
    TRACK_GA = true
    ReactGA.initialize(googleAnalyticsTrackingId)
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search })
  }
}

export const reportPageView = (page): void => {
  if (TRACK_GA) {
    ReactGA.send({ hitType: 'pageview', page })
  }
}

export const reportModalView = (modalName): void => {
  if (TRACK_GA) {
    ReactGA.send({ hitType: 'modalview', modalName })
  }
}

export const sendRejectTAndCEvent = (): void => {
  if (TRACK_GA) {
    ReactGA.event({
      category: 'Terms and Conditions',
      action: 'Clicked rejected',
      label: 'Rejected'
    })
  }
}

export const sendVisitDealEvent = (dealName: string, bankName: string): void => {
  if (TRACK_GA) {
    ReactGA.event({
      category: 'Visit deal',
      action: `Show "${dealName}"`,
      label: `Show "${dealName}" by ${bankName}`
    })
  }
}
export const sendUserGuideClickEvent = (): void => {
  if (TRACK_GA) {
    ReactGA.event({
      category: 'User Guide',
      action: 'Click'
    })
  }
}
export const sendDropdownUsageEvent = (dealName: string, bankName: string): void => {
  if (TRACK_GA) {
    ReactGA.event({
      category: 'Change bank',
      action: `Changing to "${dealName}" by ${bankName}`
    })
  }
}

export const sendLockUpExpiryClickEvent = (): void => {
  if (TRACK_GA) {
    ReactGA.event({
      category: 'Lockup click',
      action: 'Clicked Lock Up Expiry link'
    })
  }
}

export const sendEmailTermsheetClickEvent = (): void => {
  if (TRACK_GA) {
    ReactGA.event({
      category: 'Email Term Sheet click',
      action: 'Clicked Email Term Sheet link'
    })
  }
}

export const sendNotificationClickEvent = (notificationMessage: string): void => {
  if (TRACK_GA) {
    ReactGA.event({
      category: 'Notification click',
      action: `Clicked notification "${notificationMessage}"`
    })
  }
}

export const setUserData = ({ accountRole, accountType, accountOrganizationName, userId, userEmail }): void => {
  if (TRACK_GA) {
    ReactGA.set({ userId })
    ReactGA.set({
      dimension1: userEmail,
      dimension2: accountRole,
      dimension3: accountType,
      dimension4: accountOrganizationName
    })
  }
}
