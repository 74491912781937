import { useIsSidebarEnabledByFF } from 'components/Notifications/commonHooks'
import { useUserPreferences } from 'lib'
import { useGetNotifications } from 'lib/queries/useGetNotifications'
import { useMemo } from 'react'
import { Notification } from 'services/notifications'
import { NOTIFICATION_CENTER_OPEN } from './constants'
import { useGetFilteredNotifications } from './useGetFilteredNotifications'

interface useNotificationSidebarReturn {
  data?: Notification[]
  enableDueToFeatureFlag: boolean
  showNotificationsSidebar: boolean
  showSkeleton: boolean
  showNoNotificationsMessage: boolean
}

export const useNotificationSidebar = (): useNotificationSidebarReturn => {
  const [open] = useUserPreferences<boolean>([NOTIFICATION_CENTER_OPEN])
  const enableDueToFeatureFlag = useIsSidebarEnabledByFF()
  const { isLoading, isError } = useGetNotifications()
  const { data } = useGetFilteredNotifications()

  return useMemo(() => ({
    data,
    enableDueToFeatureFlag,
    showNotificationsSidebar: open,
    showSkeleton: isLoading || isError || data === undefined,
    showNoNotificationsMessage: data?.length === 0
  }), [data, enableDueToFeatureFlag, isError, isLoading, open])
}
