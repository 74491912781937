import React from 'react'
import { IApiToUIViewMapping } from 'routes/TermsheetV2ViewPage/types'
import { always, assoc, nAry, identity as keepAsIs, ifElse, includes, pluck, equals, cond, T } from 'ramda'
import { ITermsheetV2Entity, OtherSyndicateBank } from 'store/types'
import {
  DistributionGroup,
  FIRST_STEP_NAME,
  SECOND_STEP_NAME,
  OfferingType
} from 'store/applicationGeneratorStore/const'
import {
  PRIMARY_OR_SECONDARY,
  TermsheetState,
  ABO_DEAL_SUB_TYPES,
  OTH_DEAL_SUB_TYPES,
  SPAC_DEAL_SUB_TYPES,
  BANK_ROLES
} from 'constants/index'
import { TooltipReusable } from 'components/TooltipReusable'
import { parseAndFormatDateV2, parseFromIsoAndFormatDateV2 } from 'utils/dateHelpers'
import { tsDateToDateTime } from 'store/applicationGeneratorStore/utils'
import Linkify from 'linkify-react'
import { linkifyDefaults } from 'config/linkifyDefaults'
import punycode from 'punycode/'
import { isReallyNotEmpty } from 'utils'

export const apiToUIViewMapping: IApiToUIViewMapping = {
  offeringType: {
    label: 'Offering Type',
    viewPageValueMapper: keepAsIs
  },
  dealSubType: {
    label: 'Deal Sub-type',
    uiFormField: `${FIRST_STEP_NAME}.dealSubType`,
    formPageValueMapper: keepAsIs,
    viewPageValueMapper: (value, termsheet) => {
      return cond([
        [equals(OfferingType.ABO), always(ABO_DEAL_SUB_TYPES[termsheet.dealSubType])],
        [equals(OfferingType.SPAC), always(SPAC_DEAL_SUB_TYPES[termsheet.dealSubType])],
        [equals(OfferingType.OTH), always(OTH_DEAL_SUB_TYPES[termsheet.dealSubType])],
        [T, always('')]
      ])(termsheet.offeringType)
    }
  },
  region: {
    label: 'Region',
    uiFormField: `${FIRST_STEP_NAME}.region`,
    formPageValueMapper: keepAsIs,
    viewPageValueMapper: keepAsIs
  },
  headerDisclaimer: {
    uiFormField: `${SECOND_STEP_NAME}.headerDisclaimerText`,
    formPageValueMapper: keepAsIs
  },
  state: {
    uiFormField: `${FIRST_STEP_NAME}.state`,
    formPageValueMapper: keepAsIs
  },
  publishingBankRole: {
    label: 'Role of the Publishing Bank',
    uiFormField: `${FIRST_STEP_NAME}.publishingBankRole`,
    formPageValueMapper: keepAsIs,
    viewPageValueMapper: keepAsIs
  },
  issuerName: {
    label: 'Issuer Name',
    uiFormField: `${FIRST_STEP_NAME}.issuerName`,
    formPageValueMapper: keepAsIs,
    viewPageValueMapper: keepAsIs
  },
  organizationId: {
    uiFormField: `${FIRST_STEP_NAME}.organizationId`,
    formPageValueMapper: keepAsIs
  },
  rumpOffering: {
    label: 'Rump offering',
    uiFormField: `${FIRST_STEP_NAME}.rumpOffering`,
    formPageValueMapper: keepAsIs,
    viewPageValueMapper: keepAsIs
  },
  issuerLegalName: {
    label: 'Issuer Legal Name',
    uiFormField: `${FIRST_STEP_NAME}.issuerLegalName`,
    formPageValueMapper: keepAsIs,
    viewPageValueMapper: keepAsIs
  },
  issuerDomicile: {
    label: 'Issuer Domicile',
    uiFormField: `${SECOND_STEP_NAME}.issuerDomicile`,
    formPageValueMapper: keepAsIs,
    viewPageValueMapper: keepAsIs
  },
  sector: {
    label: 'Sector',
    uiFormField: `${SECOND_STEP_NAME}.sector`,
    formPageValueMapper: keepAsIs,
    viewPageValueMapper: keepAsIs
  },
  businessDescription: {
    label: 'Business Description',
    uiFormField: `${SECOND_STEP_NAME}.businessDescription`,
    formPageValueMapper: keepAsIs,
    viewPageValueMapper: keepAsIs
  },
  'offeringType.securityType': {
    label: 'Security Type',
    uiFormField: `${SECOND_STEP_NAME}.securityType`,
    formPageValueMapper: keepAsIs,
    viewPageValueMapper: keepAsIs
  },
  'offeringType.otherSecurityType': {
    label: 'Other security type',
    uiFormField: `${SECOND_STEP_NAME}.otherSecurityType`,
    formPageValueMapper: keepAsIs,
    viewPageValueMapper: keepAsIs
  },
  primaryOrSecondary: {
    label: 'Primary/Secondary',
    uiFormField: `${FIRST_STEP_NAME}.primaryOrSecondary`,
    formPageValueMapper: keepAsIs,
    viewPageValueMapper: keepAsIs
  },
  publishingBankName: {
    uiFormField: `${FIRST_STEP_NAME}.publishingBankName`,
    formPageValueMapper: keepAsIs
  },
  useOfProceeds: {
    label: 'Use of proceeds',
    uiFormField: `${SECOND_STEP_NAME}.useOfProceeds`,
    formPageValueMapper: (value, termsheet) => {
      if (termsheet.primaryOrSecondary === PRIMARY_OR_SECONDARY.SECONDARY) {
        return ''
      } else {
        return value
      }
    },
    viewPageValueMapper: keepAsIs
  },
  'offeringType.offeringSharePriceRange': {
    label: 'Price range/guidance',
    uiFormField: `${SECOND_STEP_NAME}.offeringSharePriceRange`,
    formPageValueMapper: keepAsIs,
    viewPageValueMapper: (value, termsheet) => {
      const isPriced = termsheet.state === TermsheetState.PRICED
      const isWithdrawn = termsheet.state === TermsheetState.WITHDRAWN
      if (isPriced || isWithdrawn) {
        return ''
      }
      return value
    }
  },
  'offeringType.finalPriceLocalCcy': {
    label: 'Final Price',
    viewPageValueMapper: (value, termsheet) => {
      const isPriced = termsheet.state === TermsheetState.PRICED
      const isWithdrawn = termsheet.state === TermsheetState.WITHDRAWN
      const isRI = termsheet.offeringType === OfferingType.RI
      if ((isPriced && !isRI) || (isWithdrawn && !isRI)) {
        return value
      }
      return ''
    }
  },
  'offeringType.offeringImpliedMarketValue': {
    label: 'Implied market value',
    uiFormField: `${SECOND_STEP_NAME}.offeringImpliedMarketValue`,
    formPageValueMapper: keepAsIs,
    viewPageValueMapper: keepAsIs
  },
  'offeringType.freeFloat': {
    label: 'Free float',
    uiFormField: `${SECOND_STEP_NAME}.freeFloat`,
    formPageValueMapper: keepAsIs,
    viewPageValueMapper: keepAsIs
  },
  externalLinks: {
    uiFormField: `${SECOND_STEP_NAME}.externalLinks`,
    formPageValueMapper: keepAsIs
  },
  offeringCurrencyType: {
    label: 'Offering currency',
    uiFormField: `${SECOND_STEP_NAME}.offeringCurrencyType`,
    formPageValueMapper: keepAsIs,
    viewPageValueMapper: keepAsIs
  },
  'offeringType.sellingShareholders': {
    viewPageValueMapper: (
      value: Array<{
        shareholder: string
        lockup: string
        shareholderLegal: string
      }>
    ): JSX.Element => {
      const mappedValues = value.filter(item => isReallyNotEmpty(item.shareholder)).map((item, index) => {
        return (
          <div key={index} className='flex justify-between flex-col pb-4 pt-4'>
            <div className='flex flex-row justify-between pb-2'>
              <div className='font-semibold'>
                Selling shareholder {index + 1}
                <TooltipReusable
                  classNames='w-4 ml-1 mb-0.5'
                  helperText={item.shareholderLegal && item.shareholderLegal.length > 1
                    ? item.shareholderLegal
                    : 'No information available'}
                />
              </div>
              <div>{item.shareholder}</div>
            </div>
            <div className='flex flex-row justify-between'>
              <div className='font-semibold '>Lock up (days)</div>
              <div>{item.lockup}</div>
            </div>
          </div>
        )
      })
      return (
        (isReallyNotEmpty(mappedValues)
          ? (<div className='flex-col w-full divide-y'>{mappedValues}</div>)
          : null) as JSX.Element
      )
    },
    uiFormField: `${SECOND_STEP_NAME}.sellingShareholders`,
    formPageValueMapper: (sellingShareholders) =>
      sellingShareholders.map(({
        lockup,
        shareholder,
        shareholderLegal
      }) => ({
        shareholder,
        sellingShareholdersLegal: shareholderLegal,
        lockUpExpiryDays: lockup
      }))
  },
  'offeringType.offeringSizeNrSharesPrimary': {
    uiFormField: `${SECOND_STEP_NAME}.offeringSizeNrSharesPrimary`,
    formPageValueMapper: keepAsIs,
    viewPageValueMapper: (value, termsheet, isMobile) => {
      const label = termsheet?.primaryOrSecondary?.toUpperCase() === PRIMARY_OR_SECONDARY.PRIMARY_AND_SECONDARY
        ? 'Primary Offering'
        : 'Number of shares offered'
      if (termsheet.state === TermsheetState.PRICED || termsheet.primaryOrSecondary === PRIMARY_OR_SECONDARY.SECONDARY) {
        return ''
      }
      // Not a perfect solution at all.
      // The problem here is that viewPageValueMapper used to map label but not the value
      // In order to make the field looks like all the others, the HTML is being copy-pasted
      // That's why this code for mobile version (which supposed to be only in useColumnContent) is duplicated here as well.
      // We should consider creating a viewPageLabelMapper (or any other reasonable solution) instead.
      const labelStyles = isMobile ? 'flex w-1/2 my-1 flex-wrap flex-row justify-between font-semibold' : 'pr-2 min-w-fit font-semibold'
      const valueStyles = isMobile ? 'my-1' : 'text-right whitespace-pre-wrap'
      return (
        <div className='flex flex-row justify-between w-full'>
          <div className={labelStyles}>{label}</div>
          <div className={valueStyles}>{value}</div>
        </div>
      )
    }
  },
  'offeringType.offeringSizeNrSharesSecondary': {
    uiFormField: `${SECOND_STEP_NAME}.offeringSizeNrSharesSecondary`,
    formPageValueMapper: keepAsIs,
    viewPageValueMapper: (value, termsheet, isMobile) => {
      const label = termsheet?.primaryOrSecondary?.toUpperCase() === PRIMARY_OR_SECONDARY.PRIMARY_AND_SECONDARY
        ? 'Secondary Offering'
        : 'Number of shares offered'
      if (termsheet.state === TermsheetState.PRICED || termsheet.primaryOrSecondary === PRIMARY_OR_SECONDARY.PRIMARY) {
        return ''
      }
      const labelStyles = isMobile ? 'flex w-1/2 my-1 flex-wrap flex-row justify-between font-semibold' : 'pr-2 min-w-fit font-semibold'
      const valueStyles = isMobile ? 'my-1' : 'text-right whitespace-pre-wrap'
      return (
        <div className='flex flex-row justify-between w-full'>
          <div className={labelStyles}>{label}</div>
          <div className={valueStyles}>{value}</div>
        </div>
      )
    }
  },
  'offeringType.offeringSizeNrSharesPrimaryFinal': {
    label: 'Final shares in the deal - Primary',
    viewPageValueMapper: (value, termsheet) => {
      if ((termsheet.state !== TermsheetState.PRICED) && (termsheet.offeringType === OfferingType.EQL)) {
        return ''
      }
      if (termsheet.primaryOrSecondary === PRIMARY_OR_SECONDARY.SECONDARY) {
        return ''
      }
      return value
    },
    viewPageCustomPresentingLogic: (_, ts: ITermsheetV2Entity) => ts.primaryOrSecondary !== PRIMARY_OR_SECONDARY.SECONDARY

  },
  'offeringType.offeringSizeNrSharesSecondaryFinal': {
    label: 'Final shares in the deal - Secondary',
    viewPageValueMapper: (value, termsheet) => {
      if ((termsheet.state !== TermsheetState.PRICED) && (termsheet.offeringType === OfferingType.EQL)) {
        return ''
      }
      if (termsheet.primaryOrSecondary === PRIMARY_OR_SECONDARY.PRIMARY) {
        return ''
      }
      return value
    },
    viewPageCustomPresentingLogic: (_, ts: ITermsheetV2Entity) => ts.primaryOrSecondary !== PRIMARY_OR_SECONDARY.PRIMARY

  },
  'offeringType.couponFinal': {
    label: 'Coupon - Final',
    viewPageValueMapper: (value, termsheet) => {
      const isEQL = [OfferingType.EQL].includes(termsheet.offeringType)
      const isPriced = termsheet.state === TermsheetState.PRICED
      if (isEQL && isPriced) {
        return value
      }
      return ''
    }
  },
  'offeringType.yieldToMaturityFinal': {
    label: 'Yield to Maturity - Final',
    viewPageValueMapper: (value, termsheet) => {
      const isEQL = [OfferingType.EQL].includes(termsheet.offeringType)
      const isPriced = termsheet.state === TermsheetState.PRICED
      if (isEQL && isPriced) {
        return value
      }
      return ''
    }
  },
  'offeringType.conversionPremiumFinal': {
    label: 'Conversion Premium - Final',
    viewPageValueMapper: (value, termsheet) => {
      const isEQL = [OfferingType.EQL].includes(termsheet.offeringType)
      const isPriced = termsheet.state === TermsheetState.PRICED
      if (isEQL && isPriced) {
        return value
      }
      return ''
    }
  },
  'offeringType.issuePriceFinal': {
    label: 'Issue Price - Final',
    viewPageValueMapper: (value, termsheet) => {
      const isEQL = [OfferingType.EQL].includes(termsheet.offeringType)
      const isPriced = termsheet.state === TermsheetState.PRICED
      if (isEQL && isPriced) {
        return value
      }
      return ''
    }
  },
  'offeringType.percentCapitalIncrease': {
    label: '% Capital increase',
    uiFormField: `${SECOND_STEP_NAME}.percentCapitalIncrease`,
    formPageValueMapper: keepAsIs,
    viewPageValueMapper: keepAsIs
  },
  offeringSizeValue: {
    label: 'Offering size',
    uiFormField: `${SECOND_STEP_NAME}.offeringSizeValue`,
    formPageValueMapper: keepAsIs,
    viewPageValueMapper: (value, termsheet) => {
      const isPriced = termsheet.state === TermsheetState.PRICED
      const isWithdrawn = termsheet.state === TermsheetState.WITHDRAWN
      if (isPriced || isWithdrawn) {
        return ''
      }
      return value
    }
  },
  'offeringType.upsizeOption': {
    label: 'Upsize option',
    uiFormField: `${SECOND_STEP_NAME}.upsizeOption`,
    formPageValueMapper: keepAsIs,
    viewPageValueMapper: keepAsIs
  },
  'offeringType.employeeResPortion': {
    label: 'Employee reservation portion',
    uiFormField: `${SECOND_STEP_NAME}.employeeResPortion`,
    formPageValueMapper: keepAsIs,
    viewPageValueMapper: keepAsIs
  },
  finalOfferingSize: {
    label: 'Final Offering size',
    viewPageValueMapper: (value, termsheet) => {
      const isPriced = termsheet.state === TermsheetState.PRICED
      if (isPriced) {
        return value
      }
      return ''
    }
  },
  finalOfferingSizeLocalCcy: {
    label: 'Final Offering Size',
    viewPageValueMapper: (value, termsheet) => {
      const isWithdrawn = termsheet.state === TermsheetState.WITHDRAWN
      if (isWithdrawn) {
        return value
      }
      return ''
    }
  },
  greenshoe: {
    label: 'Greenshoe',
    uiFormField: `${SECOND_STEP_NAME}.greenshoe`,
    formPageValueMapper: keepAsIs,
    viewPageValueMapper: keepAsIs
  },
  allowedDistributionGroups: {
    label: 'USA Distribution',
    uiFormField: `${FIRST_STEP_NAME}.distributionUSA`,
    formPageValueMapper: ifElse(
      includes(DistributionGroup.QIBS),
      always('yes'),
      always('no')
    ),
    viewPageValueMapper: (value) => {
      if (value.includes(DistributionGroup.QIBS)) {
        return 'QIBS'
      }
      if (value?.length === 1 && value.includes(DistributionGroup.REG_S)) {
        return 'Reg S Only'
      }
      return value
    }
  },
  otherSellingRestrictions: {
    label: 'Other selling restrictions',
    uiFormField: `${SECOND_STEP_NAME}.otherSellingRestrictions`,
    formPageValueMapper: keepAsIs,
    viewPageValueMapper: keepAsIs
  },
  'offeringType.mifidTargetMarket': {
    label: 'MIFID II target market',
    uiFormField: `${SECOND_STEP_NAME}.mifidTargetMarket`,
    formPageValueMapper: keepAsIs,
    viewPageValueMapper: keepAsIs
  },
  'offeringType.discountToClose': {
    label: 'Discount to Close',
    uiFormField: `${SECOND_STEP_NAME}.discountToClose`,
    formPageValueMapper: keepAsIs,
    viewPageValueMapper: keepAsIs
  },
  lockUpExpiryDays: {
    label: 'Lockup',
    uiFormField: `${SECOND_STEP_NAME}.lockUpExpiryDays`,
    formPageValueMapper: keepAsIs,
    viewPageValueMapper: keepAsIs
  },
  listingExchange: {
    label: 'Listing exchange(s)',
    uiFormField: `${FIRST_STEP_NAME}.listingExchange`,
    formPageValueMapper: (value) =>
      (value ?? []).map(({
        displayName,
        marketIdentifierCode,
        countryAlpha3ISOCode
      }) => ({
        label: displayName,
        value: [marketIdentifierCode, countryAlpha3ISOCode].join(',')
      })),
    viewPageValueMapper: (value, termsheet) => {
      return pluck('displayName')(termsheet.listingExchange).join('\n')
    }
  },
  distributionIntoSaudiArabia: {
    uiFormField: `${FIRST_STEP_NAME}.distributionIntoSaudiArabia`,
    formPageValueMapper: ifElse(
      equals(true),
      always('yes'),
      always('no')
    )
  },
  'offeringType.isinNumber': {
    label: 'ISIN',
    uiFormField: `${SECOND_STEP_NAME}.isinNumber`,
    formPageValueMapper: keepAsIs,
    viewPageValueMapper: keepAsIs
  },
  stockTickerCode: {
    label: 'Ticker',
    uiFormField: `${SECOND_STEP_NAME}.stockTickerCode`,
    formPageValueMapper: keepAsIs,
    viewPageValueMapper: keepAsIs
  },
  'offeringType.minimumOrder': {
    label: 'Minimum order',
    uiFormField: `${SECOND_STEP_NAME}.minimumOrder`,
    formPageValueMapper: keepAsIs,
    viewPageValueMapper: keepAsIs
  },
  trancheStructure: {
    label: 'Tranche structure',
    uiFormField: `${SECOND_STEP_NAME}.trancheStructure`,
    formPageValueMapper: keepAsIs,
    viewPageValueMapper: keepAsIs
  },
  employeeResPortion: {
    label: 'Employee reservation portion',
    uiFormField: `${SECOND_STEP_NAME}.employeeResPortion`,
    formPageValueMapper: keepAsIs,
    viewPageValueMapper: keepAsIs
  },
  documentation: {
    label: 'Documentation',
    uiFormField: `${SECOND_STEP_NAME}.documentation`,
    formPageValueMapper: keepAsIs,
    viewPageValueMapper: keepAsIs
  },
  fxRate: {
    label: 'FX Rate',
    uiFormField: `${SECOND_STEP_NAME}.fxRate`,
    formPageValueMapper: keepAsIs,
    viewPageValueMapper: keepAsIs
  },
  'offeringType.boardLot': {
    label: 'Board Lot',
    uiFormField: `${SECOND_STEP_NAME}.boardLot`,
    formPageValueMapper: keepAsIs,
    viewPageValueMapper: keepAsIs
  },
  'offeringType.allocationCriteria': {
    label: 'Allocation criteria',
    uiFormField: `${SECOND_STEP_NAME}.allocationCriteria`,
    formPageValueMapper: keepAsIs,
    viewPageValueMapper: keepAsIs
  },
  'offeringType.bidLotSize': {
    label: 'Bid lot size',
    uiFormField: `${SECOND_STEP_NAME}.bidLotSize`,
    formPageValueMapper: keepAsIs,
    viewPageValueMapper: keepAsIs
  },
  investorAcknowledgement: {
    label: 'Investor acknowledgement',
    uiFormField: `${SECOND_STEP_NAME}.investorAcknowledgement`,
    formPageValueMapper: keepAsIs,
    viewPageValueMapper: keepAsIs
  },
  'offeringType.totalOfferQibs': {
    label: 'Total offer for QIBs',
    uiFormField: `${SECOND_STEP_NAME}.totalOfferQibs`,
    formPageValueMapper: keepAsIs,
    viewPageValueMapper: keepAsIs
  },
  'offeringType.commissions': {
    label: 'Commissions',
    uiFormField: `${SECOND_STEP_NAME}.commissions`,
    formPageValueMapper: keepAsIs,
    viewPageValueMapper: keepAsIs
  },
  'offeringType.anchorInvPortion': {
    label: 'Anchor investor portion',
    uiFormField: `${SECOND_STEP_NAME}.anchorInvPortion`,
    formPageValueMapper: keepAsIs,
    viewPageValueMapper: keepAsIs
  },
  'offeringType.netOfferQIBsExclAnch': {
    label: 'Net offer for QIBs excluding Anchor investors (assuming Anchor portion is fully subscribed)',
    uiFormField: `${SECOND_STEP_NAME}.netOfferQIBsExclAnch`,
    formPageValueMapper: keepAsIs,
    viewPageValueMapper: keepAsIs
  },
  'offeringType.nonInstPortion': {
    label: 'Non-institutional portion',
    uiFormField: `${SECOND_STEP_NAME}.nonInstPortion`,
    formPageValueMapper: keepAsIs,
    viewPageValueMapper: keepAsIs
  },
  'offeringType.retailPortion': {
    label: 'Retail portion',
    uiFormField: `${SECOND_STEP_NAME}.retailPortion`,
    formPageValueMapper: keepAsIs,
    viewPageValueMapper: keepAsIs
  },
  'offeringType.minAnchorBid': {
    label: 'Minimum Anchor bid (adjusted for bid lot)',
    uiFormField: `${SECOND_STEP_NAME}.minAnchorBid`,
    formPageValueMapper: keepAsIs,
    viewPageValueMapper: keepAsIs
  },
  'offeringType.maxAnchorBid': {
    label: 'Maximum Anchor bid (adjusted for bid lot)',
    uiFormField: `${SECOND_STEP_NAME}.maxAnchorBid`,
    formPageValueMapper: keepAsIs,
    viewPageValueMapper: keepAsIs
  },
  'offeringType.minBidSizeFPI': {
    label: 'Minimum bid size for FPI / Domestic MF / Ins / Other QIB',
    uiFormField: `${SECOND_STEP_NAME}.minBidSizeFPI`,
    formPageValueMapper: keepAsIs,
    viewPageValueMapper: keepAsIs
  },
  'offeringType.maxBidSizeFPI': {
    label: 'Maximum bid size for FPI / Domestic MF / Ins / Other QIB',
    uiFormField: `${SECOND_STEP_NAME}.maxBidSizeFPI`,
    formPageValueMapper: keepAsIs,
    viewPageValueMapper: keepAsIs
  },
  'offeringType.anchorInvPortionRegs': {
    label: 'Anchor Investor Portion Regs',
    uiFormField: `${SECOND_STEP_NAME}.anchorInvPortionRegs`,
    formPageValueMapper: keepAsIs,
    viewPageValueMapper: keepAsIs
  },
  'offeringType.catQibsApply': {
    label: 'Categories of QIBs who can apply (subject to applicable law & restrictions)',
    uiFormField: `${SECOND_STEP_NAME}.catQibsApply`,
    formPageValueMapper: keepAsIs,
    viewPageValueMapper: keepAsIs
  },
  'offeringType.subscriptionPrice': {
    label: 'Subscription Price',
    uiFormField: `${SECOND_STEP_NAME}.subscriptionPrice`,
    formPageValueMapper: keepAsIs,
    viewPageValueMapper: keepAsIs
  },
  'offeringType.subscriptionRatio': {
    label: 'Subscription Ratio',
    uiFormField: `${SECOND_STEP_NAME}.subscriptionRatio`,
    formPageValueMapper: keepAsIs,
    viewPageValueMapper: keepAsIs
  },
  'offeringType.TERP': {
    label: 'TERP',
    uiFormField: `${SECOND_STEP_NAME}.terp`,
    formPageValueMapper: keepAsIs,
    viewPageValueMapper: keepAsIs
  },
  'offeringType.commitments': {
    label: 'Commitments',
    uiFormField: `${SECOND_STEP_NAME}.commitments`,
    formPageValueMapper: keepAsIs,
    viewPageValueMapper: keepAsIs
  },
  'offeringType.issuedShareCapital': {
    label: 'Issued Share Capital',
    uiFormField: `${SECOND_STEP_NAME}.issuedShareCapital`,
    formPageValueMapper: keepAsIs,
    viewPageValueMapper: keepAsIs
  },
  'offeringType.marketCap': {
    label: 'Market Cap',
    uiFormField: `${SECOND_STEP_NAME}.marketCap`,
    formPageValueMapper: keepAsIs,
    viewPageValueMapper: keepAsIs
  },
  'offeringType.conditionsForCompletion': {
    label: 'Conditions for completion',
    uiFormField: `${SECOND_STEP_NAME}.conditionsForCompletion`,
    formPageValueMapper: keepAsIs,
    viewPageValueMapper: keepAsIs
  },
  'offeringType.investorAcknowledgement': {
    label: 'Investor acknowledgement',
    uiFormField: `${SECOND_STEP_NAME}.investorAcknowledgement`,
    formPageValueMapper: keepAsIs,
    viewPageValueMapper: keepAsIs
  },
  'offeringType.issuerRating': {
    label: 'Issuer rating',
    uiFormField: `${SECOND_STEP_NAME}.issuerRating`,
    formPageValueMapper: keepAsIs,
    viewPageValueMapper: keepAsIs
  },
  'offeringType.bondRating': {
    label: 'Bond rating',
    uiFormField: `${SECOND_STEP_NAME}.bondRating`,
    formPageValueMapper: keepAsIs,
    viewPageValueMapper: keepAsIs
  },
  'offeringType.securityTypeEQL': {
    label: 'Security type',
    uiFormField: `${SECOND_STEP_NAME}.securityTypeEQL`,
    formPageValueMapper: keepAsIs,
    viewPageValueMapper: keepAsIs
  },
  'offeringType.underlyingSecurity': {
    label: 'Underlying security',
    uiFormField: `${SECOND_STEP_NAME}.underlyingSecurity`,
    formPageValueMapper: keepAsIs,
    viewPageValueMapper: keepAsIs
  },
  'offeringType.issuePrice': {
    label: 'Issue Price',
    uiFormField: `${SECOND_STEP_NAME}.issuePrice`,
    formPageValueMapper: keepAsIs,
    viewPageValueMapper: (value, termsheet) => {
      const isEQL = [OfferingType.EQL].includes(termsheet.offeringType)
      const isPriced = termsheet.state === TermsheetState.PRICED
      if (isEQL && isPriced) {
        return ''
      }
      return value
    }
  },
  'offeringType.redemptionPrice': {
    label: 'Redemption Price',
    uiFormField: `${SECOND_STEP_NAME}.redemptionPrice`,
    formPageValueMapper: keepAsIs,
    viewPageValueMapper: keepAsIs
  },
  'offeringType.couponRange': {
    label: 'Coupon Range',
    uiFormField: `${SECOND_STEP_NAME}.couponRange`,
    formPageValueMapper: keepAsIs,
    viewPageValueMapper: (value, termsheet) => {
      const isEQL = [OfferingType.EQL].includes(termsheet.offeringType)
      const isPriced = termsheet.state === TermsheetState.PRICED
      if (isEQL && isPriced) {
        return ''
      }
      return value
    }
  },
  'offeringType.yieldToMaturity': {
    label: 'Yield to Maturity',
    uiFormField: `${SECOND_STEP_NAME}.yieldToMaturity`,
    formPageValueMapper: keepAsIs,
    viewPageValueMapper: (value, termsheet) => {
      const isEQL = [OfferingType.EQL].includes(termsheet.offeringType)
      const isPriced = termsheet.state === TermsheetState.PRICED
      if (isEQL && isPriced) {
        return ''
      }
      return value
    }
  },
  'offeringType.conversionPremium': {
    label: 'Conversion Premium',
    uiFormField: `${SECOND_STEP_NAME}.conversionPremium`,
    formPageValueMapper: keepAsIs,
    viewPageValueMapper: (value, termsheet) => {
      const isEQL = [OfferingType.EQL].includes(termsheet.offeringType)
      const isPriced = termsheet.state === TermsheetState.PRICED
      if (isEQL && isPriced) {
        return ''
      }
      return value
    }
  },
  'offeringType.referenceSharePrice': {
    label: 'Reference share price',
    uiFormField: `${SECOND_STEP_NAME}.referenceSharePrice`,
    formPageValueMapper: keepAsIs,
    viewPageValueMapper: keepAsIs
  },
  'offeringType.issuerCall': {
    label: 'Issuer Call',
    uiFormField: `${SECOND_STEP_NAME}.issuerCall`,
    formPageValueMapper: keepAsIs,
    viewPageValueMapper: keepAsIs
  },
  'offeringType.investorPut': {
    label: 'Investor Put',
    uiFormField: `${SECOND_STEP_NAME}.investorPut`,
    formPageValueMapper: keepAsIs,
    viewPageValueMapper: keepAsIs
  },
  'offeringType.antiDilutionProtection': {
    label: 'Anti-Dilution Protection',
    uiFormField: `${SECOND_STEP_NAME}.antiDilutionProtection`,
    formPageValueMapper: keepAsIs,
    viewPageValueMapper: keepAsIs
  },
  'offeringType.settlementUponConversion': {
    label: 'Settlement upon conversion',
    uiFormField: `${SECOND_STEP_NAME}.settlementUponConversion`,
    formPageValueMapper: keepAsIs,
    viewPageValueMapper: keepAsIs
  },
  'offeringType.changeOfControl': {
    label: 'Change of control',
    uiFormField: `${SECOND_STEP_NAME}.changeOfControl`,
    formPageValueMapper: keepAsIs,
    viewPageValueMapper: keepAsIs
  },
  'offeringType.dividendProtection': {
    label: 'Dividend protection',
    uiFormField: `${SECOND_STEP_NAME}.dividendProtection`,
    formPageValueMapper: keepAsIs,
    viewPageValueMapper: keepAsIs
  },
  'offeringType.securitiesOffered': {
    label: 'Securities Offered',
    uiFormField: `${SECOND_STEP_NAME}.securitiesOffered`,
    formPageValueMapper: keepAsIs,
    viewPageValueMapper: keepAsIs
  },
  'offeringType.exchangePremium': {
    label: 'Exchange Premium',
    uiFormField: `${SECOND_STEP_NAME}.exchangePremium`,
    formPageValueMapper: keepAsIs,
    viewPageValueMapper: keepAsIs
  },
  'offeringType.creditSpread': {
    label: 'Pricing assumptions - credit spread',
    uiFormField: `${SECOND_STEP_NAME}.creditSpread`,
    formPageValueMapper: keepAsIs,
    viewPageValueMapper: keepAsIs
  },
  'offeringType.costOfBorrow': {
    label: 'Pricing assumptions - cost of borrow',
    uiFormField: `${SECOND_STEP_NAME}.costOfBorrow`,
    formPageValueMapper: keepAsIs,
    viewPageValueMapper: keepAsIs
  },
  'offeringType.impliedVolatility': {
    label: 'Pricing assumptions - implied volatility',
    uiFormField: `${SECOND_STEP_NAME}.impliedVolatility`,
    formPageValueMapper: keepAsIs,
    viewPageValueMapper: keepAsIs
  },
  'offeringType.delta': {
    label: 'Pricing assumptions - delta',
    uiFormField: `${SECOND_STEP_NAME}.delta`,
    formPageValueMapper: keepAsIs,
    viewPageValueMapper: keepAsIs
  },
  'offeringType.isinOfTheBond': {
    label: 'ISIN of the Bond',
    uiFormField: `${SECOND_STEP_NAME}.isinOfTheBond`,
    formPageValueMapper: keepAsIs,
    viewPageValueMapper: keepAsIs
  },
  'offeringType.statusOfTheBonds': {
    label: 'Status of the Bonds',
    uiFormField: `${SECOND_STEP_NAME}.statusOfTheBonds`,
    formPageValueMapper: keepAsIs,
    viewPageValueMapper: keepAsIs
  },
  'offeringType.initialConversionPrice': {
    label: 'Initial Conversion Price',
    uiFormField: `${SECOND_STEP_NAME}.initialConversionPrice`,
    formPageValueMapper: keepAsIs,
    viewPageValueMapper: keepAsIs
  },
  'offeringType.conversionRights': {
    label: 'Conversion Rights',
    uiFormField: `${SECOND_STEP_NAME}.conversionRights`,
    formPageValueMapper: keepAsIs,
    viewPageValueMapper: keepAsIs
  },
  'offeringType.conversionPeriod': {
    label: 'Conversion Period',
    uiFormField: `${SECOND_STEP_NAME}.conversionPeriod`,
    formPageValueMapper: keepAsIs,
    viewPageValueMapper: keepAsIs
  },
  'offeringType.cleanUpCall': {
    label: 'Clean-up call',
    uiFormField: `${SECOND_STEP_NAME}.cleanUpCall`,
    formPageValueMapper: keepAsIs,
    viewPageValueMapper: keepAsIs
  },
  'offeringType.taxCallGrossUp': {
    label: 'Tax call / Gross-up',
    uiFormField: `${SECOND_STEP_NAME}.taxCallGrossUp`,
    formPageValueMapper: keepAsIs,
    viewPageValueMapper: keepAsIs
  },
  'offeringType.crossDefaultAcceleration': {
    label: 'Cross Default/Acceleration',
    uiFormField: `${SECOND_STEP_NAME}.crossDefaultAcceleration`,
    formPageValueMapper: keepAsIs,
    viewPageValueMapper: keepAsIs
  },
  'offeringType.negativePledge': {
    label: 'Negative Pledge',
    uiFormField: `${SECOND_STEP_NAME}.negativePledge`,
    formPageValueMapper: keepAsIs,
    viewPageValueMapper: keepAsIs
  },
  'offeringType.settlementUponRedemption': {
    label: 'Settlement upon Redemption',
    uiFormField: `${SECOND_STEP_NAME}.settlementUponRedemption`,
    formPageValueMapper: keepAsIs,
    viewPageValueMapper: keepAsIs
  },
  'offeringType.guarantor': {
    label: 'Guarantor',
    uiFormField: `${SECOND_STEP_NAME}.guarantor`,
    formPageValueMapper: keepAsIs,
    viewPageValueMapper: keepAsIs
  },
  'offeringType.governingLaw': {
    label: 'Governing Law',
    uiFormField: `${SECOND_STEP_NAME}.governingLaw`,
    formPageValueMapper: keepAsIs,
    viewPageValueMapper: keepAsIs
  },
  'offeringType.offeringMaturity': {
    label: 'Maturity',
    uiFormField: `${SECOND_STEP_NAME}.offeringMaturity`,
    formPageValueMapper: keepAsIs,
    viewPageValueMapper: keepAsIs
  },
  'offeringType.denomination': {
    label: 'Denomination',
    uiFormField: `${SECOND_STEP_NAME}.denomination`,
    formPageValueMapper: keepAsIs,
    viewPageValueMapper: keepAsIs
  },
  'offeringType.exchangeProperty': {
    label: 'Exchange Property',
    uiFormField: `${SECOND_STEP_NAME}.exchangeProperty`,
    formPageValueMapper: keepAsIs,
    viewPageValueMapper: keepAsIs
  },
  'offeringType.lockUpExpiryDays': {
    label: 'Lockup',
    viewPageValueMapper: keepAsIs
  },
  'offeringType.formOfTheBonds': {
    label: 'Form of the Bonds',
    uiFormField: `${SECOND_STEP_NAME}.formOfTheBonds`,
    formPageValueMapper: keepAsIs,
    viewPageValueMapper: keepAsIs
  },
  'offeringType.eventOfDefault': {
    label: 'Event of Default',
    uiFormField: `${SECOND_STEP_NAME}.eventOfDefault`,
    formPageValueMapper: keepAsIs,
    viewPageValueMapper: keepAsIs
  },
  'offeringType.fairMarketCallPut': {
    label: 'Fair Market Call & Put',
    uiFormField: `${SECOND_STEP_NAME}.fairMarketCallPut`,
    formPageValueMapper: keepAsIs,
    viewPageValueMapper: keepAsIs
  },
  'offeringType.unitComposition': {
    label: 'Unit Composition',
    uiFormField: `${SECOND_STEP_NAME}.unitComposition`,
    formPageValueMapper: keepAsIs,
    viewPageValueMapper: keepAsIs
  },
  'offeringType.keyIndividualsFounders': {
    label: 'Key Individuals / Founders',
    uiFormField: `${SECOND_STEP_NAME}.keyIndividualsFounders`,
    formPageValueMapper: keepAsIs,
    viewPageValueMapper: keepAsIs
  },
  'offeringType.spacLifetime': {
    label: 'SPAC Life / timeframe',
    uiFormField: `${SECOND_STEP_NAME}.spacLifetime`,
    formPageValueMapper: keepAsIs,
    viewPageValueMapper: keepAsIs
  },
  'offeringType.foundersPromoteSponsorPromote': {
    label: '\'Founders\' Promote/ Sponsor promote',
    uiFormField: `${SECOND_STEP_NAME}.foundersPromoteSponsorPromote`,
    formPageValueMapper: keepAsIs,
    viewPageValueMapper: keepAsIs
  },
  'offeringType.riskCapital': {
    label: 'Risk Capital',
    uiFormField: `${SECOND_STEP_NAME}.riskCapital`,
    formPageValueMapper: keepAsIs,
    viewPageValueMapper: keepAsIs
  },
  'offeringType.warrantTerms': {
    label: 'Warrant terms',
    uiFormField: `${SECOND_STEP_NAME}.warrantTerms`,
    formPageValueMapper: keepAsIs,
    viewPageValueMapper: keepAsIs
  },
  'offeringType.amountHeldInTrust': {
    label: 'Amount held in trust',
    uiFormField: `${SECOND_STEP_NAME}.amountHeldInTrust`,
    formPageValueMapper: keepAsIs,
    viewPageValueMapper: keepAsIs
  },
  'offeringType.interestEarnedOnCash': {
    label: 'Interest earned on cash in trust',
    uiFormField: `${SECOND_STEP_NAME}.interestEarnedOnCash`,
    formPageValueMapper: keepAsIs,
    viewPageValueMapper: keepAsIs
  },
  'offeringType.shareLiquidationValue': {
    label: 'Share Liquidation Value',
    uiFormField: `${SECOND_STEP_NAME}.shareLiquidationValue`,
    formPageValueMapper: keepAsIs,
    viewPageValueMapper: keepAsIs
  },
  'offeringType.focusTargetAcquisitionMarket': {
    label: 'Focus/Target Acquisition Market',
    uiFormField: `${SECOND_STEP_NAME}.focusTargetAcquisitionMarket`,
    formPageValueMapper: keepAsIs,
    viewPageValueMapper: keepAsIs
  },
  'offeringType.acquisitionApproval': {
    label: 'Acquisition Approval',
    uiFormField: `${SECOND_STEP_NAME}.acquisitionApproval`,
    formPageValueMapper: keepAsIs,
    viewPageValueMapper: keepAsIs
  },
  'offeringType.overallotment': {
    label: 'Overallotment',
    uiFormField: `${SECOND_STEP_NAME}.overallotment`,
    formPageValueMapper: keepAsIs,
    viewPageValueMapper: keepAsIs
  },
  'offeringType.incorporation': {
    label: 'Incorporation',
    uiFormField: `${SECOND_STEP_NAME}.incorporation`,
    formPageValueMapper: keepAsIs,
    viewPageValueMapper: keepAsIs
  },
  'offeringType.industry': {
    label: 'Industry',
    uiFormField: `${SECOND_STEP_NAME}.industry`,
    formPageValueMapper: keepAsIs,
    viewPageValueMapper: keepAsIs
  },
  'offeringType.cusipNumber': {
    label: 'CUSIP',
    uiFormField: `${SECOND_STEP_NAME}.industry`,
    formPageValueMapper: keepAsIs,
    viewPageValueMapper: keepAsIs
  },
  'offeringType.groupCallDateTime': {
    label: 'Group Call date/time',
    uiFormField: `${SECOND_STEP_NAME}.groupCallDateTime`,
    formPageValueMapper: keepAsIs,
    viewPageValueMapper: keepAsIs
  },
  'offeringType.groupCallNr': {
    label: 'Group call nr',
    uiFormField: `${SECOND_STEP_NAME}.groupCallNumber`,
    formPageValueMapper: keepAsIs,
    viewPageValueMapper: keepAsIs
  },
  'offeringType.groupCallId': {
    label: 'Group call ID',
    uiFormField: `${SECOND_STEP_NAME}.groupCallId`,
    formPageValueMapper: keepAsIs,
    viewPageValueMapper: keepAsIs
  },
  'offeringType.groupCallEntryCode': {
    label: 'Group call entry code',
    uiFormField: `${SECOND_STEP_NAME}.groupCallEntryCode`,
    formPageValueMapper: keepAsIs,
    viewPageValueMapper: keepAsIs
  },
  'offeringType.groupCallPresentation': {
    label: 'Group call presentation',
    uiFormField: `${SECOND_STEP_NAME}.groupCallPresentation`,
    formPageValueMapper: keepAsIs,
    viewPageValueMapper: keepAsIs
  },
  'offeringType.otherOfferingType': {
    label: 'Other offering type',
    viewPageValueMapper: keepAsIs,
    uiFormField: `${SECOND_STEP_NAME}.otherOfferingType`,
    formPageValueMapper: keepAsIs
  },

  'offeringType.fxRate': {
    label: 'FX Rate',
    viewPageValueMapper: keepAsIs,
    uiFormField: `${SECOND_STEP_NAME}.fxRate`,
    formPageValueMapper: keepAsIs
  },

  'offeringType.discountRange': {
    label: 'Discount Range',
    viewPageValueMapper: keepAsIs,
    uiFormField: `${SECOND_STEP_NAME}.discountRange`,
    formPageValueMapper: keepAsIs
  },
  'offeringType.previousClose': {
    label: 'Previous close ',
    viewPageValueMapper: keepAsIs,
    uiFormField: `${SECOND_STEP_NAME}.previousClose`,
    formPageValueMapper: keepAsIs
  },
  'offeringType.discountToTERP': {
    label: 'Discount to TERP',
    viewPageValueMapper: keepAsIs,
    uiFormField: `${SECOND_STEP_NAME}.discountToTERP`,
    formPageValueMapper: keepAsIs
  },

  'offeringType.greenshoe': {
    label: 'Greenshoe',
    viewPageValueMapper: keepAsIs,
    uiFormField: `${SECOND_STEP_NAME}.greenshoe`,
    formPageValueMapper: keepAsIs
  },
  'offeringType.trancheStructure': {
    label: 'Tranche structure',
    viewPageValueMapper: keepAsIs,
    uiFormField: `${SECOND_STEP_NAME}.trancheStructure`,
    formPageValueMapper: keepAsIs
  },
  'offeringType.rumpOffering': {
    uiFormField: `${SECOND_STEP_NAME}.rumpOffering`,
    formPageValueMapper: keepAsIs
  },
  totalNumberOfSharesOffered: {
    label: 'Total number of shares offered',
    viewPageValueMapper: (termsheet) => {
      if (termsheet.offeringType !== OfferingType.OTH) {
        return ''
      }
      const {
        offeringSizeNrSharesSecondary,
        offeringSizeNrSharesPrimary
      } = termsheet[OfferingType.OTH.toLowerCase()]
      if (!offeringSizeNrSharesSecondary || !offeringSizeNrSharesPrimary) {
        return ''
      }
      return (parseInt(offeringSizeNrSharesSecondary) + parseInt(offeringSizeNrSharesPrimary)).toString()
    }
  },
  additionalInformation: {
    label: 'Additional info',
    uiFormField: `${SECOND_STEP_NAME}.additionalInfo`,
    formPageValueMapper: keepAsIs,
    viewPageValueMapper: (value?: string) => {
      return (
        <Linkify
          options={linkifyDefaults}
          className='break-words whitespace-pre'
          {...componentDecorator}
        >{value}
        </Linkify>
      )
    }
  },
  'offeringType.domesticSplit': {
    label: 'Domestic Inst/Intl Split',
    uiFormField: `${SECOND_STEP_NAME}.domesticSplit`,
    formPageValueMapper: keepAsIs,
    viewPageValueMapper: keepAsIs
  },
  'offeringType.demandAllocationPolicy': {
    label: 'Demand/Allocation Policy',
    uiFormField: `${SECOND_STEP_NAME}.demandAllocationPolicy`,
    formPageValueMapper: keepAsIs,
    viewPageValueMapper: keepAsIs
  }
}

export const componentDecorator = (href, text, key): JSX.Element => (
  <a href={href} key={key}>
    {punycode.toASCII(text)}
  </a>
)
export const bottomApiToUIViewMapping: IApiToUIViewMapping = {
  otherSyndicateBanks: {
    label: 'Underwriting Syndicate',
    uiFormField: `${SECOND_STEP_NAME}.otherSyndicateBanks`,
    formPageValueMapper: (value?: OtherSyndicateBank[]) => (value ?? []).map(({
      name,
      role,
      id
    }) => ({
      id,
      bankName: name,
      bankRole: role
    })),
    viewPageValueMapper: (value: OtherSyndicateBank[], termsheet: ITermsheetV2Entity) => {
      const mappedValue = [...(value ?? [])].reduce<Record<string, string>>((acc, {
        role,
        name
      }) =>
        assoc(role, `${acc[role] ?? ''}${name}, `, acc),
      {
        ...termsheet.publishingBankRole
          ? { [termsheet.publishingBankRole]: ', ' }
          : {}
      }
      )
      return (
        <>
          {BANK_ROLES.map((key, index) => (
            mappedValue[key]
              ? (
                <div key={`otherSyndicateBanks-${index}`} className='flex items-center'>
                  <span>{key}:</span>
                  <span className='pl-1'>{key === termsheet.publishingBankRole &&
                    <span className='font-semibold'>{termsheet.publishingBankName}</span>}
                    {mappedValue[key].trim().slice(0, -1)}
                  </span>
                </div>
                )
              : null
          ))}
        </>
      )
    }
  },
  footerDisclaimer: {
    label: 'Disclaimer',
    uiFormField: `${SECOND_STEP_NAME}.footerDisclaimer`,
    formPageValueMapper: keepAsIs,
    viewPageValueMapper: (value?: string) => {
      return (
        <div className='break-words whitespace-pre-wrap'>
          <Linkify
            options={linkifyDefaults}
            {...componentDecorator}
          >{value}
          </Linkify>
        </div>
      )
    }
  }
}

export const timetableApiToUIViewMapping: IApiToUIViewMapping = {
  dealAnnouncementDate: {
    label: 'Deal Announcement',
    uiFormField: `${SECOND_STEP_NAME}.dealAnnouncementDate`,
    formPageValueMapper: tsDateToDateTime,
    viewPageValueMapper: nAry(1, parseAndFormatDateV2)
  },
  'offeringType.researchPublicationDate': {
    label: 'Research publication',
    uiFormField: `${SECOND_STEP_NAME}.researchPublicationDate`,
    formPageValueMapper: tsDateToDateTime,
    viewPageValueMapper: nAry(1, parseAndFormatDateV2)
  },
  'offeringType.startOfBookbuildingDate': {
    label: 'Start of bookbuilding',
    uiFormField: `${SECOND_STEP_NAME}.startOfBookbuildingDate`,
    formPageValueMapper: tsDateToDateTime,
    viewPageValueMapper: nAry(1, parseAndFormatDateV2)
  },
  'offeringType.closeOfRetailOfferingDate': {
    label: 'Close of Retail offering',
    uiFormField: `${SECOND_STEP_NAME}.closeOfRetailOfferingDate`,
    formPageValueMapper: tsDateToDateTime,
    viewPageValueMapper: nAry(1, parseAndFormatDateV2)
  },
  'offeringType.closeOfBookbuildingDate': {
    label: 'Expected books close date',
    uiFormField: `${SECOND_STEP_NAME}.closeOfBookbuildingDate`,
    formPageValueMapper: tsDateToDateTime,
    viewPageValueMapper: nAry(1, parseAndFormatDateV2)
  },
  expectedPricingDate: {
    label: 'Expected pricing date',
    uiFormField: `${SECOND_STEP_NAME}.expectedPricingDate`,
    formPageValueMapper: tsDateToDateTime,
    viewPageValueMapper: nAry(1, parseAndFormatDateV2),
    viewPageCustomPresentingLogic: (_, ts: ITermsheetV2Entity) => ts.state !== TermsheetState.PRICED
  },
  pricingDate: {
    label: 'Pricing date',
    uiFormField: `${SECOND_STEP_NAME}.pricingDate`,
    viewPageValueMapper: nAry(1, parseFromIsoAndFormatDateV2)
  },
  'offeringType.allocationsReleasedDate': {
    label: 'Allocations Released Date',
    uiFormField: `${SECOND_STEP_NAME}.allocationsReleasedDate`,
    formPageValueMapper: tsDateToDateTime,
    viewPageValueMapper: nAry(1, parseAndFormatDateV2)
  },
  'offeringType.startOfTradingDate': {
    label: 'Start of trading',
    uiFormField: `${SECOND_STEP_NAME}.startOfTradingDate`,
    formPageValueMapper: tsDateToDateTime,
    viewPageValueMapper: nAry(1, parseAndFormatDateV2)
  },
  'offeringType.settlementDate': {
    label: 'Settlement',
    uiFormField: `${SECOND_STEP_NAME}.settlementDate`,
    formPageValueMapper: tsDateToDateTime,
    viewPageValueMapper: nAry(1, parseAndFormatDateV2),
    viewPageCustomPresentingLogic: (_, ts: ITermsheetV2Entity) => ts.state !== TermsheetState.PRICED
  },
  'offeringType.finalSettlementDate': {
    label: 'Final Settlement',
    uiFormField: `${SECOND_STEP_NAME}.finalSettlementDate`,
    formPageValueMapper: tsDateToDateTime,
    viewPageValueMapper: nAry(1, parseAndFormatDateV2)
  },
  'offeringType.lastDayOfStabTrading': {
    label: 'Last Day of Stab Trading',
    uiFormField: `${SECOND_STEP_NAME}.lastDayOfStabTrading`,
    formPageValueMapper: tsDateToDateTime,
    viewPageValueMapper: nAry(1, parseAndFormatDateV2)
  },
  'offeringType.roadshowScheduleTiming': {
    label: 'Roadshow Schedule/Timing',
    uiFormField: `${SECOND_STEP_NAME}.roadshowSchedule`,
    formPageValueMapper: tsDateToDateTime,
    viewPageValueMapper: nAry(1, parseAndFormatDateV2)
  },
  'offeringType.recordDate': {
    label: 'Record Date',
    uiFormField: `${SECOND_STEP_NAME}.recordDate`,
    formPageValueMapper: tsDateToDateTime,
    viewPageValueMapper: nAry(1, parseAndFormatDateV2)
  },
  'offeringType.lastDayOfCRTradingDate': {
    label: 'Last day of cum-rights trading',
    uiFormField: `${SECOND_STEP_NAME}.lastDayOfCumRightsTrading`,
    formPageValueMapper: tsDateToDateTime,
    viewPageValueMapper: nAry(1, parseAndFormatDateV2)
  },
  'offeringType.exDate': {
    label: 'Ex-rights date',
    uiFormField: `${SECOND_STEP_NAME}.exRightsDate`,
    formPageValueMapper: tsDateToDateTime,
    viewPageValueMapper: nAry(1, parseAndFormatDateV2)
  },
  'offeringType.startOfRightsTradingDate': {
    label: 'Start of rights trading',
    uiFormField: `${SECOND_STEP_NAME}.startOfRightsTrading`,
    formPageValueMapper: tsDateToDateTime,
    viewPageValueMapper: nAry(1, parseAndFormatDateV2)
  },
  'offeringType.startOfSubscriptionPeriodDate': {
    label: 'Start of subscription period',
    uiFormField: `${SECOND_STEP_NAME}.startOfSubsPeriod`,
    formPageValueMapper: tsDateToDateTime,
    viewPageValueMapper: nAry(1, parseAndFormatDateV2)
  },
  'offeringType.endOfRightsTradingDate': {
    label: 'End of rights trading',
    uiFormField: `${SECOND_STEP_NAME}.endOfRightsTrading`,
    formPageValueMapper: tsDateToDateTime,
    viewPageValueMapper: nAry(1, parseAndFormatDateV2)
  },
  'offeringType.endOfSubscriptionPeriodDate': {
    label: 'End of subscription period',
    uiFormField: `${SECOND_STEP_NAME}.endOfSubsPeriod`,
    formPageValueMapper: tsDateToDateTime,
    viewPageValueMapper: nAry(1, parseAndFormatDateV2)
  },
  'offeringType.startOfTradingNewSharesDate': {
    label: 'Start of trading new shares',
    uiFormField: `${SECOND_STEP_NAME}.startOfTradingNewShares`,
    formPageValueMapper: tsDateToDateTime,
    viewPageValueMapper: nAry(1, parseAndFormatDateV2)
  },
  'offeringType.announcementOfResultsDate': {
    label: 'Announcement of results',
    uiFormField: `${SECOND_STEP_NAME}.announcementOfResults`,
    formPageValueMapper: tsDateToDateTime,
    viewPageValueMapper: nAry(1, parseAndFormatDateV2)
  },
  'offeringType.settlementOfNewSharesDate': {
    label: 'Settlement of new shares',
    uiFormField: `${SECOND_STEP_NAME}.settlementOfNewShares`,
    formPageValueMapper: tsDateToDateTime,
    viewPageValueMapper: nAry(1, parseAndFormatDateV2)
  },
  'offeringType.egmDate': {
    label: 'EGM',
    uiFormField: `${SECOND_STEP_NAME}.egm`,
    formPageValueMapper: tsDateToDateTime,
    viewPageValueMapper: nAry(1, parseAndFormatDateV2)
  },
  'offeringType.prospectusPublicationDate': {
    label: 'Publication of the Prospectus',
    uiFormField: `${SECOND_STEP_NAME}.publicationOfProspectus`,
    formPageValueMapper: tsDateToDateTime,
    viewPageValueMapper: nAry(1, parseAndFormatDateV2)
  },
  'offeringType.rumpPlacementDate': {
    label: 'Rump placement',
    uiFormField: `${SECOND_STEP_NAME}.rumpPlacement`,
    formPageValueMapper: tsDateToDateTime,
    viewPageValueMapper: nAry(1, parseAndFormatDateV2)
  },
  'offeringType.maturityDate': {
    label: 'Maturity date',
    uiFormField: `${SECOND_STEP_NAME}.maturityDate`,
    formPageValueMapper: tsDateToDateTime,
    viewPageValueMapper: nAry(1, parseAndFormatDateV2)
  }
}
