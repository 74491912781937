import React from 'react'
import { BellIcon as BellIconSolid } from '@heroicons/react/solid'
import { BellIcon as BellIconOutline } from '@heroicons/react/outline'
import { useNotificationIcon } from 'components/Notifications/useNotificationsIcon'
import { observer } from 'mobx-react-lite'
import { useFeatureFlags } from 'lib/useFeatureFlags'
import { FEATURES } from 'constants/features'
import { SecondaryNavigationItem } from '@ppui/ui-components'
import { useNotificationSidebar } from 'components/Notifications/useNotificationsSidebar'
import { useIsSpecificPathWatch } from 'lib/hooks'

export const NotificationsIcon: React.FC = observer(() => {
  const { isFeatureFlagEnabled } = useFeatureFlags()
  const showInternalNavbar: boolean = isFeatureFlagEnabled(FEATURES.showInternalNavbar)
  const {
    enableDueToFeatureFlag,
    showNewNotificationsIndicator,
    onClick
  } = useNotificationIcon()
  const isAddNewDeal = useIsSpecificPathWatch([{
    path: '/ecm/deals/add-new',
    compare: 'strict'
  }])

  const {
    showNotificationsSidebar
  } = useNotificationSidebar()

  if (!enableDueToFeatureFlag) return null
  if (!showInternalNavbar) {
    return (
      <div className='relative'>
        {(showNewNotificationsIndicator && (
          <span className='absolute top-0 left-5 inline-block w-2 h-2 rounded-xl mr-2 bg-yellow-500' />
        ))}
        <BellIconSolid
          data-qa='NotificationCenter-Toggle'
          className='h-8 cursor-pointer shrink-0 self-start' onClick={onClick}
        />
      </div>
    )
  }
  if (isAddNewDeal) {
    return null
  }

  return (
    <SecondaryNavigationItem
      styles='p-1.5'
      clickHandler={onClick}
      icon={
        <div className='relative'>
          {(showNewNotificationsIndicator && (
            <span className='absolute top-0 left-5 inline-block w-2 h-2 rounded-xl mr-2 bg-yellow-500' />
          ))}
          <BellIconOutline className='w-6 h-6' />
        </div>
      }
      isActive={showNotificationsSidebar}
    />
  )
})
