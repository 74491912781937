import { action, makeObservable, observable } from 'mobx'
import { RootStore } from 'store/rootStore'
import { BookbuildMessage } from 'services/bookbuild-messages-v2'
import { findIndex, update, propEq, filter, complement, isNil, reverse, sortBy, prop, insert } from 'ramda'
import { IBbmItemRemove } from 'routes/TermsheetV2ViewPage/components/Bookbuild/types'
import { BookBuildModalType } from 'store/types'

export type SubmitHandler = (
  shouldSendFeedNotifications?: boolean,
  shouldSendEmailNotifications?: boolean
) => Promise<void>

export class BookbuildingMessagesStore {
  rootStore: RootStore
  isModalOpen: boolean = false
  selectedMessage?: BookbuildMessage = undefined
  submitHandler?: SubmitHandler = undefined
  bookbuildingMessages?: BookbuildMessage[] = undefined
  modalType: BookBuildModalType = BookBuildModalType.ADD

  constructor (rootStore: RootStore) {
    this.rootStore = rootStore
    makeObservable(this, {
      modalType: observable,
      isModalOpen: observable,
      selectedMessage: observable,
      submitHandler: observable,
      bookbuildingMessages: observable,
      setModalType: action.bound,
      setIsModalOpen: action.bound,
      setSelectedMessage: action.bound,
      setSubmitHandler: action.bound,
      setBookbuildingMessages: action.bound,
      upsertBookbuildingMessage: action.bound,
      deleteBookbuildingMessage: action.bound
    })
  }

  setModalType (modalType: BookBuildModalType): void {
    this.modalType = modalType
  }

  setIsModalOpen (isModalOpen: boolean): void {
    this.isModalOpen = isModalOpen
  }

  setSelectedMessage (selectedMessage: BookbuildMessage): void {
    this.selectedMessage = selectedMessage
  }

  setSubmitHandler (submitHandler?: SubmitHandler): void {
    this.submitHandler = submitHandler
  }

  setBookbuildingMessages (bookbuildingMessages?: BookbuildMessage[]): void {
    this.bookbuildingMessages = bookbuildingMessages ? reverse(sortBy(prop('timeStamp'))((bookbuildingMessages))) : bookbuildingMessages
  }

  upsertBookbuildingMessage (updatedBookbuildingMessage: BookbuildMessage): void {
    if (isNil(this.bookbuildingMessages)) {
      this.bookbuildingMessages = [updatedBookbuildingMessage]
      return
    }
    const sortedBBMsByTimeStamp = reverse(sortBy(prop('timeStamp'))(this.bookbuildingMessages))
    const index = findIndex(propEq('id', updatedBookbuildingMessage.id))(this.bookbuildingMessages)
    index > -1
      ? this.bookbuildingMessages = update(index, updatedBookbuildingMessage, sortedBBMsByTimeStamp)
      : this.bookbuildingMessages = insert(0, updatedBookbuildingMessage, sortedBBMsByTimeStamp)
  }

  deleteBookbuildingMessage (deletedBookbuildingMessage: IBbmItemRemove): void {
    if (isNil(this.bookbuildingMessages)) return
    this.bookbuildingMessages = filter(
      complement(propEq('id', deletedBookbuildingMessage.id)),
      this.bookbuildingMessages
    )
  }
}
