import { set, view } from 'ramda'
import { useQuery, useQueryClient } from 'react-query'
import { getUserProfile, ProfileDTO } from 'services/users'
import { useCallback, useMemo } from 'react'
import { CACHED_QUERY_PROPS, USER_PREFERENCES_KEY } from 'constants/queries'
import { getUserPreferenceView } from './utils'
import { Options } from 'lib/types'
import { useMutationWithErrorHandling } from 'lib/hooks'
import { useStores } from 'store/rootStore'

export function useUserPreferences<T> (
  preferencePath: string[],
  options?: Options):
  [
    T,
    (newValue: T) => Promise<void>,
    boolean
  ] {
  const queryClient = useQueryClient()
  const { commonStore, userStore } = useStores()
  const { data, isSuccess } = useQuery<ProfileDTO>(USER_PREFERENCES_KEY, getUserProfile, CACHED_QUERY_PROPS)
  const {
    userPreferences,
    preferenceLens
  } = useMemo(() => getUserPreferenceView<T>(data, preferencePath), [data, preferencePath])

  const userPreference = view(preferenceLens, userPreferences)
  const mutateUserPreferences = useMutationWithErrorHandling(options)
  const setUserPreference = useCallback(async (newValue: T): Promise<void> => {
    const newUserPreferences = set(preferenceLens, newValue, userPreferences)
    commonStore.dashboardRegion = newUserPreferences.dashboardRegion
    userStore.user.preferences = newUserPreferences
    queryClient.setQueryData<ProfileDTO>(USER_PREFERENCES_KEY, {
      user: {
        preferences: newUserPreferences
      }
    } as unknown as ProfileDTO)
    return await mutateUserPreferences.mutateAsync(newUserPreferences).then(() => {
      commonStore.dashboardRegion = newUserPreferences.dashboardRegion
    })
  }, [commonStore, userStore, mutateUserPreferences, preferenceLens, queryClient, userPreferences])

  return [userPreference, setUserPreference, isSuccess]
}
