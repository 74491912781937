import React, { forwardRef } from 'react'
import clsx from 'clsx'
import { ReactHookFormProps } from './types'
import { FormLabel } from './FormLabel'
import { FormError } from './FormError'

interface Props extends ReactHookFormProps {
  label: string
  disabled?: boolean
  required?: boolean
  dataQa?: string
}

export const FormFieldCheckbox = forwardRef<HTMLInputElement, Props>((props: Props, ref): JSX.Element => {
  const className = clsx(
    'mr-3 focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded'
  )

  const htmlFor = props.name

  return (
    <div>
      <div className='mt-2 flex items-center'>
        <input
          id={htmlFor}
          ref={ref}
          type='checkbox'
          required={props.required}
          disabled={props.disabled}
          name={props.name}
          onChange={props.onChange}
          onBlur={props.onBlur}
          className={className}
          data-qa={props.dataQa}
        />
        <FormLabel htmlFor={htmlFor} label={props.label} />
      </div>
      <FormError errors={props.errors ?? {}} name={props.name} />
    </div>
  )
})
