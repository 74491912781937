import React, { useCallback, useState } from 'react'
import { TabsProps } from 'components/Tabs/types'
import { isNil } from 'ramda'
import Item from 'components/Tabs/Item'

const MyTabs = <T,>({
  tabLabels,
  TabContainer,
  rightComponent,
  onTabChange,
  additionalContainerProps,
  defaultActiveTabIndex
}: TabsProps<T>): JSX.Element => {
  const [value, setValue] = useState<number>(defaultActiveTabIndex ?? 0)

  const onHandleTabChange = useCallback((newValue) => {
    setValue(newValue)
    onTabChange?.(tabLabels[newValue])
  }, [onTabChange, tabLabels])

  return (
    <div data-qa='Tab-Component'>
      <div
        className='text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400
        dark:border-gray-700 flex justify-between'
      >
        <ul className='flex flex-wrap -mb-px'>
          {tabLabels.map((label, index) =>
            <Item<T>
              key={`tab-key-${index}`}
              isActiveTab={index === value}
              label={label}
              index={index}
              onChange={onHandleTabChange}
            />
          )}
        </ul>
        {!isNil(rightComponent) && (
          <div className='grow-0 text-white my-auto flex gap-2'>
            {rightComponent}
          </div>
        )}
      </div>
      <div className='my-2'>
        <TabContainer value={tabLabels[value]} additionalContainerProps={additionalContainerProps} />
      </div>
    </div>

  )
}

export default React.memo(MyTabs) as typeof MyTabs
