import React from 'react'
import { useStores } from 'store/rootStore'
import { observer } from 'mobx-react-lite'
import { FilterItemProps } from 'components/CountryFilter/types'
import { Checkbox } from '@ppui/ui-components'

export const FilterItem: React.FC<FilterItemProps> = observer(({
  id,
  type,
  name,
  value,
  onCheckboxClick,
  code
}): JSX.Element => {
  const { dealsOrTermsheetsStore, filterStore } = useStores()
  const activeCountriesAmount = type === 'countries'
    ? dealsOrTermsheetsStore.getAmountOfActiveDealsByCountriesAndRegion(filterStore.activeFilterTab)?.[code]
    : 0

  return (
    <Checkbox
      id={id}
      value={value}
      label={activeCountriesAmount > 0
        ? (
          <div className='flex font-medium'>
            <span>{name}</span>
          </div>
          )
        : name}
      onChangeHandler={onCheckboxClick({ type, value: code })}
    />
  )
}
)
