import { makeAutoObservable } from 'mobx'
import {
  isEmpty,
  anyPass,
  isNil,
  keys,
  type,
  pathOr,
  propEq,
  allPass,
  pluck,
  uniq, equals
} from 'ramda'
import {
  CountriesType,
  CountriesWithNumberOfActiveDeals,
  CountryDTO,
  CountryItem,
  FilterDisplay,
  FilterFn, SubRegionSoringMappingType
} from 'store/types'
import { computedFn } from 'mobx-utils'
import { RootStore } from 'store/rootStore'
import {
  getCountriesBySubRegionsAndRegion,
  parseCountryDTO,
  subRegionsSortingMapping
} from 'store/countriesStore/utils'

export const initValue: CountriesType = {}

export class CountriesStore {
  rootStore: RootStore
  countriesWithStockExchanges: CountriesType = initValue
  subRegionsSortingMapping: SubRegionSoringMappingType = {}
  rawCountriesWithStockExchanges: CountryDTO[] = []
  allCountryCodesWithStockExchanges: string[] = []
  private _allCountriesByAlpha3ISOCode: {[key: string]: CountryDTO} = {}
  private allCountries: CountryDTO[] = []

  constructor (rootStore: RootStore) {
    this.rootStore = rootStore
    makeAutoObservable(this)
  }

  getSubRegionsForCurrentRegion = computedFn((): string[] => {
    const region = this.rootStore.filterStore.activeFilterTab
    return keys(this.countriesWithStockExchanges[region])

      .sort((a, b) => {
        const sortIndex = this.subRegionsSortingMapping[b] - this.subRegionsSortingMapping[a]
        return isNaN(sortIndex) ? 0 : sortIndex
      }) as string[]
  })

  getCountriesBySubRegionsAndRegion = computedFn((subRegionList: string[]): CountryItem[] => {
    const region = this.rootStore.filterStore.activeFilterTab

    if (!Array.isArray(subRegionList) || type(this.countriesWithStockExchanges[region]) !== 'Object') return []
    return getCountriesBySubRegionsAndRegion(
      subRegionList,
      this.countriesWithStockExchanges[region]
    )
  })

  getCountriesBySubRegion (subRegion: string): CountryItem[] {
    const region = this.rootStore.filterStore.activeFilterTab
    if (type(subRegion) !== 'String' || type(this.countriesWithStockExchanges[region]) !== 'Object') return []
    return pathOr([], [region, subRegion], this.countriesWithStockExchanges)
  }

  getFilterPredicateByActiveDeals (countriesWithActiveDeals: CountriesWithNumberOfActiveDeals): FilterFn {
    const region = this.rootStore.filterStore.activeFilterTab
    const selectedCountries = this.rootStore.filterStore.filter[region].countries
    return anyPass([
      propEq('displayType', FilterDisplay.SHOW),
      allPass([
        propEq('displayType', FilterDisplay.HIDDEN),
        ({ code }) => countriesWithActiveDeals?.[code] > 0 || selectedCountries.includes(code)
      ])
    ])
  }

  filterCountriesByDisplayType (countries: CountryItem[], filterPredicate: FilterDisplay | FilterFn): CountryItem[] {
    const filterFn = type(filterPredicate) === 'Function'
      ? filterPredicate as FilterFn
      : ({ displayType }) => displayType === filterPredicate
    return countries.filter(filterFn).sort((a, b) => a.name.localeCompare(b.name))
  }

  get isCountriesEmpty (): boolean {
    return anyPass([isEmpty, isNil])(this.countriesWithStockExchanges)
  }

  set all (data: CountryDTO[]) {
    if (!equals(this.allCountries, data)) {
      this.allCountries = data
      this._allCountriesByAlpha3ISOCode = data.reduce((acc, item) => ({
        ...acc,
        [item.alpha3ISOCode]: item
      }), {})
    }
  }

  get all (): CountryDTO[] {
    return this.allCountries
  }

  get allCountriesByAlpha3ISOCode (): {[key: string]: CountryDTO} {
    return this._allCountriesByAlpha3ISOCode
  }

  setCountriesWithCodesFromList (data: CountryDTO[]): void {
    if (type(data) !== 'Array' || equals(this.rawCountriesWithStockExchanges, data)) return
    this.rawCountriesWithStockExchanges = data
    this.allCountryCodesWithStockExchanges = uniq(pluck('alpha3ISOCode', data))
    this.countriesWithStockExchanges = parseCountryDTO(data)
    this.subRegionsSortingMapping = subRegionsSortingMapping(data)
  }
}
