import { TermsheetRegion } from 'constants/index'

export enum EmailNotificationTypes {
  REAL_TIME = 'REAL_TIME',
  ACTIVITY_SUMMARY = 'ACTIVITY_SUMMARY'
}

export enum EmailNotificationFormat {
  SUMMARY = 'SUMMARY',
  DETAILED = 'DETAILED'
}
export type EmailNotificationSettings = {
  [key in TermsheetRegion]: {
    emailNotificationTypes: EmailNotificationTypes[]
  }
}
