import React from 'react'
import PropTypes from 'prop-types'
import CancelIcon from '@material-ui/icons/Cancel'
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone'
import { ConfirmModalStyled } from './ConfirmModalStyled'
import { Button } from '@material-ui/core'
import { SubmitButtonAddNewDealStyled } from 'components/Buttons/AddNewDeal/PublishButtonAddNewDeal'

export function CommonMessageModal ({ messages, setIsModalOpen }): JSX.Element {
  return (
    <ConfirmModalStyled>
      <div onClick={() => setIsModalOpen(false)} className='confirmation-modal-cancel'>
        <CancelIcon />
      </div>
      <div className='confirmation-modal-bell'>
        <NotificationsNoneIcon />
      </div>
      {messages?.map((message, index) => {
        return (
          <p className='text-center font-bold' key={index}>
            {message}
          </p>
        )
      })}

      <div className='confirmation-modal-confirm place-content-center pt-5'>
        <SubmitButtonAddNewDealStyled onClick={() => setIsModalOpen(false)}>
          <Button className='MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-fullWidth' onClick={() => setIsModalOpen(false)} fullWidth>
            OK
          </Button>
        </SubmitButtonAddNewDealStyled>

      </div>

    </ConfirmModalStyled>
  )
}

CommonMessageModal.propTypes = {
  setIsModalOpen: PropTypes.func,
  messages: PropTypes.array
}
