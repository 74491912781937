import React, { ReactElement } from 'react'
import { Route, useLocation } from 'react-router-dom'
import { AppContainer } from 'components/AppContainer'
import { useProfileHook } from 'contexts/user-auth-context'
import { RedirectionType } from 'store/types'
import { useStores } from 'store/rootStore'
import { LoadingSpinner } from 'components/LoadingSpinner'
import NotFoundPage from 'routes/NotFoundPage'
import { getFeatureFlagHideECMRelatedFeature } from 'routes/utils'

export interface RouteProps { children: ReactElement, exact?: boolean, path?: string | string[] }

export function AuthenticatedRoute ({ children, exact, path }: RouteProps): JSX.Element {
  const [profile] = useProfileHook()
  const { isAuthenticated, isAuthenticationRequestDone } = profile
  const { search } = useLocation()
  const { redirectionStore, commonStore } = useStores()
  const query = React.useMemo(() => new URLSearchParams(search), [search])
  if (query.has('redirectionType') && query.has('bankId')) {
    redirectionStore.type = query.get('redirectionType') as RedirectionType
    redirectionStore.payload = { bankId: query.get('bankId') as string }
  }
  const FeatureFlagHideECMLoginScreen = getFeatureFlagHideECMRelatedFeature(
    commonStore.config?.envName,
    commonStore.config?.wsEndpoint
  )
  const isHideECMLoginScreenEnabled: boolean = commonStore.config?.envName
    ? FeatureFlagHideECMLoginScreen[commonStore.config?.envName]
    : false

  return (
    <Route exact={exact} path={path}>
      {getContentByPathAndAuth(isAuthenticated, isAuthenticationRequestDone, children, isHideECMLoginScreenEnabled)}
    </Route>
  )
}

function getContentByPathAndAuth (
  isAuthenticated: boolean,
  isAuthenticationRequestDone: boolean,
  children: ReactElement,
  isHideECMLoginScreenEnabled: boolean): JSX.Element | undefined {
  if (!isAuthenticationRequestDone) {
    return <AppContainer isAuthenticated={false}><LoadingSpinner /></AppContainer>
  }
  if (isAuthenticated) {
    return <AppContainer isAuthenticated>{children}</AppContainer>
  } else if (isHideECMLoginScreenEnabled) {
    return <AppContainer isAuthenticated={false}><NotFoundPage /></AppContainer>
  }
}
