import { pathOr, toLower } from 'ramda'
import { ITermsheetV2Entity } from 'store/types'
import { IApiToUIViewMapping } from 'routes/TermsheetV2ViewPage/types'
import { isReallyNotEmpty } from 'utils/index'

export const getTsPathBasedOnKeyAndType = (key: string, offeringType: string, suffix?: string): string[] => {
  const compositeKey = key.split('.')
  return compositeKey.length === 1 ? compositeKey : [toLower(offeringType ?? '') + (suffix ?? ''), ...compositeKey.slice(1)]
}
export const getExistingApiFieldKeysToBeMapped = (selectedTermsheet: ITermsheetV2Entity, mapping: IApiToUIViewMapping, suffix?: string): string[] =>
  Object.keys(mapping).reduce<string[]>((acc, key) => {
    const tsPath = getTsPathBasedOnKeyAndType(key, selectedTermsheet?.offeringType, suffix)
    return [
      ...acc,
      ...isReallyNotEmpty(pathOr(null, tsPath, selectedTermsheet))
        ? [key]
        : []
    ]
  }, [])
