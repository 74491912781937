import React, { ComponentProps } from 'react'

interface IconProps {
  element: (props: ComponentProps<'svg'> & { alt?: string }) => JSX.Element
  className?: string
}

export function Icon ({ className, element }: IconProps): JSX.Element {
  return React.createElement(element, { className: className ?? 'h-12 w-12' })
}
