import { DashboardRegion, TermsheetRegion } from 'constants/index'
import { useMemo } from 'react'
import { map, pipe, values, without } from 'ramda'
import { SelectOptions } from '@ppui/ui-components/dist/esm/components/Select/types'

export const useRegionList = <T extends DashboardRegion | TermsheetRegion,>(omitedValue?: DashboardRegion): T[] => {
  return useMemo((): T[] => {
    return pipe(
      values as (obj: typeof DashboardRegion) => DashboardRegion[],
      without([omitedValue]) as (arg: DashboardRegion[]) => DashboardRegion[],
      (values: DashboardRegion[]) => values)(DashboardRegion) as unknown as T[]
  }, [omitedValue])
}

export const useRegionSelectOptions = (valuesToSkip?: DashboardRegion[]): SelectOptions[] => {
  return useMemo(() =>
    pipe(
      values,
      without(valuesToSkip ?? []),
      map((value) => ({
        value,
        text: value
      }))
    )(DashboardRegion)
  , [valuesToSkip])
}
