import { DateTime } from 'luxon'
import { allPass, anyPass, intersection, isEmpty, pipe, propOr } from 'ramda'
import { DashboardDealViewDTO } from 'services/deals'
import { OFFERING_TYPE, STATE_ORDERING } from 'constants/index'
import { IFilterSettings } from 'store/types'
import { rootStore } from 'store/rootStore'
import { AccountType } from 'lib/types'
import { isSecondAfterFirstDate } from 'utils/dateHelpers'
import { DashboardV2SectionType, PartitionedDealsFromAPI } from 'routes/DashboardPageV2/types'

export const isNullishOrEmpty = (str: undefined|null|string): boolean => str == null || str === ''

export const sortByNewest = <T extends DashboardDealViewDTO>(deals: T[]): T[] => {
  return sortByDate(deals, true)
}

export const sortByOldest = <T extends DashboardDealViewDTO>(deals: T[]): T[] => {
  return sortByDate(deals, false)
}

const isDateUnknown = (fieldKey: string) =>
  (deal: DashboardDealViewDTO) => isNullishOrEmpty(deal[fieldKey]) || deal[fieldKey] === 'TBA'

const sortByDate = <T extends DashboardDealViewDTO>(deals: T[], reverseSort: boolean): T[] => {
  const isRi = deals.length > 0 && deals?.[0].offeringType === OFFERING_TYPE.RI
  const sortationDateKey = isRi ? 'endOfSubscriptionPeriodDate' : 'closeOfBookbuildingDate'
  const dealsTBA = deals.filter(isDateUnknown(sortationDateKey))
  const dealsWithDate = deals
    .filter((deal) => !isDateUnknown(sortationDateKey)(deal))
    .sort((a, b) => {
      const { aDateTime, bDateTime } = {
        aDateTime: DateTime.fromFormat(a[sortationDateKey] ?? '', 'yyyy-LL-dd').toMillis(),
        bDateTime: DateTime.fromFormat(b[sortationDateKey] ?? '', 'yyyy-LL-dd').toMillis()
      }
      return reverseSort ? bDateTime - aDateTime : aDateTime - bDateTime
    })
  return [...dealsWithDate, ...dealsTBA]
}

const countryFilter = (
  filterSettings: IFilterSettings,
  countries: string[],
  allowedCountries: string[],
  countryCodes: string[]
): boolean => {
  if (!filterSettings.isGeoFilterEnabled) {
    return true
  }
  if (filterSettings.includeWithNoStockExchanges && countryCodes.length === 0) {
    return true
  }
  const filteredCountryCodes = intersection(countryCodes, countries)
  return filteredCountryCodes?.length > 0 && intersection(filteredCountryCodes, allowedCountries)?.length > 0
}

export const applyUserFilters = <T extends DashboardDealViewDTO>(
  filterSettings: IFilterSettings,
  allowedCountries: string[],
  countries: string[],
  data: T[],
  allowedDealTypes,
  sectors: string[]
): T[] =>
    data.filter(
      allPass([
        anyPass([
          () => isEmpty(sectors),
          (item) => sectors.includes((item as DashboardDealViewDTO).sectorShortName ?? '')
        ]),
        anyPass([
          () => isEmpty(allowedDealTypes),
          (item) => allowedDealTypes.includes(item.offeringType)
        ]),
        anyPass([
          () => isEmpty(filterSettings.statusList),
          (item) => filterSettings.statusList.includes(item.state)
        ]),
        pipe(
          propOr([], 'countryCodes'),
          anyPass([
            () => isEmpty(countries),
            (countryCodes: string[]) => countryFilter(filterSettings, countries, allowedCountries, countryCodes)
          ])
        )
      ])
    )

export const applyTransforms = (fn: (deal: DashboardDealViewDTO) => DashboardV2SectionType) => (data: PartitionedDealsFromAPI) => {
  return {
    announced: data.announced.map(fn),
    live: data.live.map(fn),
    priced: data.priced.map(fn),
    drafts: data.drafts.map(fn),
    withdrawn: data.withdrawn.map(fn)
  }
}
export const applySorting = (data: PartitionedDealsFromAPI): PartitionedDealsFromAPI => ({
  announced: sortByOldest(data.announced),
  live: sortByOldest(data.live),
  priced: sortByNewest(data.priced),
  drafts: sortByOldest(data.drafts),
  withdrawn: sortByNewest(data.withdrawn)
})

export const isDealAvailable = (deal: DashboardDealViewDTO): boolean => rootStore.userStore.user.profile?.user?.accountType === AccountType.INVESTOR
  ? Boolean(deal.isATermsheetViewableOnThisDeal)
  : true

export const stateSorting = (rowA: any, rowB: any, columnId: any): number => {
  const firstPosition = STATE_ORDERING[rowA.getValue(columnId).toUpperCase()]
  const secondPosition = STATE_ORDERING[rowB.getValue(columnId).toUpperCase()]
  if (firstPosition === secondPosition) {
    return 0
  }
  return STATE_ORDERING[rowA.getValue(columnId).toUpperCase()] < STATE_ORDERING[rowB.getValue(columnId).toUpperCase()]
    ? 1
    : -1
}

export const booksClosedDateSorting = (rowA: any, rowB: any): number => {
  const [dateA, dateB] = [rowA, rowB].map(({
    original: {
      offeringType,
      endOfSubscriptionPeriodDate,
      closeOfBookbuildingDate
    }
  }) =>
    offeringType === OFFERING_TYPE.RI ? endOfSubscriptionPeriodDate : closeOfBookbuildingDate)
  if (dateA === dateB) {
    return 0
  }
  return isSecondAfterFirstDate(dateB, dateA) ? 1 : -1
}
