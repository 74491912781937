/* eslint-disable no-console */
import React, { ReactNode } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { QueryClient, QueryClientProvider, setLogger } from 'react-query'
import { ThemeProvider } from '@material-ui/core'
import { MUITheme } from './theme'
import { AppProviders } from './contexts/app-providers'

setLogger({
  log: console.log,
  warn: console.warn,
  error: console.warn
})
const queryClient = new QueryClient()

export function Providers ({ children, isMfe = false }: { children: ReactNode, isMfe?: boolean }): JSX.Element {
  return (
    <ThemeProvider theme={MUITheme}>
      <BrowserRouter basename={isMfe ? '/ecm' : ''}>
        <QueryClientProvider client={queryClient}>
          <AppProviders isMfe={isMfe}>
            {children}
          </AppProviders>
        </QueryClientProvider>
      </BrowserRouter>
    </ThemeProvider>
  )
}
