import { DashboardDealViewDTO } from 'services/deals'
import { pluck } from 'ramda'
import { DashboardV2SectionType } from 'routes/DashboardPageV2/types'
import { parseAndFormatDateV2 } from 'utils/dateHelpers'
import { isDealAvailable } from 'routes/DashboardPageV2/helpers/utils'

export const dashboardDealTransform = (deal: DashboardDealViewDTO): DashboardV2SectionType => {
  return {
    id: deal.dealV2Id,
    available: isDealAvailable(deal),
    draft: deal.draft ?? false,
    issuerName: deal.issuerName,
    pricingDate: deal.pricingDate ?? null,
    publishingBankNames: pluck('publishingBankName', deal.publishingBankNames ?? []),
    publishingBankNamesFull: deal.publishingBankNames,
    region: deal.region,
    type: deal.offeringType,
    isOrderExists: deal.orderId != null,
    termsheetIds: deal.termsheetIds,
    ...deal.offeringType === 'RI'
      ? { endOfSubscriptionPeriodDate: parseAndFormatDateV2(deal.endOfSubscriptionPeriodDate) }
      : { closeOfBookbuildingDate: parseAndFormatDateV2(deal.closeOfBookbuildingDate) },
    offeringSharePriceRangeMin: deal.offeringSharePriceRangeMin,
    offeringSharePriceRangeMax: deal.offeringSharePriceRangeMax,
    finalPriceLocalCcy: deal.finalPriceLocalCcy
  }
}
