import { CacheBustNotificationSnackbar } from 'components/Snackbar/CacheBustNotificationSnackbar'
import { reportPageView } from 'config/configureGoogleAnalytics'
import { FEATURES } from 'constants/features'
import { USER_PREFERENCES_KEY } from 'constants/queries'
import { useProfileHook } from 'contexts/user-auth-context'
import { useFeatureFlags } from 'lib/useFeatureFlags'
import { observer } from 'mobx-react-lite'
import { isNil } from 'ramda'
import React, { useEffect, useRef } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { useHistory, useLocation } from 'react-router-dom'
import RoutedApp from 'routes'
import { getUserProfile, ProfileDTO } from 'services/users'
import { useStores } from 'store/rootStore'
import { Alert, CloseableToast, ToastContainer } from 'ui'
import { GitlabMRLink } from 'utils/GitlabMRLink'
import { hotJarFun } from 'utils/hotjarHelpers'
import { useAnalyticTelemetry } from 'lib/queries/usePostTelemetryData'
import { TelemetryData, TelemetryStatus } from 'services/telemetry'
import { ClientConfigDTO } from 'interfaces/Config'
import { useIsSpecificPathWatch, useScreenMetadata } from 'lib/hooks'
import { CountryFilter } from 'components/CountryFilter'
interface AppProps {
  config: ClientConfigDTO
}
const USER_LOGGED_OUT = 'pp-user-logged-out'

const App: React.FC<AppProps> = observer(({ config }): JSX.Element | null => {
  const location = useLocation()
  const { mutateAsync } = useAnalyticTelemetry()
  const [profile] = useProfileHook()
  const { listen } = useHistory()
  const hotJarScriptRef = useRef(document.createElement('script'))
  const { isAuthenticated } = profile
  const rootStore = useStores()
  const { userStore, alertStore, commonStore } = rootStore
  const { isFeatureFlagEnabled } = useFeatureFlags()
  const isHotJarTrackingEnabled: boolean = isFeatureFlagEnabled(FEATURES.hotJarTracking)
  const queryClient = useQueryClient()
  useEffect(() => {
    const logoutEvent = (): void => {
      queryClient.clear()
      rootStore.resetStores()
    }
    window.addEventListener(USER_LOGGED_OUT, logoutEvent)
    return () => {
      window.removeEventListener(USER_LOGGED_OUT, logoutEvent)
    }
  }, [queryClient, rootStore])

  const isDashboardPath = useIsSpecificPathWatch([{
    path: '/ecm/dashboard',
    compare: 'includes'
  }])
  const { isTaaS, isInvestor } = userStore.getUserRoles()
  const isGeoFilterEnabled: boolean = isInvestor || isTaaS
  const { isMobile } = useScreenMetadata()
  useQuery<ProfileDTO | undefined>(
    [USER_PREFERENCES_KEY, isAuthenticated, userStore.isLoaded, userStore.isLoading],
    async () => {
      if (isAuthenticated) {
        return await getUserProfile()
      }
    },
    {
      retryDelay: 5000,
      onSuccess: (data) => {
        if (isNil(data)) return

        const { config, user, organization } = data
        commonStore.dashboardRegion = data.user.preferences.dashboardRegion
        userStore.user = {
          profile: data,
          config,
          preferences: user.preferences,
          organizationLogo: organization?.logo,
          userCountryCode: user.countryAlpha3Code
        }
      }
    }
  )
  useEffect(() => {
    const head = document.getElementsByTagName('head')[0]
    hotJarScriptRef.current.async = true
    if (isHotJarTrackingEnabled && config.envName === 'live' && !head.contains(hotJarScriptRef.current)) {
      hotJarFun(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=', head, hotJarScriptRef.current)
    } else if (head.contains(hotJarScriptRef.current)) {
      head.removeChild(hotJarScriptRef.current)
    }
  }, [isHotJarTrackingEnabled, config.envName])
  useEffect(() => {
    commonStore.config = config
    // there are some conflicting types in react-router-dom
    return listen((data: any) => {
      reportPageView(data?.pathname ?? data.location.pathname)
    })
  }, [listen, commonStore, config])

  useEffect(() => {
    const telemetryPayload: Partial<TelemetryData> = {
      pageURL: location.pathname,
      status: TelemetryStatus.PAGE_LOADED,
      pageName: location.pathname.substring(1),
      deviceType: isMobile ? 'mobile' : 'desktop'
    }
    async function sendTelemetryData (): Promise<void> {
      await mutateAsync(telemetryPayload)
    }
    if (isDashboardPath) {
      void sendTelemetryData()
    }
  }, [isDashboardPath, isMobile, location, mutateAsync])

  return (
    <>
      <GitlabMRLink envName={config.envName} />
      <ToastContainer>
        <CloseableToast shouldBeClosed={alertStore.shouldBeClosed}>
          {/* For now only one alert at a time is supported */}
          <Alert {...alertStore.alertData} />
        </CloseableToast>
      </ToastContainer>
      {profile.isMfe && isGeoFilterEnabled && (
        <div className='absolute w-full z-20'>
          <CountryFilter />
        </div>
      )}
      <RoutedApp />
      <CacheBustNotificationSnackbar />
    </>
  )
})

export { App }
