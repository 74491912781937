import { makeAutoObservable } from 'mobx'

import { RootStore } from 'store/rootStore'
import { equals } from 'ramda'
import { ITermsheetV2Entity } from 'store/types'
import { DealsLocalStorageKeys } from 'constants/deals'
import { isReallyNotEmpty } from 'utils'

export class TempTermsheetsStore {
  private readonly rootStore: RootStore
  private _tempTermsheet: ITermsheetV2Entity | null = null
  private _isBankSelectorClicked: boolean = false

  constructor (rootStore: RootStore) {
    this.rootStore = rootStore
    makeAutoObservable(this)
  }

  set tempTermsheet (value: ITermsheetV2Entity | null) {
    if (!equals(this._tempTermsheet, value)) {
      localStorage.setItem(DealsLocalStorageKeys.createdTermsheet, JSON.stringify(value))
      localStorage.setItem(DealsLocalStorageKeys.createdTimestamp, (new Date()).toString())
      this._tempTermsheet = value
    }
  }

  get tempTermsheet (): ITermsheetV2Entity | null {
    const createdTimestamp = localStorage.getItem(DealsLocalStorageKeys.createdTimestamp)
    if (createdTimestamp) {
      const timediff = ((new Date()).getTime() - (new Date(createdTimestamp)).getTime()) / 1000 / 60
      if (timediff > 2) {
        this._tempTermsheet = null
        localStorage.removeItem(DealsLocalStorageKeys.createdTermsheet)
        localStorage.removeItem(DealsLocalStorageKeys.createdTimestamp)
      }
    }
    if (isReallyNotEmpty(this._tempTermsheet)) {
      return this._tempTermsheet
    }
    try {
      this._tempTermsheet = JSON.parse(localStorage.getItem(DealsLocalStorageKeys.createdTermsheet) ?? '')
      return this._tempTermsheet
    } catch (e) {
      return null
    }
  }

  setIsBankSelectorClicked (value: boolean): void {
    this._isBankSelectorClicked = value
  }

  getIsBankSelectorClicked (): boolean {
    return this._isBankSelectorClicked
  }
}
