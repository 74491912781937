import { ChangeEvent, useCallback } from 'react'
import { useStores } from 'store/rootStore'
import { DashboardFilters } from 'services/users'
import { isNil, mergeDeepRight, pluck } from 'ramda'
import { OnCheckboxClickType, UseCountryFilterReturn } from 'components/CountryFilter/types'

export const useCountryFilter = (): UseCountryFilterReturn => {
  const { filterStore, countriesStore } = useStores()

  const onChange = useCallback<OnCheckboxClickType>((
    {
      type,
      value,
      subRegion: subRegionWhenTypeIsCountry,
      allVisibleCountries
    }
  ) =>
    (event: ChangeEvent<HTMLInputElement>) => {
      const checked: boolean = event.target.checked
      if (!checked && type === 'subRegions') {
        filterStore.removeSubRegionWithCountries(
          value,
          pluck('code', countriesStore.getCountriesBySubRegionsAndRegion([value])))
        return
      }
      if (!checked && type === 'countries' && !isNil(subRegionWhenTypeIsCountry)) {
        const allCountriesForCurtRegions = pluck('code', countriesStore.getCountriesBySubRegionsAndRegion(
          [subRegionWhenTypeIsCountry]
        ))
        filterStore.truncateValueFromFilter(type, value)
        if (!filterStore.checkIfFilteredCountriesExistInProvidedCountryList(allCountriesForCurtRegions)) {
          filterStore.truncateValueFromFilter('subRegions', subRegionWhenTypeIsCountry)
        }
        return
      }
      filterStore.addFilterByName(type, value)

      if (type === 'countries' && !isNil(subRegionWhenTypeIsCountry)) {
        filterStore.addFilterByName('subRegions', subRegionWhenTypeIsCountry)
        return
      }

      type === 'subRegions' && allVisibleCountries && allVisibleCountries?.length > 0 &&
      filterStore.setFilter(
        mergeDeepRight(filterStore.filter, {
          [filterStore.activeFilterTab]: {
            countries: [
              ...filterStore.filter[filterStore.activeFilterTab].countries,
              ...pluck('code', allVisibleCountries)
            ]
          }
        }) as DashboardFilters

      )
    }, [countriesStore, filterStore])

  return {
    onChange,
    selectedCountries: filterStore.filter[filterStore.activeFilterTab].countries,
    selectedSubregions: filterStore.filter[filterStore.activeFilterTab].subRegions
  }
}
