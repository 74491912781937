import {
  CountriesWithNumberOfActiveDeals,
  DealListByTypeItemV2,
  IFilterSettings
} from 'store/types'
import {
  all,
  filter,
  mergeDeepWith,
  defaultTo,
  pipe,
  propEq,
  reduce
} from 'ramda'
import { DashboardRegion, OFFERING_TYPE } from 'constants/index'
import memoize from 'fast-memoize'
import { DashboardDealViewDTO } from 'services/deals'
import { applySorting, applyTransforms, applyUserFilters } from 'routes/DashboardPageV2/helpers/utils'
import { getAnnouncedDeals, getLiveDeals, partitionDeals } from 'routes/DashboardPageV2/helpers/partition'
import { dashboardDealTransform } from 'routes/DashboardPageV2/helpers/transform'

export const getPartitionedDealsV2 = memoize((
  filterSettings: IFilterSettings,
  deals: DashboardDealViewDTO[],
  countries: string[],
  allowedCountries: string[],
  allowedDeals: OFFERING_TYPE[],
  sectors: string[]
): DealListByTypeItemV2 => {
  return reduce((acc, offeringType) => ({
    ...acc,
    [offeringType]: pipe(
      (data: DashboardDealViewDTO[]): DashboardDealViewDTO[] => applyUserFilters(
        filterSettings,
        allowedCountries,
        countries,
        data,
        allowedDeals,
        sectors
      ),
      filter(propEq('offeringType', offeringType)) as (arg: DashboardDealViewDTO[]) => DashboardDealViewDTO[],
      partitionDeals,
      applySorting,
      applyTransforms(dashboardDealTransform)
    )(deals)
  }), {}, Object.keys(OFFERING_TYPE))
})

export const getFilteredDealsV2 = memoize((
  filterSettings: IFilterSettings,
  deals: DashboardDealViewDTO[],
  countries: string[],
  allowedCountries: string[],
  allowedDealTypes: OFFERING_TYPE[],
  sectors: string[]
): DashboardDealViewDTO[] => {
  return applyUserFilters(
    filterSettings,
    allowedCountries,
    countries,
    deals,
    allowedDealTypes,
    sectors
  )
})

export const getDealsByRegion = memoize((dealsList) =>
  reduce((acc, item) => mergeDeepWith(
    (left, right) => all(Array.isArray)([left, right])
      ? [...left, ...right]
      : right,
    acc, {
      [DashboardRegion.ALL]: [item],
      [item.region]: [item]
    }), {}, dealsList)) as <T extends {region: string}>(dealsList: T[]) => {[region: string]: T[]}
export const getAmountOfActiveDealsByCountries = memoize(pipe(
  defaultTo([]) as (dealList?: DashboardDealViewDTO[]) => DashboardDealViewDTO[],
  (dealsList: DashboardDealViewDTO[]): CountriesWithNumberOfActiveDeals => {
    const announced = getAnnouncedDeals(dealsList)
    const live = getLiveDeals(dealsList)
    const deals = [...announced, ...live]
    return deals.flatMap(deal => deal.countryCodes ?? [])
      .reduce((countries, code) => {
        return {
          ...countries,
          [code]: Number(countries[code] || 0) + 1
        }
      }, {})
  }
))
