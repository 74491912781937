import { FieldsMapping } from 'store/types'
import { OtherSyndicateBanksType } from 'routes/TermsheetV2Page/components/Complete/types'
import { identity as keepAsIs } from 'ramda'
import { dateTimeToString } from 'store/applicationGeneratorStore/utils'
import { DistributionGroup, FIRST_STEP_NAME, OfferingType, SECOND_STEP_NAME } from 'store/applicationGeneratorStore/const'
import { isReallyNotEmpty } from 'utils'
import {
  PRIMARY_OR_SECONDARY
} from 'constants/index'
const otherSyndicateBanksConvert = (value: OtherSyndicateBanksType): any => value.map(({ bankRole, bankName, id }) => ({
  id,
  name: bankName,
  role: bankRole
}))

const sellingShareholdersConvert = (value, formFields): any => {
  if (formFields.primaryOrSecondary !== PRIMARY_OR_SECONDARY.PRIMARY) {
    return (value ?? []).reduce((acc, item) =>
      [
        ...acc,
        ...Object.values(item).some((property) => isReallyNotEmpty(property))
          ? [{
              lockup: item?.lockUpExpiryDays,
              shareholder: item?.shareholder,
              shareholderLegal: item?.sellingShareholdersLegal
            }]
          : []],
    [])
  } else {
    return []
  }
}

const calculateFieldIfSecondary = (value: string, formFields: Record<string, any>): any => {
  return formFields.primaryOrSecondary === PRIMARY_OR_SECONDARY.PRIMARY ? '' : value
}

const calculateFieldIfPrimary = (value: string, formFields: Record<string, any>): any => {
  return formFields.primaryOrSecondary === PRIMARY_OR_SECONDARY.SECONDARY ? '' : value
}

export const FORM_TO_API_FIELDS_MAPPING: FieldsMapping = {
  [FIRST_STEP_NAME]: {
    listingExchange: {
      name: 'listingExchange',
      location: 'root',
      valueMappingHandler: (items) =>
        items.map(({ label, value }) => {
          const [marketIdentifierCode, countryAlpha3ISOCode] = value.split(',')
          return {
            displayName: label,
            marketIdentifierCode,
            countryAlpha3ISOCode
          }
        })
    },
    distributionUSA: {
      name: 'allowedDistributionGroups',
      location: 'root',
      valueMappingHandler: (value) => {
        const mapping = {
          yes: [DistributionGroup.QIBS],
          no: [DistributionGroup.REG_S]
        }
        return mapping[value]
      }
    },
    distributionIntoSaudiArabia: {
      name: 'distributionIntoSaudiArabia',
      location: 'root',
      valueMappingHandler: (value) => value === 'yes'
    },
    primaryOrSecondary: {
      name: 'primaryOrSecondary',
      valueMappingHandler: (value) => value.toUpperCase()
    },
    state: {
      name: 'state',
      valueMappingHandler: (value, _stepValues, offeringType) => {
        if ([OfferingType.RI, OfferingType.IPO].includes(offeringType)) {
          return value
        }
        return 'LIVE'
      }
    }
  },
  [SECOND_STEP_NAME]: {
    otherSyndicateBanks: {
      name: 'otherSyndicateBanks',
      location: 'root',
      valueMappingHandler: otherSyndicateBanksConvert
    },
    additionalInfo: {
      name: 'additionalInformation',
      location: 'root',
      valueMappingHandler: keepAsIs
    },
    headerDisclaimerText: {
      name: 'headerDisclaimer',
      valueMappingHandler: keepAsIs
    },
    offeringSizeNrSharesPrimary: {
      name: 'offeringSizeNrSharesPrimary',
      valueMappingHandler: calculateFieldIfPrimary,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [
        OfferingType.IPO,
        OfferingType.ABO,
        OfferingType.RI,
        OfferingType.OTH,
        OfferingType.SPAC
      ]
    },
    useOfProceeds: {
      name: 'useOfProceeds',
      valueMappingHandler: calculateFieldIfPrimary,
      location: 'root'
    },
    offeringSizeNrSharesPrimaryFinal: {
      name: 'offeringSizeNrSharesPrimaryFinal',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [
        OfferingType.IPO,
        OfferingType.ABO,
        OfferingType.RI,
        OfferingType.OTH,
        OfferingType.SPAC
      ]
    },
    offeringSizeNrSharesSecondary: {
      name: 'offeringSizeNrSharesSecondary',
      valueMappingHandler: calculateFieldIfSecondary,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [OfferingType.IPO, OfferingType.ABO, OfferingType.OTH]
    },
    offeringSizeNrSharesSecondaryFinal: {
      name: 'offeringSizeNrSharesSecondaryFinal',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [OfferingType.IPO, OfferingType.ABO, OfferingType.OTH]
    },
    sellingShareholders: {
      name: 'sellingShareholders',
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [
        OfferingType.IPO,
        OfferingType.ABO,
        OfferingType.OTH,
        OfferingType.SPAC
      ],
      valueMappingHandler: sellingShareholdersConvert
    },
    offeringImpliedMarketValue: {
      name: 'offeringImpliedMarketValue',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [OfferingType.IPO]
    },
    offeringSizeValue: {
      name: 'offeringSizeValue',
      valueMappingHandler: keepAsIs,
      location: 'root',
      belongsToOfferingTypes: [
        OfferingType.IPO,
        OfferingType.ABO,
        OfferingType.EQL,
        OfferingType.RI,
        OfferingType.OTH,
        OfferingType.SPAC
      ]
    },
    greenshoe: {
      name: 'greenshoe',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [OfferingType.IPO, OfferingType.ABO, OfferingType.OTH]
    },
    isinNumber: {
      name: 'isinNumber',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [
        OfferingType.IPO,
        OfferingType.ABO,
        OfferingType.RI,
        OfferingType.OTH,
        OfferingType.SPAC
      ]
    },
    unitComposition: {
      name: 'unitComposition',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [OfferingType.SPAC]
    },
    keyIndividualsFounders: {
      name: 'keyIndividualsFounders',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [OfferingType.SPAC]
    },
    spacLifetime: {
      name: 'spacLifetime',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [OfferingType.SPAC]
    },
    subscriptionPrice: {
      name: 'subscriptionPrice',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [OfferingType.RI, OfferingType.OTH]
    },
    subscriptionRatio: {
      name: 'subscriptionRatio',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [OfferingType.RI, OfferingType.OTH]
    },
    discountToTERP: {
      name: 'discountToTERP',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [OfferingType.RI]
    },
    mifidTargetMarket: {
      name: 'mifidTargetMarket',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [
        OfferingType.IPO,
        OfferingType.ABO,
        OfferingType.EQL,
        OfferingType.RI,
        OfferingType.OTH
      ]
    },
    securityType: {
      name: (offeringType) => {
        // due to incomplete migration from v1 to v2.
        // Adam Knight said he'll remember that it should be fixed eventually.
        if (offeringType === OfferingType.EQL) {
          return 'securityTypeEQL'
        }
        return 'securityType'
      },
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [
        OfferingType.IPO,
        OfferingType.ABO,
        OfferingType.RI,
        OfferingType.OTH
      ]
    },
    offeringMaturity: {
      name: 'offeringMaturity',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [OfferingType.EQL]
    },
    issuePrice: {
      name: 'issuePrice',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [OfferingType.EQL]
    },
    redemptionPrice: {
      name: 'redemptionPrice',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [OfferingType.EQL]
    },
    couponRange: {
      name: 'couponRange',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [OfferingType.EQL]
    },
    yieldToMaturity: {
      name: 'yieldToMaturity',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [OfferingType.EQL]
    },
    conversionPremium: {
      name: 'conversionPremium',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [OfferingType.EQL]
    },
    otherOfferingType: {
      name: 'otherOfferingType',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [OfferingType.OTH]
    },
    domesticSplit: {
      name: 'domesticSplit',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [OfferingType.OTH]
    },
    demandAllocationPolicy: {
      name: 'demandAllocationPolicy',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [OfferingType.OTH]
    },
    allocationCriteria: {
      name: 'allocationCriteria',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [
        OfferingType.IPO,
        OfferingType.ABO,
        OfferingType.RI,
        OfferingType.OTH
      ]
    },
    commissions: {
      name: 'commissions',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [
        OfferingType.IPO,
        OfferingType.ABO,
        OfferingType.RI,
        OfferingType.OTH
      ]
    },
    commitments: {
      name: 'commitments',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [
        OfferingType.IPO,
        OfferingType.ABO,
        OfferingType.RI,
        OfferingType.OTH,
        OfferingType.SPAC
      ]
    },
    investorAcknowledgement: {
      name: 'investorAcknowledgement',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [
        OfferingType.IPO,
        OfferingType.ABO,
        OfferingType.RI,
        OfferingType.OTH,
        OfferingType.SPAC
      ]
    },
    freeFloatPreGreenShoe: {
      name: 'freeFloatPreGreenShoe',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [OfferingType.IPO, OfferingType.ABO, OfferingType.OTH]
    },
    freeFloat: {
      name: 'freeFloat',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [OfferingType.IPO, OfferingType.ABO, OfferingType.OTH]
    },
    timeToBookCovered: {
      name: 'timeToBookCovered',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [OfferingType.ABO, OfferingType.EQL, OfferingType.OTH]
    },
    finalPrimarySizeLocalCcy: {
      name: 'finalPrimarySizeLocalCcy',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [OfferingType.IPO, OfferingType.ABO, OfferingType.OTH]
    },
    finalSecondarySizeLocalCcy: {
      name: 'finalSecondarySizeLocalCcy',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [OfferingType.IPO, OfferingType.ABO, OfferingType.OTH]
    },
    lastDayOfStabTrading: {
      name: 'lastDayOfStabTrading',
      valueMappingHandler: dateTimeToString,
      location: 'timetableSubObject',
      belongsToOfferingTypes: [
        OfferingType.IPO,
        OfferingType.ABO,
        OfferingType.EQL,
        OfferingType.RI
      ]
    },
    offeringSharePriceRange: {
      name: 'offeringSharePriceRange',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [
        OfferingType.IPO,
        OfferingType.ABO,
        OfferingType.OTH,
        OfferingType.SPAC
      ]
    },
    estimatedOfferingSizeHighLocalCcy: {
      name: 'estimatedOfferingSizeHighLocalCcy',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [
        OfferingType.IPO,
        OfferingType.ABO,
        OfferingType.OTH,
        OfferingType.SPAC
      ]
    },
    estimatedOfferingSizeLowLocalCcy: {
      name: 'estimatedOfferingSizeLowLocalCcy',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [
        OfferingType.IPO,
        OfferingType.ABO,
        OfferingType.OTH,
        OfferingType.SPAC
      ]
    },
    rumpOffering: {
      name: 'rumpOffering',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [OfferingType.ABO]
    },
    percentCapitalIncrease: {
      name: 'percentCapitalIncrease',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [
        OfferingType.IPO,
        OfferingType.ABO,
        OfferingType.RI,
        OfferingType.OTH
      ]
    },
    conditionsForCompletion: {
      name: 'conditionsForCompletion',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [
        OfferingType.IPO,
        OfferingType.ABO,
        OfferingType.RI,
        OfferingType.OTH
      ]
    },
    previousClose: {
      name: 'previousClose',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [OfferingType.ABO, OfferingType.OTH]
    },

    startOfTradingDate: {
      name: 'startOfTradingDate',
      valueMappingHandler: dateTimeToString,
      location: 'timetableSubObject',
      belongsToOfferingTypes: [
        OfferingType.IPO,
        OfferingType.ABO,
        OfferingType.OTH,
        OfferingType.SPAC
      ]
    },
    roadshowSchedule: {
      name: 'roadshowScheduleTiming',
      valueMappingHandler: dateTimeToString,
      location: 'timetableSubObject',
      belongsToOfferingTypes: [
        OfferingType.SPAC
      ]
    },
    upsizeOption: {
      name: 'upsizeOption',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [OfferingType.IPO, OfferingType.ABO, OfferingType.OTH]
    },
    priceRangeHighOriginal: {
      name: 'priceRangeHighOriginal',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [OfferingType.IPO, OfferingType.ABO, OfferingType.OTH]
    },
    priceRangeLowOriginal: {
      name: 'priceRangeLowOriginal',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [OfferingType.IPO, OfferingType.ABO, OfferingType.OTH]
    },
    priceRangeHighUpdated: {
      name: 'priceRangeHighUpdated',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [OfferingType.IPO, OfferingType.ABO, OfferingType.OTH]
    },
    priceRangeLowUpdated: {
      name: 'priceRangeLowUpdated',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [OfferingType.IPO, OfferingType.ABO, OfferingType.OTH]
    },
    priceRangeHighUpdated2: {
      name: 'priceRangeHighUpdated2',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [OfferingType.IPO, OfferingType.ABO, OfferingType.OTH]
    },
    priceRangeLowUpdated2: {
      name: 'priceRangeLowUpdated2',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [OfferingType.IPO, OfferingType.ABO, OfferingType.OTH]
    },
    finalPriceLocalCcy: {
      name: 'finalPriceLocalCcy',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [
        OfferingType.IPO,
        OfferingType.ABO,
        OfferingType.RI,
        OfferingType.OTH
      ]
    },
    percentageOfCompanySold: {
      name: 'percentageOfCompanySold',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [OfferingType.IPO, OfferingType.ABO, OfferingType.OTH]
    },
    finalBaseDealSizeShares: {
      name: 'finalBaseDealSizeShares',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [
        OfferingType.IPO,
        OfferingType.ABO,
        OfferingType.RI,
        OfferingType.OTH
      ]
    },
    percentageDiscountToPreviousClose: {
      name: 'percentageDiscountToPreviousClose',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [OfferingType.ABO]
    },
    upsizeOptionLowCcy: {
      name: 'upsizeOptionLowCcy',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [OfferingType.IPO, OfferingType.ABO, OfferingType.OTH]
    },
    upsizeOptionHighCcy: {
      name: 'upsizeOptionHighCcy',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [OfferingType.IPO, OfferingType.ABO, OfferingType.OTH]
    },
    upsizeOptionLowUSD: {
      name: 'upsizeOptionLowUSD',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [OfferingType.IPO, OfferingType.ABO, OfferingType.OTH]
    },
    upsizeOptionHighUSD: {
      name: 'upsizeOptionHighUSD',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [OfferingType.IPO, OfferingType.ABO, OfferingType.OTH]
    },
    upsizeOptionLowShares: {
      name: 'upsizeOptionLowShares',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [OfferingType.IPO, OfferingType.ABO, OfferingType.OTH]
    },
    upSizeOptionHighShares: {
      name: 'upSizeOptionHighShares',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [OfferingType.IPO, OfferingType.ABO, OfferingType.OTH]
    },
    minimumOrder: {
      name: 'minimumOrder',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [
        OfferingType.IPO,
        OfferingType.ABO,
        OfferingType.RI,
        OfferingType.OTH
      ]
    },
    otherSecurityType: {
      name: 'otherSecurityType',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [
        OfferingType.IPO,
        OfferingType.ABO,
        OfferingType.RI,
        OfferingType.OTH
      ]
    },
    issuedShareCapital: {
      name: 'issuedShareCapital',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [
        OfferingType.IPO,
        OfferingType.ABO,
        OfferingType.RI,
        OfferingType.OTH
      ]
    },
    issuedShareCapitalDiluted: {
      name: 'issuedShareCapitalDiluted',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [
        OfferingType.IPO,
        OfferingType.ABO,
        OfferingType.RI,
        OfferingType.OTH
      ]
    },
    issuedShareCapitalUndiluted: {
      name: 'issuedShareCapitalUndiluted',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [
        OfferingType.IPO,
        OfferingType.ABO,
        OfferingType.RI,
        OfferingType.OTH
      ]
    },
    fxRate: {
      name: 'fxRate',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [OfferingType.IPO, OfferingType.ABO, OfferingType.OTH]
    },
    marketCap: {
      name: 'marketCap',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [OfferingType.ABO, OfferingType.RI, OfferingType.OTH]
    },
    discountRange: {
      name: 'discountRange',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [OfferingType.ABO, OfferingType.OTH]
    },
    boardLot: {
      name: 'boardLot',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [OfferingType.ABO]
    },
    allocationsReleasedDate: {
      name: 'allocationsReleasedDate',
      valueMappingHandler: dateTimeToString,
      location: 'timetableSubObject',
      belongsToOfferingTypes: [OfferingType.IPO, OfferingType.ABO, OfferingType.SPAC]
    },
    closeOfBookbuildingDate: {
      name: 'closeOfBookbuildingDate',
      valueMappingHandler: dateTimeToString,
      location: 'timetableSubObject',
      belongsToOfferingTypes: [
        OfferingType.IPO,
        OfferingType.ABO,
        OfferingType.EQL,
        OfferingType.OTH,
        OfferingType.SPAC
      ]
    },
    researchPublicationDate: {
      name: 'researchPublicationDate',
      valueMappingHandler: dateTimeToString,
      location: 'timetableSubObject',
      belongsToOfferingTypes: [OfferingType.IPO]
    },
    startOfBookbuildingDate: {
      name: 'startOfBookbuildingDate',
      valueMappingHandler: dateTimeToString,
      location: 'timetableSubObject',
      belongsToOfferingTypes: [
        OfferingType.IPO,
        OfferingType.ABO,
        OfferingType.EQL,
        OfferingType.OTH
      ]
    },
    finalSettlementDate: {
      name: 'finalSettlementDate',
      valueMappingHandler: dateTimeToString,
      location: 'timetableSubObject',
      belongsToOfferingTypes: [
        OfferingType.IPO,
        OfferingType.ABO,
        OfferingType.EQL,
        OfferingType.OTH,
        OfferingType.SPAC
      ]
    },
    settlementDate: {
      name: 'settlementDate',
      valueMappingHandler: dateTimeToString,
      location: 'timetableSubObject',
      belongsToOfferingTypes: [
        OfferingType.IPO,
        OfferingType.ABO,
        OfferingType.EQL,
        OfferingType.OTH,
        OfferingType.SPAC
      ]
    },
    endOfSubsPeriod: {
      name: 'endOfSubscriptionPeriodDate',
      valueMappingHandler: dateTimeToString,
      location: 'timetableSubObject',
      belongsToOfferingTypes: [OfferingType.RI, OfferingType.OTH]
    },
    endOfRightsTrading: {
      name: 'endOfRightsTradingDate',
      valueMappingHandler: dateTimeToString,
      location: 'timetableSubObject',
      belongsToOfferingTypes: [OfferingType.RI, OfferingType.OTH]
    },
    startOfTradingNewShares: {
      name: 'startOfTradingNewSharesDate',
      valueMappingHandler: dateTimeToString,
      location: 'timetableSubObject',
      belongsToOfferingTypes: [OfferingType.RI, OfferingType.OTH]
    },
    recordDate: {
      name: 'recordDate',
      valueMappingHandler: dateTimeToString,
      location: 'timetableSubObject',
      belongsToOfferingTypes: [OfferingType.RI, OfferingType.OTH]
    },
    lastDayOfCumRightsTrading: {
      name: 'lastDayOfCRTradingDate',
      valueMappingHandler: dateTimeToString,
      location: 'timetableSubObject',
      belongsToOfferingTypes: [OfferingType.RI, OfferingType.OTH]
    },
    startOfSubsPeriod: {
      name: 'startOfSubscriptionPeriodDate',
      valueMappingHandler: dateTimeToString,
      location: 'timetableSubObject',
      belongsToOfferingTypes: [OfferingType.RI, OfferingType.OTH]
    },
    publicationOfProspectus: {
      name: 'prospectusPublicationDate',
      valueMappingHandler: dateTimeToString,
      location: 'timetableSubObject',
      belongsToOfferingTypes: [OfferingType.RI, OfferingType.OTH]
    },
    exRightsDate: {
      name: 'exDate',
      valueMappingHandler: dateTimeToString,
      location: 'timetableSubObject',
      belongsToOfferingTypes: [OfferingType.RI, OfferingType.OTH]
    },
    startOfRightsTrading: {
      name: 'startOfRightsTradingDate',
      valueMappingHandler: dateTimeToString,
      location: 'timetableSubObject',
      belongsToOfferingTypes: [OfferingType.RI, OfferingType.OTH]
    },
    settlementOfNewShares: {
      name: 'settlementOfNewSharesDate',
      valueMappingHandler: dateTimeToString,
      location: 'timetableSubObject',
      belongsToOfferingTypes: [OfferingType.RI, OfferingType.OTH]
    },
    announcementOfResults: {
      name: 'announcementOfResultsDate',
      valueMappingHandler: dateTimeToString,
      location: 'timetableSubObject',
      belongsToOfferingTypes: [OfferingType.RI]
    },
    egm: {
      name: 'egmDate',
      valueMappingHandler: dateTimeToString,
      location: 'timetableSubObject',
      belongsToOfferingTypes: [OfferingType.RI, OfferingType.OTH]
    },
    rumpPlacement: {
      name: 'rumpPlacementDate',
      valueMappingHandler: dateTimeToString,
      location: 'timetableSubObject',
      belongsToOfferingTypes: [OfferingType.RI, OfferingType.OTH]
    },
    maturityDate: {
      name: 'maturityDate',
      valueMappingHandler: dateTimeToString,
      location: 'timetableSubObject',
      belongsToOfferingTypes: [OfferingType.EQL]
    },
    dealAnnouncementDate: {
      name: 'dealAnnouncementDate',
      valueMappingHandler: dateTimeToString,
      location: 'root',
      belongsToOfferingTypes: [OfferingType.EQL]
    },
    closeOfRetailOfferingDate: {
      name: 'closeOfRetailOfferingDate',
      valueMappingHandler: dateTimeToString,
      location: 'timetableSubObject',
      belongsToOfferingTypes: [OfferingType.EQL]
    },
    expectedPricingDate: {
      name: 'expectedPricingDate',
      valueMappingHandler: dateTimeToString,
      location: 'root',
      belongsToOfferingTypes: [OfferingType.EQL]
    },
    trancheStructure: {
      name: 'trancheStructure',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [OfferingType.IPO]
    },
    employeeResPortion: {
      name: 'employeeResPortion',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [OfferingType.IPO]
    },
    bidLotSize: {
      name: 'bidLotSize',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [OfferingType.IPO]
    },
    totalOfferQibs: {
      name: 'totalOfferQibs',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [OfferingType.IPO]
    },
    anchorInvPortion: {
      name: 'anchorInvPortion',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [OfferingType.IPO]
    },
    netOfferQIBsExclAnch: {
      name: 'netOfferQIBsExclAnch',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [OfferingType.IPO]
    },
    nonInstPortion: {
      name: 'nonInstPortion',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [OfferingType.IPO]
    },
    retailPortion: {
      name: 'retailPortion',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [OfferingType.IPO]
    },
    minAnchorBid: {
      name: 'minAnchorBid',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [OfferingType.IPO]
    },
    maxAnchorBid: {
      name: 'maxAnchorBid',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [OfferingType.IPO]
    },
    minBidSizeFPI: {
      name: 'minBidSizeFPI',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [OfferingType.IPO]
    },
    maxBidSizeFPI: {
      name: 'maxBidSizeFPI',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [OfferingType.IPO]
    },
    anchorInvPortionRegs: {
      name: 'anchorInvPortionRegs',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [OfferingType.IPO]
    },
    catQibsApply: {
      name: 'catQibsApply',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [OfferingType.IPO]
    },
    foundersPromoteSponsorPromote: {
      name: 'foundersPromoteSponsorPromote',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [OfferingType.SPAC]
    },
    riskCapital: {
      name: 'riskCapital',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [OfferingType.SPAC]
    },
    warrantTerms: {
      name: 'warrantTerms',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [OfferingType.SPAC]
    },
    amountHeldInTrust: {
      name: 'amountHeldInTrust',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [OfferingType.SPAC]
    },
    interestEarnedOnCash: {
      name: 'interestEarnedOnCash',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [OfferingType.SPAC]
    },
    shareLiquidationValue: {
      name: 'shareLiquidationValue',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [OfferingType.SPAC]
    },
    focusTargetAcquisitionMarket: {
      name: 'focusTargetAcquisitionMarket',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [OfferingType.SPAC]
    },
    acquisitionApproval: {
      name: 'acquisitionApproval',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [OfferingType.SPAC]
    },
    overallotment: {
      name: 'overallotment',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [OfferingType.SPAC]
    },
    incorporation: {
      name: 'incorporation',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [OfferingType.SPAC]
    },
    industry: {
      name: 'industry',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [OfferingType.SPAC]
    },
    cusipNumber: {
      name: 'cusipNumber',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [OfferingType.SPAC]
    },
    groupCallDateTime: {
      name: 'groupCallDateTime',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [OfferingType.SPAC]
    },
    groupCallEntryCode: {
      name: 'groupCallEntryCode',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [OfferingType.SPAC]
    },
    groupCallId: {
      name: 'groupCallId',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [OfferingType.SPAC]
    },
    groupCallNumber: {
      name: 'groupCallNr',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [OfferingType.SPAC]
    },
    groupCallPresentation: {
      name: 'groupCallPresentation',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [OfferingType.SPAC]
    },
    discountToClose: {
      name: 'discountToClose',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [OfferingType.RI]
    },
    issuerRating: {
      name: 'issuerRating',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [OfferingType.EQL]
    },
    bondRating: {
      name: 'bondRating',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [OfferingType.EQL]
    },
    securitiesOffered: {
      name: 'securitiesOffered',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [OfferingType.EQL]
    },
    exchangeProperty: {
      name: 'exchangeProperty',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [OfferingType.EQL]
    },
    exchangePremium: {
      name: 'exchangePremium',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [OfferingType.EQL]
    },
    underlyingSecurity: {
      name: 'underlyingSecurity',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [OfferingType.EQL]
    },
    referenceSharePrice: {
      name: 'referenceSharePrice',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [OfferingType.EQL]
    },
    issuerCall: {
      name: 'issuerCall',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [OfferingType.EQL]
    },
    investorPut: {
      name: 'investorPut',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [OfferingType.EQL]
    },
    antiDilutionProtection: {
      name: 'antiDilutionProtection',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [OfferingType.EQL]
    },
    settlementUponConversion: {
      name: 'settlementUponConversion',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [OfferingType.EQL]
    },
    changeOfControl: {
      name: 'changeOfControl',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [OfferingType.EQL]
    },
    denomination: {
      name: 'denomination',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [OfferingType.EQL]
    },
    dividendProtection: {
      name: 'dividendProtection',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [OfferingType.EQL]
    },
    creditSpread: {
      name: 'creditSpread',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [OfferingType.EQL]
    },
    costOfBorrow: {
      name: 'costOfBorrow',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [OfferingType.EQL]
    },
    impliedVolatility: {
      name: 'impliedVolatility',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [OfferingType.EQL]
    },
    delta: {
      name: 'delta',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [OfferingType.EQL]
    },
    isinOfTheBond: {
      name: 'isinOfTheBond',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [OfferingType.EQL]
    },
    statusOfTheBonds: {
      name: 'statusOfTheBonds',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [OfferingType.EQL]
    },
    formOfTheBonds: {
      name: 'formOfTheBonds',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [OfferingType.EQL]
    },
    initialConversionPrice: {
      name: 'initialConversionPrice',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [OfferingType.EQL]
    },
    conversionRights: {
      name: 'conversionRights',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [OfferingType.EQL]
    },
    conversionPeriod: {
      name: 'conversionPeriod',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [OfferingType.EQL]
    },
    cleanUpCall: {
      name: 'cleanUpCall',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [OfferingType.EQL]
    },
    taxCallGrossUp: {
      name: 'taxCallGrossUp',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [OfferingType.EQL]
    },
    crossDefaultAcceleration: {
      name: 'crossDefaultAcceleration',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [OfferingType.EQL]
    },
    negativePledge: {
      name: 'negativePledge',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [OfferingType.EQL]
    },
    eventOfDefault: {
      name: 'eventOfDefault',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [OfferingType.EQL]
    },
    settlementUponRedemption: {
      name: 'settlementUponRedemption',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [OfferingType.EQL]
    },
    guarantor: {
      name: 'guarantor',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [OfferingType.EQL]
    },
    fairMarketCallPut: {
      name: 'fairMarketCallPut',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [OfferingType.EQL]
    },
    governingLaw: {
      name: 'governingLaw',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [OfferingType.EQL]
    },
    terp: {
      name: 'TERP',
      valueMappingHandler: keepAsIs,
      location: 'offeringTypeSubObject',
      belongsToOfferingTypes: [OfferingType.RI]
    }
  }
}
export const CONSISTENT_WITH_API_FIELDS = {
  [FIRST_STEP_NAME]: [
    'publishingBankName',
    'organizationId',
    'issuerLegalName',
    'dealSubType',
    'issuerName',
    'publishingBankRole',
    'region'
  ],
  [SECOND_STEP_NAME]: [
    'offeringCurrencyType',
    'primarySecondaryTotal',
    'lockUpExpiryDays',
    'otherSellingRestrictions',
    'stockTickerCode',
    'footerDisclaimer',
    'sector',
    'businessDescription',
    'roadshowSchedule',
    'lastDayOfCumRightsTrading',
    'externalLinks',
    'issuerDomicile',
    'documentation',
    'domesticSplit',
    'demandAllocationPolicy',
    'underwritingShare',
    'attachments',
    'deletedAttachments'
  ]

}
