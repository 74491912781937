import { useWs } from 'lib/useWs'
import { CommonStore } from 'store/commonStore/commonStore'
import { useStores } from 'store/rootStore'

export function useFeatureFlags (): { isFeatureFlagEnabled: (featureFlagName: string) => boolean } {
  const { commonStore } = useStores()
  const { sendJsonMessage } = useWs(
    {
      onOpen: (event) => {
        if (!commonStore.isFFInitialized) {
          sendJsonMessage({
            action: 'getEnabledFeaturesForWs',
            service: 'featuresService'
          })
          commonStore.isFFInitialized = true
        }
      },
      onMessage: onMessageHandler(commonStore)

    }
  )
  const isFeatureFlagEnabled = commonStore.isFeatureFlagEnabled.bind(commonStore)

  return { isFeatureFlagEnabled }
}

export const onMessageHandler = (commonStore: CommonStore) => (event: MessageEvent): void => {
  try {
    const parsedMsg = JSON.parse(event.data)
    if (
      parsedMsg?.meta?.type === 'DEFAULT_WS_HANDLER' &&
      parsedMsg?.meta?.service === 'featuresService' &&
      parsedMsg?.meta?.action === 'getEnabledFeaturesForWs'
    ) {
      commonStore.globalFf = parsedMsg?.data.global || []
      commonStore.userSpecificFf = parsedMsg?.data.userSpecific || []
      commonStore.isFFLoaded = true
    } else
    if (parsedMsg?.meta?.type === 'FF_SERVICE_GLOBAL') {
      commonStore.globalFf = parsedMsg?.data || []
    } else if (parsedMsg?.meta?.type === 'FF_SERVICE_USER_SPECIFIC') {
      commonStore.userSpecificFf = parsedMsg?.data || []
    }
  } catch (e) {}
}
