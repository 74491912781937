import { DashboardDealViewDTO } from 'services/deals'
import { TermsheetState } from 'constants/index'
import memoize from 'fast-memoize'
import { pathOr } from 'ramda'
import { PartitionedDealsFromAPI } from 'routes/DashboardPageV2/types'

const isDraft = (dealOrTermsheet: DashboardDealViewDTO): boolean => Boolean(
  pathOr(false, ['draft'], dealOrTermsheet)
)
const isPriced = ({ state }: DashboardDealViewDTO): boolean =>
  state === TermsheetState.PRICED
const isAnnounced = ({ state }: DashboardDealViewDTO): boolean =>
  state === TermsheetState.ANNOUNCED
const isLive = ({ state }: DashboardDealViewDTO): boolean =>
  state === TermsheetState.LIVE
const isWitdhDrawn = ({ state }: DashboardDealViewDTO): boolean =>
  state === TermsheetState.WITHDRAWN || state === TermsheetState.POSTPONED

const getDraftDeals = <T extends DashboardDealViewDTO>(dealsOrTermsheets: T[]): T[] => {
  return dealsOrTermsheets.filter(isDraft)
}
const getWithdrawn = <T extends DashboardDealViewDTO>(dealsOrTermsheets: T[]): T[] => {
  return dealsOrTermsheets.filter(isWitdhDrawn)
}

export const getLiveDeals = memoize(<T extends DashboardDealViewDTO>(dealsOrTermsheets: T[]): T[] => {
  return dealsOrTermsheets
    .filter(dealOrTermsheet => !isDraft(dealOrTermsheet))
    .filter(dealOrTermsheet => isLive(dealOrTermsheet))
})

const getPricedDeals = <T extends DashboardDealViewDTO>(dealsOrTermsheets: T[]): T[] => {
  return dealsOrTermsheets
    .filter(dealOrTermsheet => !isDraft(dealOrTermsheet))
    .filter(dealOrTermsheet => isPriced(dealOrTermsheet))
}

export const getAnnouncedDeals = memoize(<T extends DashboardDealViewDTO>(dealsOrTermsheets: T[]): T[] => {
  return dealsOrTermsheets
    .filter(dealOrTermsheet => !isDraft(dealOrTermsheet))
    .filter(dealOrTermsheet => isAnnounced(dealOrTermsheet))
})

export const partitionDeals = (
  dealsOrTermsheets: DashboardDealViewDTO[]
): PartitionedDealsFromAPI => {
  return {
    announced: getAnnouncedDeals(dealsOrTermsheets),
    live: getLiveDeals(dealsOrTermsheets),
    priced: getPricedDeals(dealsOrTermsheets),
    drafts: getDraftDeals(dealsOrTermsheets),
    withdrawn: getWithdrawn(dealsOrTermsheets)
  }
}
