import React from 'react'
import { useVerifyPermissions } from 'lib/useVerifyPermissions'
import { LoadingScreen } from '@ppui/ui-components'
import { Redirect } from 'react-router-dom'
import { useStores } from 'store/rootStore'
import { observer } from 'mobx-react-lite'
import { IConditionalRenderProps } from 'components/ConditionalRender/types'
import { useFeatureFlags } from 'lib/useFeatureFlags'
import { type } from 'ramda'

const ConditionalRender: React.FC<IConditionalRenderProps> = observer((
  { isAccountTypePermitted, isFFPermitted, component: Component }
) => {
  const { commonStore, userStore } = useStores()
  const { isFeatureFlagEnabled } = useFeatureFlags()
  const { isLoaded, isPermitted } = useVerifyPermissions(
    {
      isFFPermitted: type(isFFPermitted) === 'Boolean'
        ? Boolean(isFFPermitted)
        : isFeatureFlagEnabled(String(isFFPermitted)),
      isAccountTypePermitted,
      isFFLoaded: commonStore.isFFLoaded,
      isAccountLoaded: userStore.isLoaded
    })

  if (!isLoaded) {
    return <LoadingScreen />
  }
  if (!isPermitted) {
    return <Redirect to='/login' />
  }
  return <Component />
})
export default ConditionalRender
