import { useState } from 'react'
import { AlertType } from './const'
import { AlertData, AlertMessage } from './types'

type ClearAlert = () => void
export type SetAlert = (message: AlertMessage) => void

/**
 * @deprecated
 * Please don't use it. I even not even sure it's workable. Please use "alertStore" instead.
 */
export function useAlert (): [AlertData, ClearAlert, SetAlert, SetAlert, SetAlert, SetAlert] {
  const [type, setType] = useState<AlertType|null>(null)
  const [message, setMessage] = useState<AlertMessage|null>(null)

  const clearAlert = (): void => setType(null)
  const setErrorAlertSignUp = (message: AlertMessage): void => {
    setType(AlertType.ERROR_SIGNUP)
    setMessage(message)
  }

  const setErrorAlert = (message: AlertMessage): void => {
    setType(AlertType.ERROR)
    setMessage(message)
  }

  const setWarningAlert = (message: AlertMessage): void => {
    setType(AlertType.WARNING)
    setMessage(message)
  }

  const setSuccessAlert = (message: AlertMessage): void => {
    setType(AlertType.SUCCESS)
    setMessage(message)
  }

  return [{ type, message }, clearAlert, setErrorAlertSignUp, setErrorAlert, setWarningAlert, setSuccessAlert]
}
