
export enum AlertType {
  SUCCESS = 'SUCCESS',
  WARNING = 'WARNING',
  ERROR = 'ERROR',
  INFO = 'INFO',
  ERROR_SIGNUP = 'ERROR_SIGNUP'
}

export const typeMapping = {
  [AlertType.SUCCESS]: 'Message',
  [AlertType.WARNING]: 'Warning',
  [AlertType.ERROR]: 'Alarm',
  [AlertType.ERROR_SIGNUP]: 'Alarm',
  [AlertType.INFO]: 'Info'
}
