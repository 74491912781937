import React, { useState } from 'react'

import styled from '@emotion/styled'
import { Button } from '@material-ui/core'
import { lightThemeColors } from '../../../theme'
import { isNil } from 'ramda'

interface PublishButtonAddNewDealProps {
  'data-qa'?: string
  label: string
  onClick?: (event: any) => void
  className?: string
}

export function PublishButtonAddNewDeal (props: PublishButtonAddNewDealProps): JSX.Element {
  const [isClicked, setIsClicked] = useState(false)
  return (
    <SubmitButtonAddNewDealStyled className={props.className}>
      <Button
        {...props} onClick={(...args) => {
          if (isClicked) return
          setIsClicked(true)
          !isNil(props.onClick) && props.onClick(...args)
        }} fullWidth data-qa={props['data-qa']}
      >
        {props.label}
      </Button>
    </SubmitButtonAddNewDealStyled>
  )
}

export const SubmitButtonAddNewDealStyled = styled.div`
  background: ${lightThemeColors.orange};
  background: #ee6a52;
  display: flex;
  .MuiButton-root {
    color: #fff;
    font-size: 12px;
    font-weight: 700;
    text-transform: none;
  }
  border-radius: 2px;
`
