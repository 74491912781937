import { makeAutoObservable } from 'mobx'
import {
  DealTypeFilterData,
  GetUserDataType,
  SetUserDataType,
  SubFilters
} from 'store/types'
import {
  always,
  equals,
  mergeDeepRight,
  omit,
  pick,
  pipe,
  reduce,
  reject,
  set,
  uniq,
  values,
  without
} from 'ramda'
import { getUserPreferenceView } from 'lib/utils'
import {
  DashboardFilterItems,
  DashboardFilters
} from 'services/users'
import { DashboardRegion, OFFERING_TYPE, TermsheetRegion, TermsheetState } from 'constants/index'
import { RootStore } from 'store/rootStore'
import { DASHBOARD_OFFERING_TYPES } from 'routes/DashboardPageV2/components/Layout/const'

export const emptyFilterValues = {
  countries: [],
  subRegions: []
}

export const initFilterValue: DashboardFilters = pipe(
  values as (obj: typeof TermsheetRegion) => TermsheetRegion[],
  reduce<string, DashboardFilters>(
    (acc, item) => ({ ...acc, [item]: emptyFilterValues }),
    {}
  ) as unknown as () => DashboardFilters
)(TermsheetRegion)

export const initSubFilters = {
  includeWithNoStockExchanges: false,
  statusList: [],
  dealTypes: {},
  sectors: []
}
export class FilterStore {
  private setUserDataFn: SetUserDataType = always(null)
  private getUserDataFn: GetUserDataType = always(Promise.resolve({}))
  private _isAlreadyMounted: boolean = false
  private _activeFilterTabItem: TermsheetRegion
  private _isFilterOpened: boolean = false
  private _subFilters: SubFilters = initSubFilters
  rootStore: RootStore
  filter: DashboardFilters = initFilterValue

  constructor (rootStore: RootStore) {
    this.rootStore = rootStore
    this._activeFilterTabItem = (this.rootStore.commonStore.dashboardRegion === DashboardRegion.ALL
      ? DashboardRegion.EMEA
      : this.rootStore.commonStore.dashboardRegion) as unknown as TermsheetRegion
    makeAutoObservable(this)
  }

  set includeWithNoStockExchanges (value: boolean) {
    if (equals(value, this._subFilters.includeWithNoStockExchanges)) return
    this._subFilters.includeWithNoStockExchanges = value
  }

  get includeWithNoStockExchanges (): boolean {
    return this._subFilters.includeWithNoStockExchanges
  }

  set activeFilterTab (value) {
    if ((value as unknown as DashboardRegion) === DashboardRegion.ALL) return
    this._activeFilterTabItem = value as unknown as TermsheetRegion
  }

  get activeFilterTab (): TermsheetRegion {
    return this._activeFilterTabItem
  }

  set isFilterOpen (value: boolean) {
    if (equals(value, this._isFilterOpened)) return
    this._isFilterOpened = value
  }

  get isFilterOpen (): boolean {
    return this._isFilterOpened
  }

  set isMounted (value: boolean) {
    if (equals(value, this._isAlreadyMounted)) return
    this._isAlreadyMounted = value
  }

  get isMounted (): boolean {
    return this._isAlreadyMounted
  }

  set getUserData (value: GetUserDataType) {
    this.getUserDataFn = value
  }

  get getUserData (): GetUserDataType {
    return this.getUserDataFn
  }

  set setUserData (value: SetUserDataType) {
    this.setUserDataFn = value
  }

  get setUserData (): SetUserDataType {
    return this.setUserDataFn
  }

  set statusList (value: TermsheetState[]) {
    if (equals(value, this._subFilters.statusList)) return
    this._subFilters.statusList = [...value]
    void this.mutateUserPreferences()
  }

  get statusList (): TermsheetState[] {
    return this._subFilters.statusList
  }

  set sectors (value: string[]) {
    if (equals(value, this._subFilters.sectors)) return
    this._subFilters.sectors = [...value]
    void this.mutateUserPreferences()
  }

  get sectors (): string[] {
    return this._subFilters.sectors
  }

  get isFilterV2Ready (): boolean {
    return this.rootStore.dealsOrTermsheetsStore.dealsV2.length > 0
  }

  get isFilterApplied (): boolean {
    return Object.values(this.filter)
      .reduce<boolean>((acc, item) =>
      acc || (item.countries.length > 0 || item.subRegions.length > 0),
    false
    ) || this._subFilters.includeWithNoStockExchanges
  }

  checkIfFilteredCountriesExistInProvidedCountryList (countries: string[]): boolean {
    return this.filter[this.activeFilterTab].countries.some(r => countries.includes(r))
  }

  async mutateUserPreferences (): Promise<boolean> {
    if (!this.isFilterV2Ready) return false
    const region = this.activeFilterTab
    const data = await (this.getUserData)()

    const {
      userPreferences,
      preferenceLens
    } = getUserPreferenceView<DashboardFilterItems>(data, ['dashboardFilters', region])

    const userPreferencesWithFilters = set(
      preferenceLens,
      pick(['countries', 'subRegions'], this.filter[region]),
      userPreferences
    )
    const newUserPreferences = mergeDeepRight(
      userPreferencesWithFilters,
      { dashboardSubFilters: this._subFilters }
    )
    this.setUserData({
      user: {
        preferences: newUserPreferences
      }
    }, newUserPreferences)
    return true
  }

  setFilter (value: DashboardFilters): void {
    if (!this.isFilterV2Ready || equals(this.filter, value)) return
    this.filter = value
    void this.mutateUserPreferences()
  }

  syncFilter (value: DashboardFilters): void {
    const newFilter = pipe(mergeDeepRight(this.filter), omit([DashboardRegion.ALL]))(value) as DashboardFilters
    if (equals(this.filter, newFilter)) return
    this.filter = newFilter
  }

  syncSubFilter (value: SubFilters): void {
    const newSubFilter = pipe(mergeDeepRight(this._subFilters))(value) as SubFilters
    if (equals(this._subFilters, newSubFilter)) return
    this._subFilters = newSubFilter
  }

  resetCountryFilter (): void {
    if (!this.isFilterV2Ready) return
    this._subFilters.includeWithNoStockExchanges = false
    this.setFilter({
      ...initFilterValue
    })
  }

  resetFilter (): void {
    this.resetCountryFilter()
    this._subFilters.statusList = []
    this._subFilters.dealTypes = {}
    this._subFilters.sectors = []
  }

  get allowedDealTypes (): OFFERING_TYPE[] {
    return this.isDealTypeFilterApplied
      ? Object.entries(this._subFilters.dealTypes).reduce<OFFERING_TYPE[]>((acc, [offeringType, isActive]): OFFERING_TYPE[] => {
        return isActive ? [...acc, offeringType as OFFERING_TYPE] : acc
      }, [])
      : DASHBOARD_OFFERING_TYPES
  }

  get isDealTypeFilterApplied (): boolean {
    return Object.values(this._subFilters.dealTypes).some(a => a)
  }

  get isSectorFilterApplied (): boolean {
    return this._subFilters.sectors.length > 0
  }

  get dealTypeFilterData (): DealTypeFilterData {
    return this._subFilters.dealTypes
  }

  set dealTypeFilterData (value: DealTypeFilterData) {
    this._subFilters.dealTypes = value
    void this.mutateUserPreferences()
  }

  removeSubRegionWithCountries (subRegion: string, countries: string[]): void {
    if (!this.isFilterV2Ready) return
    const region = this.activeFilterTab
    const newValues: DashboardFilterItems = {
      subRegions: [...reject(equals(subRegion), this.filter[region].subRegions)],
      countries: [...without(countries, this.filter[region].countries)]
    }
    this.setFilter({
      ...this.filter,
      [region]: newValues
    })
  }

  addFilterByName<T extends keyof DashboardFilterItems>(name: T, value: string | number): void {
    const region = this.activeFilterTab
    if (!this.isFilterV2Ready || !Array.isArray(this.filter?.[region]?.[name])) return

    this.setFilter({
      ...this.filter,
      [region]: {
        ...this.filter[region],
        [name]: uniq([...this.filter[region][name], value])
      }
    })
  }

  truncateValueFromFilter<T extends keyof DashboardFilterItems>(name: T, value: string | number): void {
    const region = this.activeFilterTab
    if (!Array.isArray(this.filter?.[region]?.[name])) return

    this.setFilter({
      ...this.filter,
      [region]: {
        ...this.filter[region],
        [name]: [...reject(equals(value), this.filter[region][name])]
      }
    })
  }
}
