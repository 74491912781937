import { isNil, when } from 'ramda'
import React, { useCallback } from 'react'
import { BrowserRouter, Link, useHistory } from 'react-router-dom'
import { AlertData } from 'ui/Alert/types'
import { AlertType, typeMapping } from 'ui/Alert/const'
import { Toast } from '@ppui/ui-components'
import { AlertType as ToastType } from '@ppui/ui-components/dist/cjs/components/Alert/types'
import { observer } from 'mobx-react-lite'
import { useStores } from 'store/rootStore'
export const Alert: React.FC<AlertData> = observer(({ type, message }) => {
  const history = useHistory()
  const { alertStore } = useStores()

  const redirectLink = useCallback((hyperlink: string, message?: string) => {
    return (
      <Link to={() => hyperlink} onClick={() => history.push(hyperlink)}>
        <u className={`${type === AlertType.ERROR_SIGNUP ? 'text-blue-500 font-bold' : ''}`}>{message}</u>
      </Link>
    )
  }, [history, type])
  if (type == null || message == null || !typeMapping[type]) return null

  const content = when(
    () => !isNil(message.hyperlink),
    () => (
      <BrowserRouter>
        <div>
          {message.description}{' '}
          {redirectLink(message.hyperlink as string, message.linkMessage)}
        </div>
      </BrowserRouter>
    ),
    message.description
  )
  return (
    <Toast
      type={typeMapping[type] as ToastType}
      title={message.title}
      message={content}
      onCloseClick={() => alertStore.closeAlert()}
    />
  )
})
