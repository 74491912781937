import React, { ReactElement } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'

interface Styles {
  root: string
  fullPage: string
  spinner: string
}

const useStyles = (inline = false): Styles =>
  (makeStyles((theme) => ({
    root: {
      display: inline ? 'inline' : 'flex',
      justifyContent: 'center',
      padding: '10px 0',
      '& > * + *': {
        marginLeft: theme.spacing(2)
      }
    },
    fullPage: {
      display: inline ? 'inline' : 'block',
      height: '100%',
      width: '100%'
    },
    spinner: {
      position: 'absolute',
      top: '40%',
      left: '50%'
    }
  })))() as unknown as Styles

export function LoadingSpinner (props: { size?: number, inline?: boolean }): ReactElement {
  const classes = useStyles(props.inline ?? false)

  return (
    <div className={classes.root}>
      <CircularProgress size={props.size ?? 40} />
      <div hidden>loading</div>
    </div>
  )
}

LoadingSpinner.propTypes = {
  size: PropTypes.number,
  inline: PropTypes.bool
}

export function FullPageSpinner (): ReactElement {
  const classes = useStyles()

  return (
    <div className={classes.fullPage}>
      <div className={classes.spinner}>
        <CircularProgress />
        <div hidden>loading</div>
      </div>
    </div>
  )
}
