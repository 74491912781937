import React, { createContext, useContext, useState } from 'react'
import { ModalComponent } from 'components/ModalComponent'
import { CommonMessageModal } from 'contexts/modal-context/TermsheetModals/CommonMessageModal'
import { ConfirmDeleteFileModal } from 'contexts/modal-context/TermsheetModals/ConfirmDeleteFileModal'

const TermsheetModalContext = createContext<any>(undefined)

interface TermsheetModalProviderProps {
  children: any
}

const TermsheetModalProvider = (props: TermsheetModalProviderProps): any => {
  const [isOpen, setIsOpen] = useState(false)
  const [modalSelector, setModalSelector] = useState<any>()
  const [modalSize, setModalSize] = useState<string>('md')
  const [messages, setMessages] = useState<string[]>()
  const [filename, setFilename] = useState<string>('')
  const [submitHandler, setSubmitHandler] = useState<any>()

  const value = React.useMemo(
    () => ({
      isOpen,
      setIsOpen,
      setModalSelector,
      setMessages,
      setSubmitHandler,
      setFilename
    }),
    [isOpen]
  )

  const modalRouter = React.useMemo(
    () => ({

      commonMessages: {
        component: <CommonMessageModal messages={messages} setIsModalOpen={setIsOpen} />,
        size: 'xs'
      },
      confirmFileDelete: {
        component: <ConfirmDeleteFileModal filename={filename} submitHandler={submitHandler} setIsModalOpen={setIsOpen} />,
        size: 'xs'
      }
    }),
    [messages, filename, submitHandler]
  )
  React.useEffect(() => {
    setModalSize(modalRouter[modalSelector]?.size)
  }, [modalRouter, modalSelector])
  return (
    <TermsheetModalContext.Provider value={value}>
      {props.children}
      <ModalComponent maxWidth={modalSize as any} isOpen={isOpen} handleModalClose={() => setIsOpen(false)}>
        {modalRouter[modalSelector]?.component}
      </ModalComponent>
    </TermsheetModalContext.Provider>
  )
}

function useTermsheetModalContext (): any {
  const context = useContext<any>(TermsheetModalContext)
  if (!(context as boolean)) {
    throw new Error('useTermsheetModalContext must be inside TermsheetModalProvider')
  }

  return context
}

export { TermsheetModalProvider, useTermsheetModalContext }
