import { AppContainer } from 'components/AppContainer'
import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { useProfileHook } from '../contexts/user-auth-context/index'
import { RouteProps } from './AuthenticatedRoute'

export const UnauthenticatedRoute = ({ children, exact, path }: RouteProps): JSX.Element => {
  const [profile] = useProfileHook()

  const { isAuthenticated } = profile

  return (
    <Route exact={exact} path={path}>
      {!isAuthenticated
        ? <AppContainer isAuthenticated={false}>{children}</AppContainer>
        : <Redirect to='/' />}
    </Route>
  )
}
