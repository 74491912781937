import { defaultUserPreferences } from 'constants/users'
import { makeAutoObservable } from 'mobx'
import { RootStore } from 'store/rootStore'
import { IUserRoles, UserModel } from 'store/types'
import { equals } from 'ramda'
import { ORGANIZATION_TYPES } from 'constants/organizations'

export class UserStore {
  private readonly rootStore: RootStore
  private _user: UserModel = {
    profile: null,
    config: {
      dashboardType: null,
      canAccessLockUpExpiry: false,
      canSeeDashboardForAllSwitch: false
    },
    preferences: defaultUserPreferences,
    organizationLogo: '', // empty string
    userCountryCode: ''
  }

  private _isLoading: boolean = false
  private _isLoaded: boolean = false
  constructor (rootStore: RootStore) {
    this.rootStore = rootStore
    makeAutoObservable(this)
  }

  set isLoading (value: boolean) {
    this._isLoading = value
  }

  get isLoading (): boolean {
    return this._isLoading
  }

  set isLoaded (value: boolean) {
    this._isLoaded = value
  }

  get isLoaded (): boolean {
    return this._isLoaded
  }

  set user (value: UserModel) {
    if (equals(this._user, value)) return
    this._user = value
    this.isLoaded = true
    this.isLoading = false
  }

  get user (): UserModel {
    return this._user
  }

  get canAccessLockUpExpiry (): boolean {
    return this._user.config.canAccessLockUpExpiry
  }

  getUserRoles (): IUserRoles {
    const isTaaS = ORGANIZATION_TYPES.PRIMARY_PORTAL_AGENT === this._user.profile?.user?.accountType
    const isBanker = ORGANIZATION_TYPES.BANKER === this._user.profile?.user?.accountType
    const isInvestor = ORGANIZATION_TYPES.INVESTOR === this._user.profile?.user?.accountType
    const isSSS = ORGANIZATION_TYPES.SELL_SIDE_SALES === this._user.profile?.user?.accountType
    return {
      isTaaS,
      isBanker,
      isInvestor,
      isSSS
    }
  }
}
