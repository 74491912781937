import { always, assocPath, cond, equals, isNil, T } from 'ramda'
import { MappingRecords } from 'store/types'
import { OfferingType } from 'store/applicationGeneratorStore/const'
import { isUndefined } from 'utils'
import { DateTime } from 'routes/TermsheetV2Page/components/Complete/types'

export const dateTimeToString = cond([
  [
    ({ date, time }): boolean => Boolean(date && time),
    ({ date, time }: { date: string, time: string }): string => date // (`${date}T${time}:00Z`) TODO: backend doesn't support time yet
  ],
  [
    ({ date, time }): boolean => Boolean(date && !time),
    ({ date }: { date: string}): string => date
  ],
  [
    T,
    (): string => ''
  ]
])

export const tsDateToDateTime = (date?: string): DateTime => ({ date: date ?? '', time: '' })

export const mapFormFieldsToApiFields =
    (formFields: Record<string, any>, mapping: MappingRecords, offeringType: OfferingType): Record<string, any> => {
      return Object.entries(formFields).reduce((acc, [key, value]) => {
        if (isNil(mapping[key]) || isUndefined(value)) {
          return acc
        }
        const { name, location, valueMappingHandler } = mapping[key]
        const actualName: string = (name instanceof Function) ? name(offeringType) : name
        const path = cond([
          [equals('root'), always([actualName])],
          [equals('offeringTypeSubObject'), always([offeringType.toLowerCase(), actualName])],
          [equals('timetableSubObject'), always([`${offeringType.toLowerCase()}Timetable`, actualName])],
          [T, always([actualName])]
        ])(location as string)
        const mappedValue = valueMappingHandler(value, formFields, offeringType)

        if (location === 'timetableSubObject') {
          return mappedValue ? assocPath(path, mappedValue, acc) : acc
        }
        if (!location || location === 'root' || location === 'offeringTypeSubObject') {
          return assocPath(path, mappedValue, acc)
        }
        return acc
      }, {})
    }
