
import React, { useEffect } from 'react'
import { DEFAULT_ERROR, ERRORS } from 'components/LoadErrorPage/const'

export const LoadErrorPage: React.FC<{errorType: keyof typeof ERRORS}> = ({ errorType }) => {
  useEffect(() => {
    const timer = setInterval(() => {
      window.location.reload()
    }, 30000)
    return () => clearInterval(timer)
  }, [])

  return (
    <div className='h-screen flex justify-center items-center font-normal'>
      {ERRORS[errorType] ?? DEFAULT_ERROR}
    </div>
  )
}
