import { useCallback } from 'react'
import { AlertTime } from '../store/alertStore/const'
import { AlertType } from 'ui/Alert/const'
import { ToastArgs } from 'store/types'
import { useStores } from 'store/rootStore'

export interface ToastType {
  toastify: <T extends unknown>(promise: Promise<T>, successMessage: string, errorMessage: string) => Promise<T>
  toast: (payload: ToastArgs) => void
}

export function useToast (): ToastType {
  const { alertStore } = useStores()
  const toastify = useCallback(async <T extends unknown> (promise: Promise<T>, successMessage: string, errorMessage: string): Promise<T> => await promise
    .then((result) => {
      alertStore.showTemporaryAlert({
        alert: {
          type: AlertType.SUCCESS,
          message: { title: 'Success', description: successMessage }
        },
        ms: AlertTime.VeryShort
      })
      return result
    })
    .catch((e) => {
      alertStore.showTemporaryAlert({
        alert: {
          type: AlertType.ERROR,
          message: { title: 'Error', description: errorMessage }
        },
        ms: AlertTime.Short
      })
      throw e
    }), [alertStore])

  return { toastify, toast: alertStore.showTemporaryAlert }
}
