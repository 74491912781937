import React, { ReactNode } from 'react'
import PropTypes from 'prop-types'

import { DialogContent, Dialog } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

interface ModalComponentPropTypes {
  handleModalClose: () => any
  isOpen: boolean
  children: ReactNode
  maxWidth: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false
}

export function ModalComponent ({ isOpen, handleModalClose, children, maxWidth }: ModalComponentPropTypes): JSX.Element {
  const classes = useStyles()
  const descriptionElementRef = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    if (isOpen) {
      const { current: descriptionElement } = descriptionElementRef
      if (descriptionElement !== null) {
        descriptionElement.focus()
      }
    }
  }, [isOpen])

  return (
    <Dialog
      open={isOpen}
      onClose={handleModalClose}
      scroll='paper'
      aria-labelledby='scroll-dialog-title'
      aria-describedby='scroll-dialog-description'
      fullWidth
      maxWidth={maxWidth as any}
    >
      <DialogContent classes={{ root: classes.root }}>
        <div ref={descriptionElementRef}>{children}</div>
      </DialogContent>
    </Dialog>
  )
}

ModalComponent.propTypes = {
  handleModalClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.bool]),
  maxWidth: PropTypes.string
}

const useStyles = makeStyles({
  root: {
    padding: '20px'
  }
})
