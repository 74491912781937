import React, { useState } from 'react'
import { LoadingIndicatorPercentage } from '../ui/LoadingIndicatorPercentage'
import { useInterval } from 'react-use'

const fakeMessages = [
  'Checking region...',
  'Loading account data...',
  'Loading features...',
  'Loading settings...',
  'Checking configuration...'
]
const percentRefreshIntervalMs = 330
const messageRefreshIntervalMs = 800

export function LoadingPage (): JSX.Element {
  const [percent, setPercent] = useState(0)
  const [description, setDescription] = useState(fakeMessages[0])

  useInterval(() => {
    if (percent >= 100) {
      return
    }
    const artificialIncreasePercentAmount = 0.8
    const newPercent = Math.min(100, percent + artificialIncreasePercentAmount)
    setPercent(newPercent)
  }, percent >= 100 ? null : percentRefreshIntervalMs)

  useInterval(() => {
    const newDescription = fakeMessages[Math.floor(Math.random() * fakeMessages.length)]
    setDescription(newDescription)
  }, messageRefreshIntervalMs)

  return (
    <div className='h-screen hidden md:flex flex-col'>
      <LoadingIndicatorPercentage percent={percent} description={description} />
    </div>
  )
}
