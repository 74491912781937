import { useMemo } from 'react'

export const useVerifyPermissions = (
  { isFFPermitted, isAccountTypePermitted, isFFLoaded, isAccountLoaded }:
  { isFFPermitted: boolean, isAccountTypePermitted: boolean, isFFLoaded: boolean, isAccountLoaded: boolean }
): {isLoaded: boolean, isPermitted: boolean} => {
  return useMemo(() => {
    if (!isFFLoaded || !isAccountLoaded) {
      return { isLoaded: false, isPermitted: false }
    }
    if (!isAccountTypePermitted || !isFFPermitted) {
      return { isLoaded: true, isPermitted: false }
    }
    return { isLoaded: true, isPermitted: true }
  }, [isAccountLoaded, isAccountTypePermitted, isFFLoaded, isFFPermitted])
}
