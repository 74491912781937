import styled from '@emotion/styled'
import { Button, ButtonGroup } from '@material-ui/core'
import React, { ReactElement, useEffect } from 'react'
import ReactMarkdown from 'react-markdown'
import { useQuery } from 'react-query'
import { handleLogout } from 'services/auth'
import { acceptTermsAndConditions, getUserProfile, ProfileDTO, sendRejectTAndCEmail } from 'services/users'
import { ModalComponent } from 'components/ModalComponent'
import { reportModalView, sendRejectTAndCEvent } from 'config/configureGoogleAnalytics'
import { ORGANIZATION_TYPES } from 'constants/organizations'
import { useTermsAndConditions } from 'contexts/modal-context/useTermsAndConditions'
import { observer } from 'mobx-react-lite'
import { useFeatureFlags } from 'lib/useFeatureFlags'
import { FEATURES } from 'constants/features'

export const trackTermsAndConditions = (): void => {
  reportModalView({ path: 'terms-and-conditions', trackerNames: 'terms-and-conditions', title: 'terms-and-conditions' })
}

const showTAndCs = (data: ProfileDTO, termsAndConditionsText: string | undefined): boolean => {
  const isAccepted = data.user.termsAndConditionsConsent ?? false
  if (isAccepted || data.user.accountType === ORGANIZATION_TYPES.PRIMARY_PORTAL_AGENT) {
    return false
  }
  return termsAndConditionsText !== null
}

export const TermsAndConditionsModal = observer((): ReactElement => {
  const [isOpen, setIsOpen] = React.useState(false)
  const { isFeatureFlagEnabled } = useFeatureFlags()
  const { data, isSuccess } = useQuery(['getCurrentUser'], getUserProfile)
  const { termsAndConditionsText } = useTermsAndConditions(data?.user.accountType)
  const isEnableTnC = Boolean(isFeatureFlagEnabled(FEATURES.enableTnC))
  useEffect(() => {
    if (isEnableTnC && isSuccess && data !== undefined) {
      setIsOpen(showTAndCs(data, termsAndConditionsText))
    }
  }, [data, isEnableTnC, isSuccess, termsAndConditionsText]
  )

  useEffect(() => {
    if (isOpen) {
      trackTermsAndConditions()
    }
  }, [isOpen])

  const handleAccept = async (): Promise<void> => {
    try {
      await acceptTermsAndConditions()
    } finally {
      setIsOpen(false)
    }
  }

  const handleReject = async (): Promise<void> => {
    try {
      sendRejectTAndCEvent()
      await handleLogout()
    } catch (error: unknown) {
      if (data?.user.email !== undefined) {
        throw new Error(`Could not send rejected T & C email to ${data.user.email}`)
      }
      throw error
    } finally {
      await sendRejectTAndCEmail(data)
    }
  }

  return (
    <ModalComponent isOpen={isOpen} handleModalClose={() => {}} maxWidth='lg'>
      <h1>Terms and Conditions</h1>
      <div data-qa='ecm.terms-and-conditions-modal.text'><ReactMarkdown>{termsAndConditionsText ?? ''}</ReactMarkdown></div>
      <ButtonGroupStyled>
        <ButtonGroup disableElevation>
          <ButtonStyled>
            <Button onClick={handleAccept} data-qa='ecm.terms-and-conditions-modal.accept-button'>Accept</Button>
          </ButtonStyled>
          <Button onClick={handleReject} data-qa='ecm.terms-and-conditions-modal.reject-button'>Reject</Button>
        </ButtonGroup>
      </ButtonGroupStyled>
    </ModalComponent>
  )
})

const ButtonGroupStyled = styled.div`
  .MuiButtonGroup-root {
    margin-top: 20px;
  }
`

const ButtonStyled = styled.div`
  .MuiButton-root {
    background: ${({ theme }) => theme.blue_100};
    color: ${({ theme }) => theme.white};
  }
`
