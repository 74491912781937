import { observer } from 'mobx-react-lite'
import React, { useMemo } from 'react'
import { useFeatureFlags } from 'lib/useFeatureFlags'
import { useStores } from 'store/rootStore'
import { useLinks } from 'components/InternalNavbar/hooks'
import { SecondaryNavigationItem } from '@ppui/ui-components'
import { navigateToUrl } from '@pp/utils'
import { useLocation } from 'react-router-dom'
import { isNil } from 'ramda'

export const Links: React.FC = observer(() => {
  const { userStore } = useStores()
  const { isFeatureFlagEnabled } = useFeatureFlags()
  const { pathname } = useLocation()
  const accountType = useMemo(() => {
    return userStore.user.profile?.user.accountType ?? ''
  }, [userStore.user.profile?.user.accountType])
  const links = useLinks(
    isFeatureFlagEnabled,
    userStore.user.profile?.user.accountType
  )
  return (
    <div key={`links-${accountType}`} className='flex gap-x-4 w-full items-center'>
      {links.filter(link => link.isAvailable).map((linkData, index) => {
        return (
          <SecondaryNavigationItem
            key={index}
            styles='mr-2 ml-0'
            clickHandler={() => {
              if (!isNil(linkData.clickHandler)) {
                linkData.clickHandler()
              } else {
                navigateToUrl(linkData.url)
              }
            }}
            icon={linkData.icon}
            isActive={linkData.url.includes(pathname)}
            text={linkData.label}
          />
        )
      })}
    </div>
  )
})
