import { readFileAsData } from 'utils/readFileAsData'
import { API } from './aws-api'
import { CountryDTO } from 'store/types'

export const uploadCountriesCSV = async (file: Blob | undefined): Promise<void> => {
  await API<unknown>().post({
    path: '/v2/countries',
    init: {
      body: {
        data: { file: await readFileAsData(file) }
      }
    }
  })
}

export const downloadCountriesCSV = async (): Promise<Blob> => {
  const csv = await API<string>().get({
    init: { headers: { Accept: 'application/CSV' } },
    path: '/v2/countries/csv/download'
  })
  const blob = new Blob([csv], { type: 'application/CSV;charset=utf-8' })
  return blob
}

export const getCountriesWithStockExchanges = async (): Promise<CountryDTO[]> => await API<CountryDTO[]>().get({
  authenticated: false,
  path: '/v2/countries/existedInStockExchange'
})

export const fetchCountries = async (): Promise<CountryDTO[]> => {
  return await API<CountryDTO[]>().get({
    authenticated: false,
    path: '/v2/countries'
  })
}
