import React, { lazy, Suspense } from 'react'
import { Redirect, Route, Switch, withRouter } from 'react-router-dom'
import { FullPageSpinner } from 'components/LoadingSpinner'
import { AuthenticatedRoute } from 'routes/AuthenticatedRoute'
import { useDashboardPage } from 'routes/DashboardPageV2/useDashboardPage'
import { Retry } from 'routes/Retry'
import { UnauthenticatedRoute } from 'routes/UnauthenticatedRoute'
import { useStores } from 'store/rootStore'
import { observer } from 'mobx-react-lite'
import ConditionalRender from 'components/ConditionalRender/ConditionalRender'

/* eslint-disable @typescript-eslint/promise-function-async */
const DashboardPageV2 = lazy(() => Retry(() => import('./DashboardPageV2')))
const TermsheetV2ViewPage = lazy(() => Retry(() => import('./TermsheetV2ViewPage/TermsheetV2ViewPage')))
const AdminPage = lazy(() => Retry(() => import('./AdminPage')))
const OrganisationAdminPage = lazy(() => Retry(() => import('./OrganisationAdminPage')))
const NotFoundPage = lazy(() => Retry(() => import('./NotFoundPage')))
const PartnerTermsheetPage = lazy(() => Retry(() => import('./PartnerTermsheetPage')))
const LockUpExpiryPage = lazy(() => Retry(() => import('./LockUpExpiryPage')))
const PreferencesPage = lazy(() => Retry(() => import('./PreferencesPage')))
const TermsheetV2Page = lazy(() => Retry(() => import('./TermsheetV2Page')))

const RoutedApp: React.FC = observer(() => {
  const { filteredDashboardRegions } = useDashboardPage()
  const { userStore } = useStores()
  const { isTaaS, isInvestor } = userStore.getUserRoles()
  return (
    <Suspense fallback={<FullPageSpinner />}>
      <Switch>
        <AuthenticatedRoute exact path='/termsheetV2'>
          <TermsheetV2Page />
        </AuthenticatedRoute>

        <AuthenticatedRoute exact path='/dashboard'>
          <DashboardPageV2 />
        </AuthenticatedRoute>

        <AuthenticatedRoute exact path='/'>
          <Redirect to='/dashboard' />
        </AuthenticatedRoute>

        {filteredDashboardRegions.map((region, i) => (
          <Route
            key={i} exact path={[`/${region}`, `/${region}/`]} render={() =>
              <Switch>
                <AuthenticatedRoute>
                  <DashboardPageV2 />
                </AuthenticatedRoute>
              </Switch>}
          />
        ))}

        <AuthenticatedRoute path='/termsheet/:dealId/:termsheetId?'>
          <TermsheetV2ViewPage />
        </AuthenticatedRoute>
        <AuthenticatedRoute path='/deals/:dealId/termsheets/:termsheetId'>
          <TermsheetV2ViewPage />
        </AuthenticatedRoute>
        <AuthenticatedRoute path='/termsheets/:termsheetId'>
          <TermsheetV2ViewPage />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path='/admin'>
          <ConditionalRender isAccountTypePermitted={isTaaS} isFFPermitted component={AdminPage} />
        </AuthenticatedRoute>
        <AuthenticatedRoute exact path='/admin/organisation-settings'>
          <ConditionalRender isAccountTypePermitted={isTaaS} isFFPermitted component={OrganisationAdminPage} />
        </AuthenticatedRoute>

        <AuthenticatedRoute exact path='/investor/lock-up-expiry'>
          <ConditionalRender isAccountTypePermitted={isInvestor} component={LockUpExpiryPage} isFFPermitted />
        </AuthenticatedRoute>

        <AuthenticatedRoute exact path='/investor/preferences'>
          <ConditionalRender isAccountTypePermitted={isInvestor} component={PreferencesPage} isFFPermitted />
        </AuthenticatedRoute>

        <Route exact path='/partner-referral/view-termsheet'>
          <PartnerTermsheetPage />
        </Route>

        <Route exact path='/not-found'>
          <NotFoundPage />
        </Route>

        <UnauthenticatedRoute path='*'>
          <Redirect to='/login' />
        </UnauthenticatedRoute>

      </Switch>
    </Suspense>
  )
})

export default withRouter(RoutedApp)
