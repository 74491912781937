export const readFileAsData = async (file: Blob | undefined): Promise<string> => {
  return await new Promise((resolve, reject) => {
    const fileReader = new FileReader()
    fileReader.onload = () => {
      resolve(fileReader.result as string)
    }
    fileReader.onerror = () => {
      reject(new Error('Error during opening the file'))
    }
    fileReader.readAsText(file as Blob)
  })
}
