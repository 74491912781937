import { DashboardRegion, TermsheetRegion } from 'constants/index'
import { useUserPreferences, useUserRole } from 'lib'
import { useGetNotifications } from 'lib/queries/useGetNotifications'
import { always, cond, equals, T } from 'ramda'
import { useCallback } from 'react'
import { Notification } from 'services/notifications'

interface useGetFeedNotificationsReturn {
  data?: Notification[]
}

export const useGetFilteredNotifications = (): useGetFeedNotificationsReturn => {
  const preferencePath = ['dashboardRegion']
  const [dashboardRegion] = useUserPreferences<DashboardRegion>(preferencePath)
  const { data } = useGetNotifications()
  const { isInvestor } = useUserRole()

  const filterByRegion = (region: TermsheetRegion, notifications: Notification[]): Notification[] =>
    notifications.filter(notification => notification.region === region)

  const getFilteredNotifications = useCallback((dashboardRegion: DashboardRegion,
    data: Notification[]): Notification[] =>
    (cond([
      [equals<DashboardRegion>(DashboardRegion.ALL), always(data)],
      [equals<DashboardRegion>(DashboardRegion.EMEA), always(filterByRegion(TermsheetRegion.EMEA, data))],
      [equals<DashboardRegion>(DashboardRegion.AMRS), always(filterByRegion(TermsheetRegion.AMRS, data))],
      [equals<DashboardRegion>(DashboardRegion.APAC), always(filterByRegion(TermsheetRegion.APAC, data))],
      [T, always([])]
    ]) as ((arg: DashboardRegion) => Notification[]))(dashboardRegion),
  [])

  if (!isInvestor) { return { data: data ?? [] } }

  return {
    data: getFilteredNotifications(dashboardRegion, data ?? [])
  }
}
